import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { RequestService } from "../core/request.service";
import { BaseCrudService } from "../core/basecrud.service";
import { TokenStorage } from "../core/tokenstorage.service";
import { ConfigurationService } from "../core/configuration.service";

@Injectable()
export class DocumentViewService extends BaseCrudService<any> {
  policyApi: string = this.configurationService.config.selfApi;

  constructor(
    protected translateService: TranslateService,
    protected requestService: RequestService,
    protected tokenStorage: TokenStorage,
    protected configurationService: ConfigurationService
  ) {
    super(
      "tenants/",
      configurationService.config.selfApi,
      translateService,
      requestService,
      tokenStorage
    );
  }

  public GetUserSharedDocumentById(id: string) {
    return this.requestService.getFile(
      this.configurationService.config.selfApi + `${this.controller}${this.configurationService.config.tenantPublicId}/documents/${id}/view`
    );
  }
}
