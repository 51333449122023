<main class="page page--insurance-type d-flex flex-column">
  <section class="section section--insurance-hero row align-items-start">
    <div class="section__description col-12 col-md-12 col-lg-6">
      <h1 [innerHTML]="'PERSONAL_LIABILITY_PAGE_TITLE' | translate"></h1>
      <h2 class="bold">{{ "PERSONAL_LIABILITY_SUBTITLE" | translate }}</h2>
      <p>
        {{ "PERSONAL_LIABILITY_TEXT" | translate }}
      </p>
      <button class="btn btn--home btn-primary justify-content-center"
        [routerLink]="'/wizard/insurance-type' | navigateWithLang">
        {{ "CALCULATE_PREMIUM" | translate }}
      </button>
    </div>
    <div class="col-12 col-md-0 col-lg-6 d-none d-lg-flex justify-content-center">
      <img src="assets/images/migros-privat.svg" [src]="
          'assets/images/' + ('GIRL_WITH_SKATEBOARD_2_SRC' | translate) + '.svg'
        " class="section-image" [alt]="'GIRL_WITH_SKATEBOARD_2_ALT' | translate"
        [title]="'GIRL_WITH_SKATEBOARD_2_TITLE' | translate" />
    </div>
  </section>
  <section class="section section--what-is-insured row align-items-start">
    <div class="section__description col-12 col-md-12 col-lg-6">
      <h2 class="bold" [innerHTML]="
          'WHY_PERSONAL_LIABILITY_INSURANCE_IS_WORTHWHILE' | translate
        "></h2>
      <p [innerHTML]="
          'WHY_PERSONAL_LIABILITY_INSURANCE_IS_WORTHWHILE_DESCRIPTION'
            | translate
        "></p>
    </div>
    <div class="
        col-12 col-md-0 col-lg-6
        d-sm-flex
        justify-content-center
        mt-sm-5 mt-lg-0
      ">
      <img src="assets/images/insurance-icons.svg"
        [src]="'assets/images/' + ('3_CLOUDS_DAMAGE_SRC' | translate) + '.svg'" class="section-image"
        [alt]="'3_CLOUDS_DAMAGE_ALT' | translate" [title]="'3_CLOUDS_DAMAGE_TITLE' | translate" />
    </div>
  </section>
  <section class="
      section section--advantages
      text-left text-sm-center
      align-items-center
      d-flex
      flex-column
      position-relative
    ">
    <img src="assets/images/home-bg.png" alt="background" class="frame-image position-absolute" />
    <h2 class="bold">{{ "COMPREHENSIVELY_PROTECTED_WITH_MIGROS" | translate }}</h2>
    <div class="
        wrapper wrapper--advantages
        d-flex
        text-center
        flex-column flex-lg-row
      ">
      <div class="item__description">
        <svg-icon src="assets/images/umberalla-icon.svg" class="icon">
        </svg-icon>
        <h3 class="bold">{{ "HIGH_PROTECTION" | translate }}</h3>
        <p>{{ "HIGH_PROTECTION_DESCRIPTION" | translate }}</p>
      </div>
      <div class="item__description">
        <svg-icon src="assets/images/premium-icon.svg" class="icon" [svgAriaLabel]="'PREMIUM_ICON_ALT' | translate">
        </svg-icon>
        <h3 class="bold">{{ "PREMIUM_PROTECTION" | translate }}</h3>
        <p>{{ "PREMIUM_PROTECTION_DESCRIPTION" | translate }}</p>
      </div>
      <div class="item__description">
        <svg-icon src="assets/images/partner-icon.svg" class="icon" [svgAriaLabel]="'PARTNER_ICON_ALT' | translate">
        </svg-icon>
        <h3 class="bold">{{ "STRONG_PARTNER" | translate }}</h3>
        <p>{{ "STRONG_PARTNER_DESCRIPTION" | translate }}</p>
      </div>
      <div class="item__description">
        <svg-icon src="assets/images/cards-icon.svg" class="icon" [svgAriaLabel]="'CARDS_ICON_ALT' | translate">
        </svg-icon>
        <h3 class="bold">{{ "CUMULUS_POINTS" | translate }}</h3>
        <p>{{ "CUMULUS_POINTS_DESCRIPTION" | translate }}</p>
      </div>
    </div>
  </section>
  <section class="section section--migros-everywhere">
    <h2 class="bold">{{ "MORE_PERFORMANCE_FOR_YOUR_MONEY" | translate }}</h2>
    <p>{{ "OUR_ADVANTAGES_AT_GLANCE" | translate }}</p>
    <div class="wrapper wrapper--services d-flex flex-wrap">
      <div class="
          wrapper__item
          d-flex
          align-items-center
          justify-content-center
          text-center
        ">
        <h3 class="bold" [innerHTML]="'WAIVER_OF_RECOURSE' | translate"></h3>
        <div class="card__description">
          <p>{{ "WAIVER_OF_RECOURSE_PERSONAL_LIABILITY_TEXT" | translate }}</p>
        </div>
      </div>
      <div class="
          wrapper__item
          d-flex
          align-items-center
          justify-content-center
          text-center
        ">
        <h3 class="bold" [innerHTML]="'INSURANCED_AMOUNT_PERSONAL_LIABILITY' | translate"></h3>
        <div class="card__description">
          <p>{{ "INSURANCED_AMOUNT_PERSONAL_LIABILITY_TEXT" | translate }}</p>
        </div>
      </div>
      <div class="
          wrapper__item
          d-flex
          align-items-center
          justify-content-center
          text-center
        ">
        <h3 class="bold" [innerHTML]="'CAN_BE_CANCELED' | translate"></h3>
        <div class="card__description">
          <p>{{ "CAN_BE_CANCELED_TEXT" | translate }}</p>
        </div>
      </div>
      <div class="
          wrapper__item
          d-flex
          align-items-center
          justify-content-center
          text-center
        ">
        <h3 class="bold" [innerHTML]="'PASSIVE_LEGAL_PROTECTION' | translate"></h3>
        <div class="card__description">
          <p>{{ "PASSIVE_LEGAL_PROTECTION_TEXT" | translate }}</p>
        </div>
      </div>
      <div class="
          wrapper__item
          d-flex
          align-items-center
          justify-content-center
          text-center
        ">
        <h3 class="bold" [innerHTML]="'NO_CLAIMS_BONUS' | translate"></h3>
        <div class="card__description">
          <p>{{ "NO_CLAIMS_BONUS_TEXT" | translate }}</p>
        </div>
      </div>
      <div class="
          wrapper__item
          d-flex
          align-items-center
          justify-content-center
          text-center
        ">
        <h3 class="bold" [innerHTML]="'CUMULUS_POINTS_ON_REWARD' | translate"></h3>
        <div class="card__description">
          <p>{{ "CUMULUS_POINTS_ON_REWARD_TEXT" | translate }}</p>
        </div>
      </div>
    </div>
  </section>
  <section class="section section--extra-protection">
    <h2 class="bold">{{ "EXTRA_PROTECTION" | translate }}</h2>
    <p>{{ "EXTRA_PROTECTION_SUBTITLE" | translate }}</p>
    <div class="wrapper wrapper--protection d-flex flex-wrap">
      <div class="wrapper__item d-flex flex-column align-item-start">
        <svg-icon src="assets/images/car-ellipse-icon.svg" class="icon" [svgAriaLabel]="'CAR_ICON_ALT' | translate">
        </svg-icon>
        <h3 class="bold">{{ "DRIVE_OTHER_PEOPLES_DEVICE" | translate }}</h3>
        <p>
          {{ "DRIVE_OTHER_PEOPLES_DEVICE_TEXT" | translate }}
        </p>
      </div>
      <div class="wrapper__item d-flex flex-column align-item-start">
        <svg-icon src="assets/images/key-ellipse-icon.svg" class="icon" [svgAriaLabel]="'KEYS_ICON_ALT' | translate">
        </svg-icon>
        <h3 class="bold">{{ "KEY" | translate }}</h3>
        <p [innerHTML]="'KEY_TEXT' | translate"></p>
      </div>
    </div>
  </section>
  <section class="section section--download">
    <app-download></app-download>
  </section>
  <!-- <section class="section section--slider">
    <app-slider></app-slider>
  </section> -->
</main>
