import { Component, Inject, PLATFORM_ID } from "@angular/core";
import { Title } from "@angular/platform-browser";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { NavigationEnd, Router } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";
import { ConfigurationService } from "./core/configuration.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public constructor(
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private configurationService: ConfigurationService
  ) {
    this.router.initialNavigation();
    
    if (isPlatformBrowser(this.platformId)) {
      this.initiateGTM();

      router.events.subscribe((event) => {
        //@ts-ignore
        if (window.dataLayer && event instanceof NavigationEnd) {
          //@ts-ignore
          window.dataLayer.push({ event: "pageview" });
        }
      });
    }
  }

  initiateGTM() {
    const gtmId = this.configurationService.config.gtmId;
    if (!gtmId) return false;

    const scriptTag = document.createElement("SCRIPT");
    scriptTag.innerHTML = `
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
        });
        var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "${gtmId}");
    `;
    document.head.appendChild(scriptTag);

    const iframeTag = document.createElement("iframe");
    iframeTag.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
    iframeTag.height = "0";
    iframeTag.width = "0";
    iframeTag.style.display = "none";
    iframeTag.style.visibility = "hidden";
    const noscriptTag = document.createElement("NOSCRIPT");
    noscriptTag.appendChild(iframeTag);
    document.body.prepend(noscriptTag);
  }
}
