<header class="d-flex align-items-center justify-content-start">
  <a [routerLink]="'/' | navigateWithLang">
    <div class="logo">
      <img [src]="configurationService.getLogoPath()" alt="Migros Versicherung" />
    </div>
  </a>
  <div class="elements__container d-flex align-items-center ml-auto">
    <!-- <div class="dropdown" (click)="toggleDropdown()" (clickOutside)="onClickedOutsideDropdown($event)">
      <div class="bg-transparent border-0 position-relative d-flex align-items-center">
        <button class="btn btn--dropdown d-block">
          {{ selectedLanguage | translate }}
          <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg"
            class="dropdown__icon">
            <path d="M7 0.5H0L3.5 5L7 0.5Z" fill="#3D4657" />
          </svg>
        </button>
        <div class="subnav subnav--langs" *ngIf="isDropdownOpen" [ngClass]="{ 'subnav--open': isDropdownOpen }">
          <a *ngFor="let i of languages" (click)="setDefaultLanguage(i.id)" class="language">{{ i.key | translate }}</a>
        </div>
      </div>
    </div>
    <div class="logged-in">
      <app-account-dropdown *ngIf="authService.isLoggedIn" [isCockpitLinkVisible]="isCockpitActive">
      </app-account-dropdown>
      <button (click)="onLogin()" class="btn btn--icon d-block d-lg-none" *ngIf="!authService.isLoggedIn">
        <svg-icon src="assets/images/user-login-mobile.svg"></svg-icon>
      </button>
    </div>
    <button (click)="toggleNavbar()"
      class="btn btn--navbar hamburger bg-transparent d-flex d-xl-none align-items-center justify-content-center">
      <svg-icon src="assets/images/hamburger-menu.svg" [ngClass]="{ 'd-none': isNavbarOpen }"></svg-icon>
      <svg-icon src="assets/images/close-icon.svg" class="d-none" [ngClass]="{ 'd-block': isNavbarOpen }"></svg-icon>
    </button>
    -->
    <button class="btn btn-primary d-none d-lg-flex" [routerLink]="'/wizard/insurance-type' | navigateWithLang">
      <p>{{ "CALCULATE_PREMIUM" | translate }}</p>
    </button>
  </div>
</header>
