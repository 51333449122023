import { UrlMatcher, UrlSegment, UrlSegmentGroup } from "@angular/router";

export const localizedRoutes: ILocalizedRoutes = {
  "household-insurance": {
    langs: {
      en: "household-insurance",
      de: "hausratversicherung",
      fr: "assurance-menage",
      it: "household-insurance",
    },
    title: "HOUSEHOLD_INSURANCE_META_TITLE",
    description: "HOUSEHOLD_INSURANCE_META_DESCRIPTION",
  },
  "personal-liability": {
    langs: {
      en: "personal-liability",
      de: "privathaftpflichtversicherung",
      fr: "assurance-responsabilite-civile-privee",
      it: "personal-liability",
    },
    title: "PERSONAL_LIABILITY_META_TITLE",
    description: "PERSONAL_LIABILITY_META_DESCRIPTION",
  },
  contact: {
    langs: {
      en: "contact",
      de: "kontakt",
      fr: "contact",
      it: "contact",
    },
    title: "CONTACT_META_TITLE",
    description: "CONTACT_META_DESCRIPTION",
  },
  "report-damage-landing": {
    langs: {
      en: "report-damage",
      de: "schaden-melden",
      fr: "declarer-sinistre",
      it: "report-damage",
    },
    title: "REPORT_DAMAGE_META_TITLE",
    description: "REPORT_DAMAGE_META_DESCRIPTION",
  },
  support: {
    langs: {
      en: "support",
      de: "support",
      fr: "service",
      it: "support",
    },
    title: "SUPPORT_META_TITLE",
    description: "SUPPORT_META_DESCRIPTION",
  },
  about: {
    langs: {
      en: "about",
      de: "ueber-uns",
      fr: "a-propos",
      it: "about",
    },
    title: "ABOUT_META_TITLE",
    description: "ABOUT_META_DESCRIPTION",
  },
  wizard: {
    langs: {
      en: "wizard",
      de: "praemie-berechnen",
      fr: "calculer-prime",
      it: "wizard",
    },
  },
  imprint: {
    langs: {
      en: "imprint",
      de: "impressum",
      fr: "mentions-legales",
      it: "imprint",
    },
    title: "IMPRINT_META_TITLE",
    description: "IMPRINT_META_DESCRIPTION",
  },
  "insurance-type": {
    langs: {
      en: "insurance-type",
      de: "versicherung-waehlen",
      fr: "choisir-assurance",
      it: "insurance-type",
    },
    title: "INSURANCE_TYPE_META_TITLE",
    description: "INSURANCE_TYPE_META_DESCRIPTION",
  },
  people: {
    langs: {
      en: "people",
      de: "people",
      fr: "people",
      it: "people",
    },
  },
  "owner-status": {
    langs: {
      en: "owner-status",
      de: "owner-status",
      fr: "owner-status",
      it: "owner-status",
    },
  },
  "insurance-amount": {
    langs: {
      en: "insurance-amount",
      de: "insurance-amount",
      fr: "insurance-amount",
      it: "insurance-amount",
    },
  },
  options: {
    langs: {
      en: "options",
      de: "options",
      fr: "options",
      it: "options",
    },
  },
  "personal-information": {
    langs: {
      en: "personal-information",
      de: "personal-information",
      fr: "personal-information",
      it: "personal-information",
    },
  },
  summary: {
    langs: {
      en: "summary",
      de: "summary",
      fr: "summary",
      it: "summary",
    },
  },
  final: {
    langs: {
      en: "final",
      de: "final",
      fr: "final",
      it: "final",
    },
  },
  blog: {
    langs: {
      en: "blog",
      de: "blog",
      fr: "blog",
      it: "blog",
    },
    title: "BLOG_META_TITLE",
    description: "BLOG_META_DESCRIPTION",
  },
};

interface ILocalizedRoutes {
  [key: string]: ILocalizedRoute;
}

interface ILocalizedRoute {
  langs: ILocalizedPaths;
  title?: string;
  description?: string;
}

interface ILocalizedPaths {
  en: string;
  de: string;
  fr: string;
  it: string;
}

export const matcherFor = (originalPath: string): UrlMatcher => {
  return (url: UrlSegment[], urlSegmentGroup: UrlSegmentGroup) => {
    const lang =
      urlSegmentGroup.segments.length > 1 && urlSegmentGroup.segments[0].path;

    if (url.length && isIncludesPath(originalPath, url))
      return {
        consumed: [lang ? matchPathWithLang(originalPath, lang) : url[0]],
      };
    return null;
  };
};

const isIncludesPath = (originalPath: string, url: UrlSegment[]) => {
  return Object.values(localizedRoutes[originalPath].langs).includes(
    url[0].path
  );
};

const matchPathWithLang = (originalPath: string, lang) => {
  return new UrlSegment(localizedRoutes[originalPath].langs[lang], {});
};
