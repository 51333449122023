import { Component, OnInit } from "@angular/core";
import { ConfigurationService } from "src/app/core/configuration.service";
import { AppStorage } from "src/app/core/appstorage.service";
import { ContentfulService } from "src/app/core/contentful.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
@Component({
  selector: "app-alternative-home",
  templateUrl: "./alternative-home.component.html",
  styleUrls: ["./alternative-home.component.scss"],
})
export class AlternativeHomeComponent implements OnInit {
  phoneNumberPf: string;
  heroImagePath: string;
  heroTitle: string;
  heroText: string;

  constructor(private configurationService: ConfigurationService,private appStorage: AppStorage,
    private contenfulService: ContentfulService, private translateService: TranslateService, private router: Router ) {
    this.phoneNumberPf = this.configurationService.config.phoneNumber;
    if(this.router.url.includes("familie")){
      this.heroTitle = this.translateService.instant("HERO_TITLE_FAMILIE");
      this.heroText = this.translateService.instant("HERO_TEXT_FAMILIE");
      this.heroImagePath = this.appStorage.getHomeHeroFamilie();
    }
    else if(this.router.url.includes("jung")){
      this.heroTitle = this.translateService.instant("HERO_TITLE_JUNG");
      this.heroText = this.translateService.instant("HERO_TEXT_JUNG");
      this.heroImagePath = this.appStorage.getHomeHeroJung();
    }
    else if(this.router.url.includes("studenten")){
      this.heroTitle = this.translateService.instant("HERO_TITLE_STUDENTEN");
      this.heroText = this.translateService.instant("HERO_TEXT_STUDENTEN");
      this.heroImagePath = this.appStorage.getHomeHeroStudenten();
    }
    else if(this.router.url.includes("optimierer")){
      this.heroTitle = this.translateService.instant("HERO_TITLE_OPTIMIERER");
      this.heroText = this.translateService.instant("HERO_TEXT_OPTIMIERER");
      this.heroImagePath = this.appStorage.getHomeHeroOptimierer();
    }
  }

  ngOnInit(): void {}
}
