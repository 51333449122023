<div class="container">
  <nav>
    <a [routerLink]="'/' | navigateWithLang" class="logo">
      <img [src]="configurationService.getLogoPath()" alt="Migros Versicherung" />
    </a>
    <div class="d-flex justify-content-end flex-wrap">
      <div class="small">{{ "DO_YOU_NEED_HELP" | translate }}</div>
      <a class="right-texts ml-2" href="tel:{{ phoneNumberPf }}" rel="noreferrer">
        <div class="small bold">{{ phoneNumberPf }}</div>
      </a>
    </div>
  </nav>
</div>
