<div class="default-layout">
  <div class="default-layout__nav-slot">
    <app-wizard-navigation-bar></app-wizard-navigation-bar>
  </div>
  <div class="default-layout__content-slot">
    <router-outlet></router-outlet>
  </div>
  <div class="wizard-layout__footer-slot">
    <app-wizard-footer></app-wizard-footer>
  </div>
</div>
