import { ConfigurationService } from "./../core/configuration.service";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { Meta } from "@angular/platform-browser";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { localizedRoutes } from "../localized-routes";
import { ContentfulService } from "../core/contentful.service";
import { PageTitleService } from "./page-title.service";

@Injectable({ providedIn: "root" })
export class RouteResolver implements Resolve<string> {
  constructor(
    private pageTitleService: PageTitleService,
    private translateService: TranslateService,
    private metaService: Meta,
    private contentfulService: ContentfulService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const foundRoute = Object.values(localizedRoutes).find((localizedRoute) => {
      return Object.values(localizedRoute.langs).some((pathName) => {
        return pathName === route.url[0]?.path;
      });
    });

    const currentTitle = this.pageTitleService.getPageTitleFromTransferState();

    if (currentTitle) {
      return true;
    } else if (foundRoute?.title) {
      this.setTitleWhenReady(foundRoute?.title, foundRoute?.description);
    } else {
      this.setTitleWhenReady("HOME_META_TITLE", "HOME_META_DESCRIPTION");
    }
  }

  setTitleWhenReady(title, description) {
    let translatedTitle = this.translateService.instant(title);
    let translatedDescription = this.translateService.instant(description);

    // If translation returns same KEY (which means translation doesn't exist yet)
    if (translatedTitle !== title) {
      this.pageTitleService.setPageTitle(translatedTitle);
      this.metaService.updateTag({
        name: "description",
        content: this.translateService.instant(translatedDescription),
      });
    } else {
      this.contentfulService.onTranslationsLoaded.subscribe(() => {
        translatedTitle = this.translateService.instant(title);
        translatedDescription = this.translateService.instant(title);

        this.pageTitleService.setPageTitle(translatedTitle);
        this.metaService.updateTag({
          name: "description",
          content: this.translateService.instant(translatedDescription),
        });
      });
    }
  }
}
