import { Inject, Injectable, Optional, PLATFORM_ID } from "@angular/core";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { REQUEST, RESPONSE } from "@nguniversal/express-engine/tokens";
import { Request } from "express";
import { StorageService } from "../core/storage.service";

@Injectable({
  providedIn: "root",
})
export class BrowserStorage {
  constructor(
    @Optional() @Inject(REQUEST) private req: Request<any>,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private storageService: StorageService
  ) {}

  setItem(key: string, value: string, storage: "session" | "local" = "local") {
    if (isPlatformBrowser(this.platformId)) {
      if (storage === "local") {
        window.localStorage.setItem(key, value);
        this.document.cookie = `${key}=${value};path=/`;
      } else {
        window.sessionStorage.setItem(key, value);
        this.document.cookie = `${key}=${value};path=/`;
      }
    } else {
      this.storageService.setItem(key, value);
    }
  }
  getItem(key: string, storage: "session" | "local" = "local") {
    if (isPlatformBrowser(this.platformId)) {
      let res = window.localStorage.getItem(key);
      if (storage === "local") {
        res = window.localStorage.getItem(key);
      } else {
        res = window.sessionStorage.getItem(key);
      }
      if (!res) {
        res = this.getSSRCookie(key);
      }

      return res;
    } else {
      return this.storageService.getItem(key) || this.getSSRCookie(key);
    }
  }

  private getSSRCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(this.req?.headers.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
