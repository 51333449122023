import { Injectable } from "@angular/core";
import { PolicyService } from "./policy.service";
import { SocotraConfigService } from "./socotraConfig.service";
import { HttpClient } from "@angular/common/http";
import { Message } from "../types/wizard";
import { BehaviorSubject, Observable } from "rxjs";
import { NotificationsService } from "./notifications.service";

interface IMessages {
  received: any[];
  sent: any[];
  deleted: any[];
}

const initialMessages = {
  received: [],
  sent: [],
  deleted: [],
};

@Injectable({
  providedIn: "root",
})
export class MessagesService {
  private messagesSource: BehaviorSubject<IMessages>;
  public messages$: Observable<IMessages>;
  private isUpdatingSource: BehaviorSubject<Boolean>;
  public isUpdating$: Observable<Boolean>;
  private unreadCountSource: BehaviorSubject<number>;
  public unreadCount$: Observable<number>;

  constructor(
    private socotraConfigService: SocotraConfigService,
    private policyService: PolicyService,
    private httpClient: HttpClient,
    private notificationService: NotificationsService
  ) {
    this.messagesSource = new BehaviorSubject<IMessages>(initialMessages);
    this.messages$ = this.messagesSource.asObservable();
    this.isUpdatingSource = new BehaviorSubject<Boolean>(false);
    this.isUpdating$ = this.isUpdatingSource.asObservable();
    this.unreadCountSource = new BehaviorSubject<number>(0);
    this.unreadCount$ = this.unreadCountSource.asObservable();
  }

  get isUpdating(): Boolean {
    return this.isUpdatingSource.getValue();
  }
  set isUpdating(isUpdating: Boolean) {
    this.isUpdatingSource.next(isUpdating);
  }

  get messages(): IMessages {
    return this.messagesSource.getValue();
  }
  set messages(messages: IMessages) {
    this.messagesSource.next(messages);
  }

  get unreadCount(): number {
    return this.unreadCountSource.getValue();
  }
  set unreadCount(unreadCount: number) {
    this.unreadCountSource.next(unreadCount);
  }

  public async getUnreadMessagesCount(): Promise<number> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();
    const policyHolderPublicId =
      await this.policyService.readOrGetPolicyHolderPublicId();

    let res;
    try {
      if (policyHolderPublicId) {
        res = await this.httpClient
          .get<number>(
            this.policyService.policyApi +
              "policyholders/" +
              policyHolderPublicId +
              "/messages/unread/count",
            requestOptions
          )
          .toPromise();
      }
    } catch (error) {
      this.notificationService.danger("COULDNT_UPDATE_MESSAGES");
    } finally {
      this.unreadCount = res;
      return res;
    }
  }

  public async getAllMessages(): Promise<any> {
    this.isUpdating = true;

    this.getUnreadMessagesCount();
    const requestOptions = await this.socotraConfigService.getRequestOptions();
    const policyHolderPublicId =
      await this.policyService.readOrGetPolicyHolderPublicId();

    try {
      const res = await this.httpClient
        .get<any[]>(
          this.policyService.policyApi +
            "policyholders/" +
            policyHolderPublicId +
            "/messages",
          requestOptions
        )
        .toPromise();

      const newMessagesState = {
        received: res.filter((y) => !y.IsDeleted && y.ToPolicyHolderId),
        sent: res.filter((y) => !y.IsDeleted && y.FromPolicyHolderId),
        deleted: res.filter((y) => y.IsDeleted == true),
      };

      this.messages = newMessagesState;
    } catch (error) {
      this.notificationService.danger("COULDNT_UPDATE_MESSAGES");
    } finally {
      this.isUpdating = false;
    }
  }

  public async PostMessage(messageBody: Message): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();
    const policyHolderPublicId =
      await this.policyService.readOrGetPolicyHolderPublicId();

    return await this.httpClient
      .post(
        this.policyService.policyApi +
          "policyholders/" +
          policyHolderPublicId +
          "/messages",
        messageBody,
        requestOptions
      )
      .toPromise();
  }
}
