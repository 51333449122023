import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { ContentfulService } from "../core/contentful.service";

@Injectable({ providedIn: "root" })
export class DynamicLandingResolver implements Resolve<any> {
  constructor(private contentfulService: ContentfulService, private httpClient: HttpClient) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    const slug = route.paramMap.get("slug");
    return await this.contentfulService.GetDynamicLandingPageBySlug(slug);
  }
}
