import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { BrowserStorage } from "src/app/services/browserStorage.service";
import { WizardService } from "src/app/services/wizard.service";
import { NavigateToMigros } from "src/app/shared/pipes/navigateToMigros.pipe";
import { NavigateWithLang } from "src/app/shared/pipes/navigateWithLang.pipe";

@Component({
  selector: "app-landing-layout",
  templateUrl: "./landing-layout.component.html",
  styleUrls: ["./landing-layout.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [NavigateWithLang, NavigateToMigros],
})
export class LandingLayoutComponent implements OnInit {
  constructor(
    public wizardService: WizardService,
    private navigateToMigros: NavigateToMigros,
    private browserStorage: BrowserStorage
  ) { }

  isCookieAccepted: boolean = this.browserStorage.getItem("cookie-consent")
    ? true
    : false;

  acceptCookie() {
    this.browserStorage.setItem("cookie-consent", "accepted");
    document.querySelector(".cookie__wrapper").classList.add("close-animation");
  }

  ngOnInit(): void {
    // Currently does nothing
  }

  handleCookieTextClicked(event: any) {
    if (event.target.nodeName === "SPAN") {
      this.wizardService.clearAllData();
      let path = this.navigateToMigros.transform("privacy");
      window.open(path, "_blank");
    }
  }
}
