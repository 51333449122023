<main class="d-flex flex-column">
  <div *ngIf="landingContent" class="dynamic-landing">
    <!-- Rich text content-->
    <div
      [innerHtml]="renderRichTextHtml(landingContent)"
      class="richtext"
    ></div>

    <a
      *ngIf="primaryButtonLink"
      class="btn btn-primary"
      [href]="primaryButtonLink"
    >
      <p>{{ primaryButtonText }}</p>
    </a>
  </div>
</main>
