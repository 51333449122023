import { Component, OnInit } from "@angular/core";
import { ConfigurationService } from "src/app/core/configuration.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  phoneNumberPf: string;
  emailPf: string;
  addressPf: string;
  isLoggedIn: boolean = false;

  constructor(
    public configurationService: ConfigurationService,
    public authService: AuthService
  ) {
    this.phoneNumberPf = this.configurationService.config.phoneNumber;
    this.emailPf = this.configurationService.config.email;
    this.addressPf = this.configurationService.config.address;
  }

  public onLogin() {
    this.authService.login();
  }

  ngOnInit(): void {}
}
