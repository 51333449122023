import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { Router, Event, NavigationStart } from "@angular/router";
import { ConfigurationService } from "src/app/core/configuration.service";
import { ContentfulService } from "src/app/core/contentful.service";
import { AuthService } from "src/app/services/auth.service";
import { isPlatformBrowser } from "@angular/common";
import { PolicyService } from "src/app/core/policy.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  isSubnavOpen = false;
  isDropdownOpen = false;
  isNavbarOpen = false;
  languages: any[] = [];
  selectedLanguage: any;
  isCockpitActive: boolean = false;

  isBlogEnabled: boolean;

  constructor(
    public configurationService: ConfigurationService,
    private contenfulService: ContentfulService,
    public authService: AuthService,
    public policyService: PolicyService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.languages = this.configurationService.config.languages;
    this.selectedLanguage = this.languages.filter(
      (x) => x.id === this.configurationService.getLanguage()
    )[0].key;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.isNavbarOpen = false;
      }
    });
    this.isBlogEnabled =
      this.configurationService.config.isBlogEnabled === "true" ||
      this.configurationService.config.isBlogEnabled === true;

    this.authService.userSub$.subscribe((userSub) => {
      if (userSub) {
        this.checkIfCockpitActive();
      }
    });
  }

  async checkIfCockpitActive() {
    const hasPublicId =
      await this.policyService.readOrGetPolicyHolderPublicId();
    this.isCockpitActive = this.authService.isLoggedIn && !!hasPublicId;
  }

  public onLogin() {
    this.authService.login();
  }

  toggleSubnav() {
    this.isSubnavOpen = !this.isSubnavOpen;
  }
  openSubnav() {
    this.isSubnavOpen = true;
  }
  closeSubnav() {
    this.isSubnavOpen = false;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleNavbar() {
    this.isNavbarOpen = !this.isNavbarOpen;
  }

  onClickedOutsideSubnav(e: Event) {
    this.isSubnavOpen = false;
  }

  onClickedOutsideNav(e: Event) {
    this.isNavbarOpen = false;
  }

  onClickedOutsideDropdown(e: Event) {
    this.isDropdownOpen = false;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener("resize", () => {
        this.isNavbarOpen = false;
      });
    }
  }

  async setDefaultLanguage(id) {
    this.configurationService.setLanguage(id);

    this.selectedLanguage = this.languages.filter((x) => x.id === id)[0].key;

    await this.contenfulService.SetTranslations(id);

    // For Blog routes, routing will be handled by blog component
    if(this.router.url.includes('blog')) return;

    const pathArr = this.router.url.split("/");
    pathArr[1] = id;

    this.router.navigateByUrl(pathArr.join("/"));
  }
}
