import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import {
  makeStateKey,
  StateKey,
  Title,
  TransferState,
} from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class PageTitleService {
  pageTitleStateKey: StateKey<string> = makeStateKey<string>("pageTitle");

  constructor(
    private titleService: Title,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  setPageTitle(value: string) {
    if (!isPlatformBrowser(this.platformId)) {
      this.transferState.set(this.pageTitleStateKey, JSON.stringify(value));
    }

    this.titleService.setTitle(value);
  }

  getPageTitleFromTransferState() {
    const title: string = this.transferState.get(this.pageTitleStateKey, null);

    if (isPlatformBrowser(this.platformId)) {
      this.transferState.remove(this.pageTitleStateKey);
    }

    return title;
  }
}
