<main class="d-flex flex-column">
  <section
    class="section section--blog-hero row align-items-start flex-column-reverse flex-lg-row align-items-start align-items-xl-center position-relative">
    <img src="assets/images/home-bg.png" alt="background" class="frame-image position-absolute d-none d-xl-block" />
    <div class="section__description col-12 col-lg-6 text-left">
      <h1 class="bold">{{ blogHeroTitle }}</h1>
      <p>
        {{ blogHeroText }}
      </p>
    </div>
    <div
      class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-item-center position-relative section__banner">
      <img src="assets/images/home-bg.png" alt="background"
        class="frame-image position-absolute d-block d-xl-none w-100" />
      <img src="assets/images/migros-about.svg" [src]="
          'assets/images/' + ('GIRL_WITH_SKATEBOARD_SRC' | translate) + '.svg'
        " class="section-image" [alt]="'GIRL_WITH_SKATEBOARD_ALT' | translate"
        [title]="'GIRL_WITH_SKATEBOARD_TITLE' | translate" />
      <div class="ellipse position-absolute col-lg-6 d-xl-flex justify-content-center d-none">
        <img src="assets/images/ellipse.svg" class="section-image ellipse-image" />
      </div>
    </div>
  </section>
  <section class="section--blog-listing">
    <ul *ngIf="blogCategories" class="blog-categories">
      <li *ngIf="blogCategories[0]" [ngClass]="{'active': blogCategories[0]===activeCategory, 'disabled': isLoading}"
        (click)="handleCategoryClick(blogCategories[0])">
        {{ blogCategories[0] }}
      </li>
      <span>|</span>
      <li *ngIf="blogCategories[1]" [ngClass]="{'active': blogCategories[1]===activeCategory, 'disabled': isLoading}"
        (click)="handleCategoryClick(blogCategories[1])">
        {{ blogCategories[1] }}
      </li>
      <span>|</span>
      <li *ngIf="blogCategories[2]" [ngClass]="{'active': blogCategories[2]===activeCategory, 'disabled': isLoading}"
        (click)="handleCategoryClick(blogCategories[2])">
        {{ blogCategories[2] }}
      </li>
    </ul>
    <div class="blog-cards">
      <div *ngFor="let entry of blogEntries" class="blog-card">
        <a *ngIf="entry.fields.slug" [routerLink]="'/blog/' + entry?.fields?.slug | navigateWithLang" alt="">
          <img class="blog-card__img" [src]="entry?.fields?.coverImage.fields.file.url+'?w=700'" srcset="{{entry?.fields?.coverImage.fields.file.url}}?w=400 400w,
            {{entry?.fields?.coverImage.fields.file.url}}?w=700 700w,
            {{entry?.fields?.coverImage.fields.file.url}}?w=1000 1000w" sizes="(max-width: 768px) 100vw,
                    (max-width: 992px) 50vw,
                    30vw," [alt]="entry?.fields?.coverImage.fields.title">
          <h2 *ngIf="entry.fields.title" class="blog-card__title">{{entry?.fields?.title}}</h2>
          <div class="blog-card__meta sub-text-container d-flex flex-row">
            <time *ngIf="entry.fields.publishDate" class="date" datetime="2020-06-15">{{ entry.fields.publishDate |
              formatDate
              }}</time>
            <span *ngIf="entry.fields.author">&nbsp;|&nbsp;</span>
            <address *ngIf="entry.fields.author" class="author">{{ entry?.fields?.author?.fields?.name }}</address>
          </div>
        </a>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button *ngIf="canLoadMore()" [ngClass]="{'disabled': isLoading}" class="btn btn-tertiary btn--load-more"
        (click)="handleLoadMore()">
        <p>{{ loadMore }}</p>
      </button>
    </div>
  </section>
</main>
