<div
  class="dropdown"
  (click)="toggleDropdown()"
  (clickOutside)="onClickedOutsideDropdown($event)"
>
  <div
    class="bg-transparent border-0 position-relative d-flex align-items-center"
  >
    <button class="btn btn--icon d-flex">
      <svg-icon src="assets/images/user-white-icon.svg"></svg-icon>
      <svg-icon
        src="assets/images/alert-circle.svg"
        class="alert-icon"
        *ngIf="messagesService.unreadCount > 0"
      ></svg-icon>
    </button>
    <div class="subnav subnav--account" *ngIf="isDropdownOpen">
      <ul>
        <li>
          <div
            class="person-container d-flex align-items-center justify-content-start"
          >
            <svg-icon src="assets/images/person-icon-dropdown.svg"></svg-icon>
            <div class="person-info">
              <p class="bold">{{ name }}</p>
              <p class="gray">{{ email }}</p>
            </div>
            <a class="person-container__link" [href]="accountEditLink"></a>
          </div>
        </li>
      </ul>
      <ul *ngIf="showMessages">
        <li *ngIf="messagesService.unreadCount > 0">
          <a
            *ngIf="messagesService.unreadCount > 0"
            [routerLink]="'/cockpit/messages' | navigateWithLang"
            class="bold position-relative profile bold"
            >{{ "YOUR_MESSAGES" | translate }}
            <span
              *ngIf="messagesService.unreadCount > 0"
              class="position-absolute notification notification-mobil"
              >{{ messagesService.unreadCount }}</span
            ></a
          >
        </li>
        <li>
          <a [routerLink]="'/cockpit/welcome' | navigateWithLang">{{
            "WELCOME_HEADER" | translate
          }}</a>
        </li>
        <li>
          <a [routerLink]="'/cockpit/messages' | navigateWithLang">{{
            "SHOW_ALL_MESSAGES" | translate
          }}</a>
        </li>
      </ul>
      <!-- <ul *ngIf="changeUserInfo">
        <li>
          <a [href]="accountEditLink">
            {{ "CHANGE_USER_DATA" | translate }}
          </a>
        </li>
      </ul> -->
      <ul *ngIf="isCockpitLinkVisible">
        <li>
          <a [routerLink]="'/cockpit/welcome' | navigateWithLang">
            {{ "VERSICHERUNGS-COCKPIT" | translate }}
          </a>
        </li>
      </ul>
      <ul>
        <li>
          <button
            class="btn btn-text d-flex align-items-center justify-content-start logout w-100"
            (click)="authService.logout(router.url, true)"
          >
            <svg-icon src="assets/images/logout-icon.svg"></svg-icon>
            {{ "LOGOUT" | translate }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
