<footer>
  <div class="footer">
    <div
      class="footer--header d-flex flex-column flex-lg-row justify-content-stretch"
    >
      <a [routerLink]="'/' | navigateWithLang">
        <div class="logo">
          <img
            [src]="configurationService.getLogoPath()"
            alt="Migros Versicherung"
          />
        </div>
      </a>
      <div class="d-flex flex-column-reverse flex-xl-row flex-grow-1">
        <div class="links d-flex flex-column flex-sm-row">
          <ul class="links__group list-unstyled mt-sm-0 mr-sm-0">
            <li class="link link__title">
              {{ "PRODUCTS" | translate }}
            </li>
            <li class="link">
              <a [routerLink]="'/household-insurance' | navigateWithLang">{{
                "HOUSEHOLD" | translate
              }}</a>
            </li>
            <li class="link">
              <a [routerLink]="'/personal-liability' | navigateWithLang">{{
                "PERSONAL_LIABILITY" | translate
              }}</a>
            </li>
          </ul>
          <ul class="links__group list-unstyled mt-sm-0 mr-sm-0">
            <li class="link link__title">
              {{ "CUSTOMER_SERVICE" | translate }}
            </li>
            <li class="link">
              <a [routerLink]="'/report-damage-landing' | navigateWithLang">{{
                "REPORT_DAMAGE" | translate
              }}</a>
            </li>
            <li class="link">
              <a [routerLink]="'/support' | navigateWithLang">{{
                "SERVICE" | translate
              }}</a>
            </li>
          </ul>
          <ul class="links__group list-unstyled mt-sm-0 mr-sm-0">
            <li class="link link__title">
              {{ "INFORMATIONS" | translate }}
            </li>
            <li class="link">
              <a [routerLink]="'/blog' | navigateWithLang">
                {{ "BLOG" | translate }}
              </a>
            </li>
            <li class="link">
              <a [routerLink]="'/about' | navigateWithLang">{{
                "ABOUT_US" | translate
              }}</a>
            </li>
          </ul>
          <ul class="links__group list-unstyled mt-sm-0 mr-sm-0">
            <li class="link link__title">
              {{ "LEGAL" | translate }}
            </li>
            <li class="link">
              <a [routerLink]="'/imprint' | navigateWithLang">{{
                "IMPRINT" | translate
              }}</a>
            </li>
            <li class="link">
              <a
                [href]="'privacy' | navigateToMigros"
                target="_blank"
                rel="noreferrer"
                >{{ "DATA_PROTECTION" | translate }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer--description">
      <span class="bold small">{{ "MIGROS_PRETTY_NAME" | translate }}</span>
      <span class="small">{{ addressPf }}</span>
      <span class="small"
        ><a href="tel:{{ phoneNumberPf }}" rel="noreferrer">{{
          phoneNumberPf
        }}</a></span
      >
      <a class="orange small font-weight-bold" href="mailto:{{ emailPf }}">{{
        emailPf
      }}</a>
    </div>
  </div>
  <div
    class="footer footer__footer d-flex flex-column text-center justify-content-center justify-content-xl-between flex-xl-row"
  >
    <div class="small">{{ "IN_COOPERATION_WITH_VAUDOISE" | translate }}</div>
    <div class="small">© {{ "MIGROS_PRETTY_NAME" | translate }} 2021</div>
  </div>
</footer>
