import { HttpHeaders } from "@angular/common/http";
import { Component } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { wizardRootPath, wizardSteps } from "src/app/constants/wizardSteps";
import { ConfigurationService } from "src/app/core/configuration.service";
import { NavigationService } from "src/app/core/navigation.service";
import { NotificationsService } from "src/app/core/notifications.service";
import { PolicyService } from "src/app/core/policy.service";
import { SocotraConfigService } from "src/app/core/socotraConfig.service";
import { StorageService } from "src/app/core/storage.service";
import { DiscountCodeTypes } from "src/app/enums/codeTypes";
import { localizedRoutes } from "src/app/localized-routes";
import { AuthService } from "src/app/services/auth.service";
import { WizardService } from "src/app/services/wizard.service";

@Component({
  templateUrl: "./deeplink-callback.component.html",
  styleUrls: ["./deeplink-callback.component.scss"],
})
export class DeeplinkCallbackComponent {
  policyPublicId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private policyService: PolicyService,
    private navigationService: NavigationService,
    private wizardService: WizardService,
    private storageService: StorageService,
    private socotraConfig: SocotraConfigService,
    private notificationService: NotificationsService,
    private translateService: TranslateService,
    private authService: AuthService,
    private configurationService: ConfigurationService,
    private socotraService: SocotraConfigService
  ) {
    this.activatedRoute.params.subscribe(async (params: Params) => {
      this.policyPublicId = params["policyPublicId"];

      const apiToken = await this.socotraConfig.getOfferApiToken();

      const headerDict = {
        Authorization: "Bearer " + apiToken,
      };

      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };

      const offerDetails = await this.policyService
        .GetOfferFromDeeplink(this.policyPublicId, requestOptions)
        .toPromise()
        .catch((data) => {
          this.wizardService.wizardCurrentStep = 1;
          this.wizardService.saveWizardStateToLocalStorage();
          this.wizardService.useSavedStateOrStartFresh();

          this.notificationService.danger(
            this.translateService.instant(data.error)
          );
        });

      this.wizardService.acceptedDataSharing = offerDetails.AcceptedDataSharing;
      this.wizardService.acceptedTermsAndConditions =
        offerDetails.AcceptedTermsAndConditions;
      this.wizardService.canton = offerDetails.Canton;
      this.wizardService.claimQuestion = offerDetails.ClaimsHistory;
      this.wizardService.insuranceStartDate =
        new Date(offerDetails.ContractStartDate) <
        new Date(Date.now() + 1000 * 60 * 60 * 24)
          ? new Date(Date.now() + 1000 * 60 * 60 * 24)
              .toISOString()
              .slice(0, 10)
          : offerDetails.ContractStartDate;
      this.wizardService.howManyPeople = offerDetails.Family;
      this.wizardService.furnishingStandard = offerDetails.InteriorStandard;
      this.wizardService.roomCount = offerDetails.NumberOfRooms;
      this.wizardService.ownerStatus = offerDetails.Owner;
      this.wizardService.personalInformation = offerDetails.PolicyHolder;
      this.wizardService.terminatedOrDeniedInsuranceQuestion =
        offerDetails.PreviouslyRejected;
      this.wizardService.insuranceType = offerDetails.ProductType;
      this.wizardService.discountCode = offerDetails.DiscountCode;
      this.wizardService.generalComment = offerDetails.GeneralComment;
      this.wizardService.selectedPerils = offerDetails.SelectedPerils;
      this.wizardService.policyPublicId = offerDetails.PolicyPublicId;
      this.wizardService.DistributionChannel = offerDetails.DistributionChannel;
      this.wizardService.selectedPerils = offerDetails.SelectedPerils;
      offerDetails.SelectedPerils.forEach((element) => {
        if (element.Name.startsWith("basic_2_household")) {
          this.wizardService.insuranceAmount = element.SumInsured;
          this.wizardService.deductible = element.Deductible;
        }
        if (element.Name.startsWith("basic_1_liability")) {
          this.wizardService.deductible = element.Deductible;
        }
      });

      const cityInfo = await this.socotraConfig.getCityById(
        offerDetails.PolicyHolder.CityId
      );
      this.wizardService.cityId = cityInfo.Id;
      this.wizardService.cityInfo = cityInfo;

      await this.applyDiscountCode(offerDetails.DiscountCode);

      this.wizardService.wizardCurrentStep = this.isFullDeeplink(offerDetails)
        ? 4
        : 2;

      if (!this.isMLoginUser(offerDetails)) {
        this.wizardService.personalInformation.correspondenceLanguage =
          offerDetails.PolicyHolder.CorrespondenceLanguage;
        this.wizardService.personalInformation.firstName =
          offerDetails.PolicyHolder.FirstName === "temp"
            ? ""
            : offerDetails.PolicyHolder.FirstName;
        this.wizardService.personalInformation.lastName =
          offerDetails.PolicyHolder.LastName === "temp"
            ? ""
            : offerDetails.PolicyHolder.LastName;
        this.wizardService.personalInformation.gender =
          offerDetails.PolicyHolder.Gender === 0 ? "male" : "female";
        this.wizardService.personalInformation.dateOfBirth =
          offerDetails.PolicyHolder.DateOfBirth;
        this.wizardService.personalInformation.cityId =
          offerDetails.PolicyHolder.CityId;
        this.wizardService.personalInformation.streetName =
          offerDetails.PolicyHolder.StreetName;
        this.wizardService.personalInformation.nationality =
          offerDetails.PolicyHolder.Nationality;
        this.wizardService.personalInformation.email =
          offerDetails.PolicyHolder.Email;
        this.wizardService.personalInformation.mobileNumber =
          offerDetails.PolicyHolder.MobilePhone;
        this.wizardService.personalInformation.houseNumber =
          offerDetails.PolicyHolder.HouseNumber;
        this.wizardService.personalInformation.cumulusNumber =
          offerDetails.PolicyHolder.LoyalityMembershipNo;

        this.wizardService.personalInformation.postCode = cityInfo.Postcode;
        this.wizardService.personalInformation.city = cityInfo.Name;

        window.localStorage.setItem("withGuestForm", "true");
      }

      this.saveInitialPolicyData();
      this.wizardService.saveWizardStateToLocalStorage();

      if (this.isMLoginUser(offerDetails)) {
        await this.getPricing();

        // Redirect to mLogin
        const currentStep = wizardSteps.find((step) => step.stepNumber === 4);
        const currentLanguage = this.configurationService.getLanguage();
        const currentPath = `/${currentLanguage}/${localizedRoutes[wizardRootPath]?.langs[currentLanguage]}/${currentStep.path}`;
        this.authService.login({ url: currentPath });
      } else {
        // Load wizard data and redirect to related step
        this.wizardService.useSavedStateOrStartFresh();
      }
    });
  }

  async saveInitialPolicyData() {
    this.wizardService.existingOfferData =
      this.wizardService.getOfferRelatedData();
  }

  async getPricing() {
    this.wizardService.discountedTotal = await this.fetchDiscountedPrice();
  }
  preparePricingRequestData() {
    const canton = this.wizardService.canton;
    const family = this.wizardService.howManyPeople;
    const owner = this.wizardService.ownerStatus;
    const paymentFrequency = "annually";
    const selectedPerils = this.wizardService.selectedPerils;
    const discountLumpSum = this.wizardService.discountLumpSum;

    return {
      canton,
      family,
      owner,
      paymentFrequency,
      discountLumpSum,
      selectedPerils,
    };
  }

  async fetchDiscountedPrice() {
    const res = await this.socotraService.getPrices(
      this.preparePricingRequestData()
    );
    return res.PriceTotal;
  }

  isMLoginUser(offerDetails): boolean {
    return !!offerDetails.PolicyHolder.SubjectId;
  }

  isFullDeeplink(offerDetails): boolean {
    return !!offerDetails.PolicyHolder.Nationality;
  }

  async applyDiscountCode(code: string) {
    if (code) {
      try {
        const res = await this.policyService.checkDiscountCode(code);

        if (res) {
          if (
            res.Type === DiscountCodeTypes.PriceReductionRecurringPercent ||
            res.Type === DiscountCodeTypes.PriceReductionOncePercent
          ) {
            this.wizardService.discountPercent = res.Value;
          } else if (
            res.Type === DiscountCodeTypes.PriceReductionRecurringLumpsum ||
            res.Type === DiscountCodeTypes.PriceReductionOnceLumpsum
          ) {
            this.wizardService.discountLumpSum = res.Value;
          }

          this.wizardService.isDiscountAdded = true;
        }
      } catch (error) {
        this.wizardService.discountCode = undefined;
      }
    }
  }
}
