import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigurationHelper } from "./configuration-helper-abstract";

@Injectable({ providedIn: "root" })
export class ConfigurationHelperBrowser implements ConfigurationHelper {
  constructor(private httpClient: HttpClient) {}

  async getConfigFile(path: string): Promise<string> {
    return JSON.stringify(await this.httpClient.get(path).toPromise() as Promise<string>);
  }
}
