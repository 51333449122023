import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-wizard-link-button",
  templateUrl: "./wizard-link-button.component.html",
  styleUrls: ["./wizard-button.component.scss"],
})
export class WizardLinkButtonComponent implements OnInit {
  @Input() variant: "primary" | "secondary" | "tertiary" | "quaternary" = "primary";
  @Input() trailingIcon: "right-arrow" | null = null;
  @Input() fluid: boolean = false;
  @Input() size: "small" | "medium" = "medium";
  @Input() link: String | null = null;
  @Output() onCallBack: EventEmitter<any> = new EventEmitter();
  @Input() isDisabled: boolean | null = false;
  icons = {
    "right-arrow": "assets/images/right-arrow.svg",
  };

  constructor() {}

  ngOnInit(): void {}

  onClick(event) {
    this.onCallBack.emit(event);
  }
}
