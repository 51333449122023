<main class="d-flex flex-column">
  <section class="section section--contact row align-items-start">
    <div class="section__description col-12 col-md-6">
      <h1>{{ "CONTACT" | translate }}</h1>
      <h2 class="bold">
        {{ "DO_YOU_HAVE_QUESTION_OR_FEEDBACK" | translate }}
      </h2>
      <p>
        {{ "CONTACT_TEXT" | translate }}
      </p>
    </div>
    <div class="col-12 col-md-6 d-flex justify-content-center">
      <img src="assets/images/hausrat-frame.svg" [src]="
          'assets/images/' + ('GUY_WITH_SMARTPHONE_SRC' | translate) + '.svg'
        " class="section-image" [alt]="'GUY_WITH_SMARTPHONE_ALT' | translate"
        [title]="'GUY_WITH_SMARTPHONE_TITLE' | translate" />
    </div>
    <div *ngIf="isSent" class="col-12 col-md-6">
      <div class="d-flex">
        <svg-icon class="mr-3" src="assets/images/check-icon.svg"></svg-icon>
        <div>
          <h2 class="mb-3">{{ "MESSAGE_SENT" | translate }}</h2>
          <p class="mb-3">
            {{ "THANKS_FOR_CONTACTING_US" | translate }}
          </p>
        </div>
      </div>
    </div>
    <form class="section__form col-12 col-md-6" *ngIf="!isSent">
      <label for="firstName">{{ "FIRST_NAME" | translate }} *</label>
      <input type="text" [(ngModel)]="contactRequest.FirstName" [ngModelOptions]="{ standalone: true }"
        [attr.placeholder]="'FIRST_NAME_PLACEHOLDER' | translate" id="firstName" />
      <label for="lastName">{{ "LAST_NAME" | translate }} *</label>
      <input type="text" [(ngModel)]="contactRequest.LastName" [ngModelOptions]="{ standalone: true }"
        [attr.placeholder]="'LAST_NAME_PLACEHOLDER' | translate" id="lastName" />
      <label for="email">{{ "EMAIL_ADDRESS" | translate }} *</label>
      <input type="email" [(ngModel)]="contactRequest.Email" [ngModelOptions]="{ standalone: true }"
        [attr.placeholder]="'EMAIL_ADDRESS_PLACEHOLDER' | translate" id="email" />
      <label for="message">{{ "MESSAGE" | translate }} *</label>
      <textarea [(ngModel)]="contactRequest.Message" (input)="contactRequest.Message = contactRequest.Message.trim()"
        [ngModelOptions]="{ standalone: true }" [attr.placeholder]="'MESSAGE_PLACEHOLDER' | translate"
        id="message"></textarea>

      <div class="wizard-actions">
        <app-wizard-button [isDisabled]="!isAnyFieldEmpty()" [ngClass]="{ 'is-loading': isLoading }"
          (onCallBack)="send()" id="sendFormButton">
          {{ "SUBMIT" | translate }}
        </app-wizard-button>
        <app-wizard-message *ngIf="hasFieldError('Email')" [option]="{
            text: this.translateService.instant('INVALID_EMAIL_ADDRESS'),
            type: 'warning'
          }"></app-wizard-message>
      </div>
    </form>
  </section>
</main>
