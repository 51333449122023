import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { MessageOption } from "src/app/types/wizard";

@Component({
  selector: "app-wizard-message",
  templateUrl: "./wizard-message.component.html",
  styleUrls: ["./wizard-message.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class WizardMessageComponent implements OnInit {
  @Input() option: MessageOption;
  iconPath: string;
  constructor() {}

  ngOnInit(): void {

    
    switch (this.option.type) {
      case "warning":
        this.iconPath = "assets/images/exclamationmark-icon.svg";
        break;
      case "suggestion":
        this.iconPath = "assets/images/questionmark-icon-green.svg";
        break;
      case "success":
          this.iconPath = "assets/images/check-icon-notification.svg";
          break;
      default:
        this.iconPath = null;
        break;
    }
  }
}


