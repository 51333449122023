import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ConfigurationService } from "src/app/core/configuration.service";
import { NotificationsService } from "src/app/core/notifications.service";
import { SocotraConfigService } from "src/app/core/socotraConfig.service";
import { Contact } from "src/app/types/wizard";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  socotraService: SocotraConfigService;
  contactRequest: Contact;
  errors: any = {};
  isFormSubmitted: Boolean;
  isLoading: Boolean;
  isSent: Boolean;

  constructor(
    _socotraService: SocotraConfigService,
    private notificationService: NotificationsService,
    private configurationService: ConfigurationService,
    private translateService: TranslateService
  ) {
    this.socotraService = _socotraService;
    this.contactRequest = {
      FirstName: "",
      LastName: "",
      Email: "",
      Message: "",
      ContactLanguage: "de",
    };
  }

  ngOnInit(): void {}

  hasFieldError(field: string) {
    return Object.keys(this.errors).includes(field);
  }

  isAnyFieldEmpty() {
    return Object.values(this.contactRequest).every(fieldValue => !!fieldValue);
  }

  handleNgModelChange(value, field) {
    value = value.trim();
  }

  send() {
    this.isLoading = true;
    this.isFormSubmitted = true;
    this.errors = {};

    this.contactRequest.ContactLanguage = this.configurationService.getLanguage();
    this.socotraService
      .sendSupportMail(this.contactRequest)
      .then((res) => {
        this.isLoading = false;
        this.isSent = true;

        this.notificationService.success(
          this.translateService.instant("MESSAGE_SENT")
        );
        this.contactRequest = {
          FirstName: "",
          LastName: "",
          Email: "",
          Message: "",
          ContactLanguage: "",
        };
      })
      .catch((err) => {
        this.isLoading = false;
        this.errors = err.error;
        console.log(err);
      });
  }
}
