<header class="d-flex align-items-center justify-content-start">
  <a [routerLink]="'/' | navigateWithLang">
    <div class="logo">
      <img
        [src]="configurationService.getLogoPath()"
        alt="Migros Versicherung"
        width="118"
        height="36"
      />
    </div>
  </a>
  <nav
    (clickOutside)="onClickedOutsideNav($event)"
    [ngClass]="{ mobile: isNavbarOpen }"
    [exclude]="'.btn--navbar'"
  >
    <ul class="links d-none d-xl-flex align-items-center list-unstyled mb-0">
      <li
        class="position-relative"
        (mouseenter)="openSubnav()"
        (mouseleave)="closeSubnav()"
      >
        <button
          class="btn btn--nav-link p-0"
          [ngClass]="{ orange: isSubnavOpen }"
          (clickOutside)="onClickedOutsideSubnav($event)"
        >
          {{ "PRODUCTS" | translate }}
        </button>
        <div
          class="subnav__wrapper"
          [ngClass]="{ 'subnav--open': isSubnavOpen }"
        >
          <div
            class="subnav subnav--products"
            [ngClass]="{ 'subnav--open': isSubnavOpen }"
          >
            <a
              routerLinkActive="orange"
              [routerLink]="'/household-insurance' | navigateWithLang"
              >{{ "HOUSEHOLD_INSURANCE" | translate }}</a
            >
            <a
              routerLinkActive="orange"
              [routerLink]="'/personal-liability' | navigateWithLang"
              >{{ "PERSONAL_LIABILITY_HEADER" | translate }}</a
            >
          </div>
        </div>
      </li>
      <!-- <li>
        <a [routerLink]="'/produkt-aktivieren' | navigateWithLang">{{
          "ACTIVATE_PRODUCT" | translate
        }}</a>
      </li>
      -->
      <li>
        <a
          routerLinkActive="orange"
          [routerLink]="'/report-damage-landing' | navigateWithLang"
          >{{ "REPORT_DAMAGE" | translate }}</a
        >
      </li>
      <li>
        <a
          [routerLink]="'/support' | navigateWithLang"
          routerLinkActive="orange"
          >{{ "SERVICE" | translate }}</a
        >
      </li>
      <li *ngIf="isBlogEnabled">
        <a
          [routerLink]="'/blog' | navigateWithLang"
          routerLinkActive="orange"
          >{{ "BLOG" | translate }}</a
        >
      </li>
    </ul>
    <div class="button__container d-none">
      <button
        class="btn btn-primary"
        [routerLink]="'/wizard/insurance-type' | navigateWithLang"
      >
        {{ "CALCULATE_PREMIUM" | translate }}
      </button>
    </div>
  </nav>
  <div class="elements__container d-flex align-items-center ml-auto">
    <div
      class="dropdown"
      (click)="toggleDropdown()"
      (clickOutside)="onClickedOutsideDropdown($event)"
    >
      <div
        class="bg-transparent border-0 position-relative d-flex align-items-center"
      >
        <button class="btn btn--dropdown d-block">
          {{ selectedLanguage | translate }}
          <svg
            width="7"
            height="5"
            viewBox="0 0 7 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="dropdown__icon"
          >
            <path d="M7 0.5H0L3.5 5L7 0.5Z" fill="#3D4657" />
          </svg>
        </button>
        <div
          class="subnav subnav--langs"
          *ngIf="isDropdownOpen"
          [ngClass]="{ 'subnav--open': isDropdownOpen }"
        >
          <a
            *ngFor="let i of languages"
            (click)="setDefaultLanguage(i.id)"
            class="language"
            >{{ i.key | translate }}</a
          >
        </div>
      </div>
    </div>
    <div class="logged-in">
      <app-account-dropdown
        *ngIf="authService.isLoggedIn"
        [isCockpitLinkVisible]="isCockpitActive"
      >
      </app-account-dropdown>
      <button
        (click)="onLogin()"
        class="btn btn--icon d-block d-lg-none"
        *ngIf="!authService.isLoggedIn"
        [attr.aria-label]="'PERSON' | translate"
      >
        <svg-icon
          src="assets/images/user-login-mobile.svg"
          [title]="'PERSON' | translate"
        ></svg-icon>
      </button>
    </div>
    <button
      (click)="toggleNavbar()"
      class="btn btn--navbar hamburger bg-transparent d-flex d-xl-none align-items-center justify-content-center"
      [attr.aria-label]="'MENU' | translate"
    >
      <svg-icon
        src="assets/images/hamburger-menu.svg"
        [ngClass]="{ 'd-none': isNavbarOpen }"
        [title]="'MENU' | translate"
      ></svg-icon>
      <svg-icon
        src="assets/images/close-icon.svg"
        class="d-none"
        [ngClass]="{ 'd-block': isNavbarOpen }"
      ></svg-icon>
    </button>
    <button
      (click)="onLogin()"
      class="btn btn-tertiary m-login-signin d-none d-lg-flex"
      *ngIf="!authService.isLoggedIn"
    >
      <svg-icon src="assets/images/m-icon.svg"></svg-icon>
      <div class="line"></div>
      <p>{{ "SIGN_IN" | translate }}</p>
    </button>
    <button
      class="btn btn-primary d-none d-lg-flex"
      [routerLink]="'/wizard/insurance-type' | navigateWithLang"
    >
      <p>{{ "CALCULATE_PREMIUM" | translate }}</p>
    </button>
  </div>
</header>
