import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ContentfulService } from "src/app/core/contentful.service";
import { TranslateService } from "@ngx-translate/core";
import { Meta, Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";

@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BlogComponent implements OnInit {
  languageSubscription: Subscription;
  activeCategory: string = "";
  baseTitle: string;
  blogCategories: any[];
  blogEntries: any[] = [];
  blogHeroTitle: string;
  blogHeroText: string;
  loadMore: string;
  isLoading: Boolean = false;
  meta: string[];
  //pagination control
  skip: number = 0;
  limit: number = 6;
  total: number;

  constructor(
    private contentfulService: ContentfulService,
    private translate: TranslateService,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.getBlogHeroContent();
    this.getBlogCategories();
    this.getBlogEntries();
    this.languageSubscription = this.translate.onLangChange.subscribe(() => {
      this.getBlogHeroContent();
      this.getBlogCategories();
      this.getBlogEntries();
    });
  }

  getBlogCategories() {
    this.contentfulService.GetBlogCategories().then((res) => {
      this.blogCategories = res.items.map((x) => x.fields.name);
    });
  }

  getBlogEntries() {
    this.isLoading = true;

    this.contentfulService.GetBlogEntries({ limit: this.limit }).then((res) => {
      this.blogEntries = [...res.items];
      this.total = res.total;
      this.skip = res.items.length;
      this.isLoading = false;
    });
  }

  getMoreBlogEntries() {
    this.isLoading = true;

    this.contentfulService
      .GetBlogEntries({ limit: this.limit, skip: this.skip })
      .then((res) => {
        this.blogEntries = [...this.blogEntries, ...res.items];
        this.total = res.total;
        this.skip = res.skip + res.items.length;
        this.isLoading = false;
      });
  }

  handleCategoryClick(category) {
    if (this.activeCategory === category) {
      this.activeCategory = "";
      this.getBlogEntries();
    } else {
      this.activeCategory = category;
      this.getBlogEntriesByCategory(category);
    }
  }

  getBlogEntriesByCategory(category) {
    this.isLoading = true;

    this.contentfulService
      .GetBlogEntries({
        limit: this.limit,
        "fields.category.sys.contentType.sys.id": "blogCategory",
        "fields.category.fields.name": category,
      })
      .then((res) => {
        this.blogEntries = [...res.items];
        this.total = res.total;
        this.skip = res.items.length;
        this.isLoading = false;
      });
  }

  getMoreBlogEntriesByCategory(category) {
    this.isLoading = true;

    this.contentfulService
      .GetBlogEntries({
        limit: this.limit,
        skip: this.skip,
        "fields.category.sys.contentType.sys.id": "blogCategory",
        "fields.category.fields.name": category,
      })
      .then((res) => {
        this.blogEntries = [...this.blogEntries, ...res.items];
        this.total = res.total;
        this.skip = res.skip + res.items.length;
        this.isLoading = false;
      });
  }

  getBlogHeroContent() {
    this.contentfulService.GetBlogLandingPageContent().then((res) => {
      const [item] = res.items;
      this.blogHeroTitle = item.fields.blogHeroTitle;
      this.blogHeroText = item.fields.blogHeroText;
      this.loadMore = item.fields.loadMore;

      this.meta = item.fields.metaTags || null;
      // Update meta tags & title
      this.baseTitle = this.titleService.getTitle();
      for (const key in this.meta) {
        this.metaService.updateTag({ name: key, content: this.meta[key] });
      }
    });
  }

  handleLoadMore() {
    if (this.activeCategory) {
      this.getMoreBlogEntriesByCategory(this.activeCategory);
    } else {
      this.getMoreBlogEntries();
    }
  }

  canLoadMore() {
    return this.total > this.skip;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();

    for (const key in this.meta) {
      this.metaService.removeTag(`name='${key}'`);
    }
  }
}
