import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { ConfigurationService } from "../core/configuration.service";
import { ContentfulService } from "../core/contentful.service";
import { StorageService } from "../core/storage.service";
import { BrowserStorage } from "./browserStorage.service";

@Injectable({
  providedIn: "root",
})
export class LanguageGuard implements CanActivate {
  constructor(
    private router: Router,
    private browserStorage: BrowserStorage,
    private configurationService: ConfigurationService,
    private contenfulService: ContentfulService,
    private storageService: StorageService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  language: string;

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let languageOfURL = next.params["lang"];

    if (!languageOfURL) {
      this.language = this.getPreferredOrDefaultLanguage();
      await this.setTranslations(this.language);

      return this.router.createUrlTree([`/${this.language}/`], {
        queryParams: state.root.queryParams,
      });
    } else if (this.isLanguageSupported(languageOfURL)) {
      this.language = languageOfURL;
      await this.setTranslations(this.language);

      return true;
    } else {
      this.language = this.getPreferredOrDefaultLanguage();
      await this.setTranslations(this.language);

      return this.router.createUrlTree([`/${this.language}/404`], {
        queryParams: state.root.queryParams,
      });
    }
  }

  isLanguageSupported(lang) {
    const availableLanguages = this.configurationService.config.languages;
    return availableLanguages.findIndex((x) => x.id === lang) > -1;
  }

  getPreferredOrDefaultLanguage() {
    let language = this.configurationService.getLanguage();

    return language && this.configurationService.isLanguageSupported(language)
      ? language
      : this.configurationService.config.defaultLanguage;
  }

  async setTranslations(lang) {
    this.configurationService.setLanguage(lang);
    this.document.documentElement.lang = lang;
    await this.contenfulService.SetTranslations(lang);
  }
}
