import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { NotificationsService } from "./notifications.service";
import "rxjs";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { isObject } from "util";
import "rxjs/Rx";
import { empty } from "rxjs";
import { share, map, catchError } from "rxjs/operators";

@Injectable()
export class RequestService {
  private _options: any = {};

  constructor(
    private http: HttpClient,
    private notificationService: NotificationsService
  ) {}

  public get(api: string, path: string, params: any = null): Observable<any> {
    this._options.params = this.buildHttpParams(params);
    return this.http.get(api.concat(path), this._options).pipe(
      share(),
      map((response: any) => {
        var response = this.handleResponse(response);
        return response;
      }),
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public post(
    api: string,
    path: string,
    body: any,
    xmlContent: boolean = false
  ): Observable<Object> {
    var options: {};
    options = { responseType: "text" };

    return this.http.post(api.concat(path), body, options).pipe(
      share(),
      map((response: any) => {
        return this.handleResponse(response);
      }),
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public put(api: string, path: string, body: any): Observable<Object> {
    return this.http.put(api.concat(path), body, this._options).pipe(
      map((response: any) => {
        return this.handleResponse(response);
      }),
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public delete(
    api: string,
    path: string,
    data: any = null
  ): Observable<Object> {
    this._options.body = data;
    return this.http.delete(api.concat(path), this._options).pipe(
      share(),
      map((response: any) => {
        return this.handleResponse(response);
      }),
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }
  public getFile(url: string, headers: any = {}) {
    return this.http
      .get(url, {
        headers,
        responseType: "arraybuffer",
      })
      .pipe(
        share(),
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          this.notificationService.danger("FILE_DOES_NOT_EXIST");
          return this.handleError(error);
        })
      );
  }

  public getPDF(url: string, headers: any = {}): Observable<any> {
    return this.http
      .get(url, {
        headers,
        responseType: "blob" as "json",
        observe: "response",
      })
      .pipe(
        share(),
        map((response) => {
          let fileName = "undefined";

          try {
            let contentDisposition = response.headers.get("content-disposition");
            if (contentDisposition) {
              fileName = /filename\*?=([^']*'')?([^;]*)/.exec(
                contentDisposition
              )[2];
              fileName =
                fileName ||
                /filename\*?=([^']*'')?([^;]*)/.exec(contentDisposition)[1];
              fileName = decodeURIComponent(fileName);
            }
          } catch (error) {
            fileName = "undefined"
          }

          return this.downLoadFile(response.body, "application/pdf", fileName);
        }),
        catchError((error: any) => {
          this.notificationService.danger("FILE_DOES_NOT_EXIST");
          return empty();
        })
      );
  }

  downLoadFile(data: any, type: string, fileName: string) {
    let blob = new Blob([data], { type: type });
    let response = {
      blob: blob,
      fileName: fileName,
    };
    return response;
  }

  public handleResponse(response: any): any {
    if (response != null) {
      return JSON.parse(JSON.stringify(response), this.dateFormater);
    }
    return response;
  }

  public handleError(error: any): Observable<any> {
    switch (error.status) {
      case 400:
        if (error.error.hasOwnProperty("ModelState"))
          return Observable.throw(error.error.ModelState);
        if (error.error.hasOwnProperty("Message")) {
          this.notificationService.danger(error.error.Message);
          return empty();
        }
        if (error.error != null) {
          if (isObject(error.error)) {
            let valError = error.error[Object.keys(error.error)[0]];
            this.notificationService.danger(valError[0]);
          } else {
            if (error.error.startsWith('["')) {
              this.notificationService.notesNotification(error.error);
              return empty();
            } else {
              this.notificationService.danger(error.error);
            }
          }
          return empty();
        }
        this.notificationService.danger(error.error);
        return empty();
      case 0:
        this.notificationService.danger("UNABLE_TO_CONNECT_API");
        return empty();
      case 403:
        this.notificationService.danger("YOU_DONT_HAVE_PERMISSIONS");
        return empty();
      case 401:
        //   this.authenticationService.logout();
        return empty();
      case 404:
        return empty();
      case 302:
        return empty();
      default:
        this.notificationService.danger(error.statusText);
        return empty();
    }
  }

  public dateFormater(key, value): Date {
    var a;
    if (typeof value === "string") {
      a = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(
        value
      );
      if (a) {
        return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]));
      }
    }
    return value;
  }

  public buildHttpParams(data: any): HttpParams {
    let params = new HttpParams();
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        // if (data[key] instanceof Date) {
        //   params = params.append(
        //     key,
        //     moment(data[key]).format("YYYY-MM-DDTHH:mm:ss")
        //   );
        // }
        if (data[key] instanceof Array) {
          data[key].forEach((element) => {
            params = params.append(key, element);
          });
        } else {
          params = params.append(key, data[key]);
        }
      }
    }
    return params;
  }
}
