import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ConfigurationService } from "src/app/core/configuration.service";
import { AuthService } from "src/app/services/auth.service";
import { NavigateWithLang } from "src/app/shared/pipes/navigateWithLang.pipe";
import { Router } from "@angular/router";
import { PolicyService } from "src/app/core/policy.service";

@Component({
  selector: "app-support",
  templateUrl: "./support.component.html",
  styleUrls: ["./support.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [NavigateWithLang],
})
export class SupportComponent implements OnInit {
  active: string = "top";
  disabled: boolean = false;
  phoneNumberPf: string;
  emailPf: string;

  scrollToFAQ() {
    window.innerWidth < 992 &&
      document
        .querySelector(".tab-content")
        .scrollIntoView({ behavior: "smooth", block: "start" });
  }

  handleTextClicked(event: any) {
    if (event.target.nodeName === "SPAN") {
      this.authService.isLoggedIn
        ? this.router.navigateByUrl(this.navigateWithLang.transform("/cockpit"))
        : this.onLogin();
    }
  }

  async handleAVBDocumentClick(event: any) {
    const language = this.configurationService.getLanguage();

    if (event.target.nodeName === "SPAN") {
      await this.policyService.GetFactsheetDocument(language);
    } else if (event.target.nodeName === "B") {
      await this.policyService.DownloadAvbProduct();
    }
  }

  constructor(
    private configurationService: ConfigurationService,
    private router: Router,
    private navigateWithLang: NavigateWithLang,
    public authService: AuthService,
    private policyService: PolicyService
  ) {
    this.phoneNumberPf = this.configurationService.config.phoneNumber;
    this.emailPf = this.configurationService.config.email;
  }

  ngOnInit(): void {}

  public onLogin() {
    this.authService.login({
      url: this.navigateWithLang.transform("/cockpit"),
    });
  }
}
