import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { ConfigurationService } from "../../core/configuration.service";

@Pipe({
  name: "navigateToMigros",
  pure: false,
})
@Injectable()
export class NavigateToMigros implements PipeTransform {
  constructor(private configurationService: ConfigurationService) {}

  transform(to: "privacy") {
    const currentLanguage = this.configurationService.getLanguage();

    const links = {
      privacy: {
        de: "https://www.migrosbank.ch/ueber-uns/datenschutzerklaerung",
        fr: "https://www.migrosbank.ch/fr/a-propos-de-nous/declaration-relative-protection-donnees.html",
      },
    };

    return links[to][currentLanguage];
  }
}
