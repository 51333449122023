import { Injectable, Injector, Inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class NotificationsService {
  constructor(
    private translate: TranslateService,
    @Inject(Injector) private readonly injector: Injector
  ) {}
  success(message: string, title?: string) {
    if (message != null)
      this.showNotification("success", this.translate.instant(message), title);
  }
  info(message: string, title?: string) {
    if (message != null)
      this.showNotification("info", this.translate.instant(message), title);
  }
  warning(message: string, title?: string) {
    if (message != null)
      this.showNotification("warning", this.translate.instant(message), title);
  }
  danger(message: string, title?: string) {
    if (message != null)
      this.showNotification("danger", this.translate.instant(message), title);
  }

  private showNotification(severity: string, message: string, title?: string) {
    let classes;
    let html;
    let closeBtn = false;
    if (severity == "danger" || severity == "warning") severity = "alert";
    classes = `toast-item toast-item--${severity}`;
    html = `<div class="toast-item__inner--${severity}">
        <p class="toast-item__message">${message}</p></div>`;

    this.toastr.show(html, title, {
      timeOut: 8000,
      enableHtml: true,
      closeButton: closeBtn,
      toastClass: classes,
    });
  }

  notesNotification(messages: string, title?: string) {
    messages = messages.replace("[", "");
    messages = messages.replace("]", "");
    messages = messages.replace(/"/g, "");
    let ms = messages.split(",");
    ms.forEach((m) => {
      this.info(m.toUpperCase().replace(/ /g, "_"), title);
    });
  }

  /**
   * Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
   * @returns {}
   */
  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }
}
