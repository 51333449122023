import { IPeril, IUIPeril } from "../types/wizard";

  export const getExistingPerilOrDefaultConfig = (
    perilName: string,
    selectedPerils: IPeril[],
    defaultPerils: IUIPeril[]
  ) => {
    return (
      selectedPerils.find((peril) => peril.Name === perilName) || {
        ...defaultPerils.find((peril) => peril.Name === perilName),
        IsSelected: true,
      }
    );
  }