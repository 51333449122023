import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, from } from "rxjs";
import { ConfigurationService } from "./configuration.service";
import { AuthService } from "../services/auth.service";
import { isPlatformBrowser } from "@angular/common";
import { StorageService } from "./storage.service";

@Injectable({ providedIn: "root" })
export class SocotraConfigService {
  apiKeyBody = {
    ApiKey: "97D2CFC3675E4A6E8483CEA2B237AC52",
    GrantType: "api_key",
  };

  path: string;
  pathExtension: string;
  pricingPath: string;
  authPath: string;
  policyPath: string;
  commonPath: string;
  private toniDigitalTokenPromise: Promise<any>;
  private toniDigitalLoginTokenPromise: Promise<any>;
  private productsSource: BehaviorSubject<any[]>;
  public products$: Observable<any[]>;

  constructor(
    private httpClient: HttpClient,
    private configurationService: ConfigurationService,
    private authService: AuthService,
    private storageService: StorageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.productsSource = new BehaviorSubject<any[]>([]);
    this.products$ = this.productsSource.asObservable();
    this.configurationService.isConfigReady$.subscribe(() => {
      this.initializeSocotraConfig();
    });

    this.initializeMLoginChangeSubscription();
  }

  initializeSocotraConfig() {
    this.path = `${this.storageService.getItem("staticApiURL")}`;
    this.pathExtension = `tenants/${this.configurationService.config.tenantId}/`;
    this.pricingPath = this.configurationService.config.pricingApi;
    this.authPath = this.configurationService.config.authApi;
    this.policyPath = this.configurationService.config.policyApi;
    this.commonPath = this.configurationService.config.commonApi;
  }

  /**
   * Some APIs requires token for requests.
   * @param {IGetApiTokenOptions} options Some of those APIs allows mLogin tokens but some are not, you can pass 'preferXsrfToken' option to use xsrfToken instead of mLogin token.
   * @returns
   */
  public async getOfferApiToken(
    preferMLoginToken: boolean = false
  ): Promise<string> {
    // If logged-in then exchange mLogin token for ToniDigital token
    if (this.authService.user?.access_token) {
      if (preferMLoginToken) {
        return this.authService.user.access_token;
      }
      if (this.toniDigitalLoginTokenPromise) {
        return (await this.toniDigitalLoginTokenPromise).access_token;
      } else {
        try {
          this.exchangeMLoginToken();
          return (await this.toniDigitalLoginTokenPromise).access_token;
        } catch (error) {
          this.toniDigitalLoginTokenPromise = undefined;
        }
      }
    } else {
      // If not logged-in then get generic ToniDigital token
      if (this.toniDigitalTokenPromise) {
        return (await this.toniDigitalTokenPromise).access_token;
      } else {
        try {
          const res = (await this.httpClient
            .post(this.authPath + "tokens", this.apiKeyBody)
            .toPromise()) as any;

          this.toniDigitalTokenPromise = res;
          return (await this.toniDigitalTokenPromise).access_token;
        } catch (error) {
          this.toniDigitalTokenPromise = undefined;
        }
      }
    }
  }

  initializeMLoginChangeSubscription() {
    this.authService.user$.subscribe(async (user) => {
      if (user) {
        this.exchangeMLoginToken();
      }
    });
  }

  async exchangeMLoginToken() {
    const res = this.httpClient
      .post(
        `${this.configurationService.config.gatewayApi}/privateauth/token`,
        null,
        {
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.authService.user.access_token}`,
            email: this.authService.user.profile.email,
          }),
        }
      )
      .toPromise();

    this.toniDigitalLoginTokenPromise = res;

    return res;
  }

  get products(): any {
    return this.productsSource.getValue();
  }
  set products(products: any) {
    this.productsSource.next(products);
  }

  public async getRequestOptions(
    preferMLoginToken: boolean = false
  ): Promise<{ headers: HttpHeaders }> {
    const token = await this.getOfferApiToken(preferMLoginToken);

    const headerDict = {
      Authorization: "Bearer " + token,
    };

    return {
      headers: new HttpHeaders(headerDict),
    };
  }

  // Get Products (this methods end point url change api/tenants/{id}/products -> to api/tenants/{id}/producttypes)
  public getProductsJson(): Observable<any> {
    if (!isPlatformBrowser(this.platformId)) {
      return Observable.of([]);
    }

    const res = this.httpClient.get(
      this.path + this.pathExtension + "producttypes"
    );
    res?.subscribe((data) => (this.products = data));
    return res;
  }

  public async getInsuranceAmountByRoomCountJson(
    productName: string,
    roomCount: number
  ): Promise<any> {
    return this.httpClient
      .get(
        this.path +
          this.pathExtension +
          "products/" +
          productName +
          "/rooms/" +
          roomCount
      )
      .toPromise();
  }

  public async getIRoomCountJson(productName: string): Promise<any> {
    return this.httpClient
      .get(
        this.path + this.pathExtension + "products/" + productName + "/rooms/"
      )
      .toPromise();
  }

  // Get Perils name
  public async getPerilsNameJson(productName: string): Promise<any> {
    return this.httpClient
      .get(
        this.path + this.pathExtension + "products/" + productName + "/perils"
      )
      .toPromise();
  }

  public async getPrices(pricingBody: any): Promise<any> {
    return this.httpClient
      .post(
        this.pricingPath + this.pathExtension + "prices/householdliability",
        pricingBody
      )
      .toPromise();
  }

  // Get HouseHoldSizes
  public async getHouseHoldSizesJson(): Promise<any> {
    return this.httpClient
      .get(this.path + this.pathExtension + "householdsizes")
      .toPromise();
  }

  // Get tenantvsowners
  public getTenantvsOwnersJson(): Promise<any> {
    return this.httpClient
      .get(this.path + this.pathExtension + "tenantvsowners")
      .toPromise();
  }

  // Get interiorstandards
  public async getInteriorStandardsJson(): Promise<any> {
    return this.httpClient
      .get(this.path + this.pathExtension + "interiorstandards")
      .toPromise();
  }

  public async getCityInfobyZipCode(zipCode: string) {
    const requestOptions = await this.getRequestOptions();
    return this.httpClient
      .get(this.commonPath + "cities/postcode/" + zipCode, requestOptions)
      .toPromise();
  }

  public async getCantonbyId(id: string): Promise<any> {
    const requestOptions = await this.getRequestOptions();

    return this.httpClient
      .get(this.commonPath + "cantons/" + id, requestOptions)
      .toPromise();
  }

  public async getCityId(postcode): Promise<any> {
    const requestOptions = await this.getRequestOptions();
    return this.httpClient
      .get(this.commonPath + "cities/postcode/" + postcode, requestOptions)
      .toPromise();
  }

  public async getCityById(cityId): Promise<any> {
    const requestOptions = await this.getRequestOptions();
    return this.httpClient
      .get(this.commonPath + "cities/" + cityId, requestOptions)
      .toPromise();
  }

  public async getCumulusInfo(): Promise<any> {
    const requestOptions = await this.getRequestOptions(true);
    const mloginConfig = this.configurationService.config.mLogin;
    const cumulusEndPoint = mloginConfig.authority + "api/cumulus/info";

    //"https://login.migros.ch/api/cumulus/info"
    return this.httpClient.get(cumulusEndPoint, requestOptions).toPromise();
  }

  public async sendSupportMail(messageBody): Promise<any> {
    const requestOptions = await this.getRequestOptions();

    return this.httpClient
      .post(
        this.commonPath +
          this.pathExtension +
          "products/householdliability/emails/support",
        messageBody,
        requestOptions
      )
      .toPromise();
  }

  public async patchPolicyholder(
    body: any,
    policyHolderId: string
  ): Promise<any> {
    const requestOptions = await this.getRequestOptions();

    const policyholderURL = `${this.policyPath}tenants/${this.configurationService.config.tenantId}/policyholders/${policyHolderId}/`;

    const policyholderData = await this.httpClient
      .get(policyholderURL, requestOptions)
      .toPromise();

    const newData = {
      ...policyholderData,
      ...body,
    };

    return await this.httpClient
      .patch(
        policyholderURL,
        {
          ...newData,
          PublicId: policyHolderId,
          TenantId: this.configurationService.config.tenantId,
        },
        requestOptions
      )
      .toPromise()
      .then((res) => {
        return <any>res;
      });
  }
}
