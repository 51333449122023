<main class="d-flex flex-column">
  <section class="
      section section--support
      row
      align-items-start
      flex-column-reverse flex-lg-row
      align-items-start align-items-xl-center
      position-relative
    ">
    <img src="assets/images/home-bg.png" alt="background" class="frame-image position-absolute d-none d-xl-block" />
    <div class="section__description col-12 col-lg-6 text-left">
      <h1 class="bold">{{ "SUPPORT_TITLE" | translate }}</h1>
      <h2 class="bold">{{ "WE_ARE_HERE" | translate }}</h2>
      <p>
        {{ "SUPPORT_TEXT" | translate }}
      </p>
      <div class="
          wrapper wrapper--button-support
          d-flex
          flex-column
          align-items-center
          flex-xl-row
          justify-content-start
        ">
        <a [href]="'tel:' + phoneNumberPf" rel="noreferrer" class="btn btn--home justify-content-start">
          <svg-icon src="assets/images/call-icon.svg" class="icon" [svgAriaLabel]="'PHONE_ICON_ALT' | translate">
          </svg-icon>

          {{ phoneNumberPf }}
        </a>
        <a [routerLink]="'/contact' | navigateWithLang" class="btn btn--home justify-content-start">
          <svg-icon src="assets/images/arrow-icon.svg" class="icon" [svgAriaLabel]="'PAPERPLANE_ICON_ALT' | translate">
          </svg-icon>
          {{ "CONTACT_FORM" | translate }}
        </a>
        <a class="btn btn--home justify-content-start" href="mailto:{{ emailPf }}" style="height: 75px;">
          <svg-icon src="assets/images/email-icon.svg" class="icon" [svgAriaLabel]="'EMAIL_ICON_ALT' | translate">
          </svg-icon>
          {{ "EMAIL" | translate }}
        </a>
      </div>
      <span class="small">{{ "WORK_DAYS_TEXT" | translate }}</span>
    </div>
    <div class="
        col-12 col-lg-6
        d-flex
        justify-content-center justify-content-lg-end
        align-item-center
        position-relative
        section__banner
      ">
      <img src="assets/images/home-bg.png" alt="background"
        class="frame-image position-absolute d-block d-xl-none w-100" />
      <img src="assets/images/migros-about.svg" [src]="
          'assets/images/' + ('GIRL_WITH_SKATEBOARD_SRC' | translate) + '.svg'
        " class="section-image" [alt]="'GIRL_WITH_SKATEBOARD_ALT' | translate"
        [title]="'GIRL_WITH_SKATEBOARD_TITLE' | translate" />
      <div class="
          ellipse
          position-absolute
          col-lg-6
          d-xl-flex
          justify-content-center
          d-none
        ">
        <img src="assets/images/ellipse.svg" class="section-image ellipse-image" />
      </div>
    </div>
  </section>
  <section class="section section--download">
    <app-download></app-download>
  </section>

  <section class="section section--faq d-flex flex-column">
    <h2 class="bold">{{ "FAQ_TITLE" | translate }}</h2>
    <div class="d-flex d-flex flex-column flex-lg-row">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills bold" orientation="vertical"
        (click)="scrollToFAQ()">
        <li ngbNavItem="top">
          <a ngbNavLink>{{ "GENERAL_QUESTIONS" | translate }}</a>
          <ng-template ngbNavContent>
            <h3 class="bold tab-content__title">
              {{ "GENERAL_QUESTIONS" | translate }}
            </h3>
            <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1">
              <ngb-panel id="custom-panel-1">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "GENERAL_QUESTIONS_1_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'GENERAL_QUESTIONS_1_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-2">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "GENERAL_QUESTIONS_2_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'GENERAL_QUESTIONS_2_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-3">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header p-0">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "GENERAL_QUESTIONS_3_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'GENERAL_QUESTIONS_3_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </ng-template>
        </li>
        <li ngbNavItem="second">
          <a ngbNavLink>{{ "CONTRACT_QUESTIONS" | translate }}</a>
          <ng-template ngbNavContent>
            <h3 class="bold tab-content__title">
              {{ "CONTRACT_QUESTIONS" | translate }}
            </h3>
            <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1">
              <ngb-panel id="custom-panel-1">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "CONTRACT_QUESTIONS_1_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'CONTRACT_QUESTIONS_1_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-2">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "CONTRACT_QUESTIONS_2_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'CONTRACT_QUESTIONS_2_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-3">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header p-0">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "CONTRACT_QUESTIONS_3_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'CONTRACT_QUESTIONS_3_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-4">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header p-0">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "CONTRACT_QUESTIONS_4_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'CONTRACT_QUESTIONS_4_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-5">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header p-0">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "CONTRACT_QUESTIONS_5_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'CONTRACT_QUESTIONS_5_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-7">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header p-0">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "CONTRACT_QUESTIONS_7_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'CONTRACT_QUESTIONS_7_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-8">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header p-0">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "CONTRACT_QUESTIONS_8_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'CONTRACT_QUESTIONS_8_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </ng-template>
        </li>
        <li ngbNavItem="third">
          <a ngbNavLink>{{ "CANCEL_QUESTIONS" | translate }}</a>
          <ng-template ngbNavContent>
            <h3 class="bold tab-content__title">
              {{ "CANCEL_QUESTIONS" | translate }}
            </h3>
            <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1">
              <ngb-panel id="custom-panel-1">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "CANCEL_QUESTIONS_1_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p (click)="handleCancelDocumentClick($event)" [innerHTML]="'CANCEL_QUESTIONS_1_ANSWER' | translate">
                  </p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-2">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "CANCEL_QUESTIONS_2_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'CANCEL_QUESTIONS_2_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-3">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header p-0">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "CANCEL_QUESTIONS_3_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'CANCEL_QUESTIONS_3_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </ng-template>
        </li>
        <li ngbNavItem="fourth">
          <a ngbNavLink>{{ "DAMAGE_QUESTIONS" | translate }}</a>
          <ng-template ngbNavContent>
            <h3 class="bold tab-content__title">
              {{ "DAMAGE_QUESTIONS" | translate }}
            </h3>
            <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1">
              <ngb-panel id="custom-panel-1">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "DAMAGE_QUESTIONS_1_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'DAMAGE_QUESTIONS_1_ANSWER' | translate" (click)="handleTextClicked($event)"></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-2">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "DAMAGE_QUESTIONS_2_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'DAMAGE_QUESTIONS_2_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-3">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "DAMAGE_QUESTIONS_3_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'DAMAGE_QUESTIONS_3_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </ng-template>
        </li>
        <li ngbNavItem="fifth">
          <a ngbNavLink>{{ "INVOICE_QUESTIONS" | translate }}</a>
          <ng-template ngbNavContent>
            <h3 class="bold tab-content__title">
              {{ "INVOICE_QUESTIONS" | translate }}
            </h3>
            <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1">
              <ngb-panel id="custom-panel-1">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "INVOICE_QUESTIONS_1_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'INVOICE_QUESTIONS_1_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-2">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "INVOICE_QUESTIONS_2_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'INVOICE_QUESTIONS_2_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </ng-template>
        </li>
        <li ngbNavItem="sixth">
          <a ngbNavLink>{{ "HOUSEHOLD_QUESTIONS" | translate }}</a>
          <ng-template ngbNavContent>
            <h3 class="bold tab-content__title">
              {{ "HOUSEHOLD_QUESTIONS" | translate }}
            </h3>
            <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1">
              <ngb-panel id="custom-panel-1">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "HOUSEHOLD_QUESTIONS_1_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'HOUSEHOLD_QUESTIONS_1_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-2">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "HOUSEHOLD_QUESTIONS_2_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p (click)="handleAVBDocumentClick($event)" [innerHTML]="'HOUSEHOLD_QUESTIONS_2_ANSWER' | translate"
                    class="list-disc"></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-3">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{ "HOUSEHOLD_QUESTIONS_3_QUESTION" | translate }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="'HOUSEHOLD_QUESTIONS_3_ANSWER' | translate"></p>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </ng-template>
        </li>
        <li ngbNavItem="seventh">
          <a ngbNavLink>{{ "PERSONAL_LIABILITY_QUESTIONS" | translate }}</a>
          <ng-template ngbNavContent>
            <h3 class="bold tab-content__title">
              {{ "PERSONAL_LIABILITY_QUESTIONS" | translate }}
            </h3>
            <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1">
              <ngb-panel id="custom-panel-1">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{
                        "PERSONAL_LIABILITY_QUESTIONS_1_QUESTION" | translate
                        }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="
                      'PERSONAL_LIABILITY_QUESTIONS_1_ANSWER' | translate
                    "></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-2">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{
                        "PERSONAL_LIABILITY_QUESTIONS_2_QUESTION" | translate
                        }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p (click)="handleAVBDocumentClick($event)" [innerHTML]="
                      'PERSONAL_LIABILITY_QUESTIONS_2_ANSWER' | translate
                    " class="list-disc"></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="custom-panel-3">
                <ng-template ngbPanelHeader let-opened="opened" class="card-header">
                  <button ngbPanelToggle class="btn btn--accordion w-100 h-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="bold text-left">
                        {{
                        "PERSONAL_LIABILITY_QUESTIONS_3_QUESTION" | translate
                        }}
                      </h4>
                      <svg-icon src="assets/images/arrow-down.svg" class="arrow-down"></svg-icon>
                    </div>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p [innerHTML]="
                      'PERSONAL_LIABILITY_QUESTIONS_3_ANSWER' | translate
                    "></p>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </section>
</main>
