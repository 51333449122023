import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[autoDot]",
})
export class DotDirective {
  @Input() ngModel: string;

  constructor(private el: ElementRef) {}

  dots() {
    let dateInput = this.el.nativeElement as HTMLInputElement;
    let lengthOfDateInput = dateInput.value.length;
    let valueOfDateInput = dateInput.value;

    if (lengthOfDateInput == 2) {
      dateInput.value = valueOfDateInput + ".";
    }
    if (lengthOfDateInput == 5) {
      dateInput.value = valueOfDateInput + ".";
    }
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;

    // Check if number
    if (
      (charCode >= 48 && charCode <= 57) ||
      (charCode >= 96 && charCode <= 105)
    ) {
      // If key is a number, call dots
      this.dots();
    } else if (
      charCode == 37 ||
      charCode == 39 ||
      charCode == 46 ||
      charCode == 8 ||
      charCode == 190 ||
      charCode == 110 ||
      charCode == 9 ||
      charCode == 11
    ) {
      // Let right backspace, tab and del pass
    } else {
      // Block other keys
      evt.preventDefault();
    }
  }

  @HostListener("keydown", ["$event"]) onKeydownHandler(event: KeyboardEvent) {
    this.isNumberKey(event);
  }
}
