<h2 class="slider__title text-center bold">{{ "WHAT_CUSTOMERS_SAYS" | translate }}</h2>
<swiper [navigation]="false" [pagination]="false" [config]="config">
  <ng-template swiperSlide>
    <div class="slide__content">
      <p class="slide__content__quote">
        {{ "TESTIMONIALS_1_TEXT" | translate }}
      </p>
      <div class="person-info">
        <img src="assets/images/avatar1.png" alt="Andrea" width="56" height="56" />
        <p>{{ "TESTIMONIALS_1_NAME" | translate }}</p>
      </div>
    </div>
  </ng-template>

  <ng-template swiperSlide>
    <div class="slide__content">
      <p class="slide__content__quote">
        {{ "TESTIMONIALS_2_TEXT" | translate }}
      </p>
      <div class="person-info">
        <img src="assets/images/avatar2.png" alt="Gabriela" width="56" height="56" />
        <p>{{ "TESTIMONIALS_2_NAME" | translate }}</p>
      </div>
    </div>
  </ng-template>

  <ng-template swiperSlide>
    <div class="slide__content">
      <p class="slide__content__quote">
        {{ "TESTIMONIALS_3_TEXT" | translate }}
      </p>
      <div class="person-info">
        <img src="assets/images/avatar3.png" alt="Daniel" width="56" height="56" />
        <p>{{ "TESTIMONIALS_3_NAME" | translate }}</p>
      </div>
    </div>
  </ng-template>
</swiper>
