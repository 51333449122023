import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { ConfigurationService } from "src/app/core/configuration.service";

@Injectable({
  providedIn: "root",
})
export class UnsubscribeRedirectHandler implements CanActivate {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const language = next.paramMap.get("lang");
    const policyPublicId = next.paramMap.get("policyPublicId");

    return this.router.createUrlTree([
      `/${language}/unsubscribe/${policyPublicId}`,
    ]);
  }
}
