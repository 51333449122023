import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PolicyService } from "src/app/core/policy.service";

@Component({
  templateUrl: "./unsubscribe.component.html",
  styleUrls: ["./unsubscribe.component.scss"],
})
export class UnsubscribeComponent implements OnInit {
  unsubscriptionStatus: "pending" | "unsubscribed" | "failed" = "pending";
  emailAddress: string;

  constructor(
    private policyService: PolicyService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.unsubscribe();
  }

  async unsubscribe() {
    try {
      const policyPublicId =
        this.activatedRoute.snapshot.params["policyPublicId"];
      const policyDetails = await this.policyService.getPolicyDetail(
        policyPublicId
      );
      const productName = policyDetails.ProductName;
      this.emailAddress = policyDetails.PolicyHolderEmail;

      const res = await this.policyService.unsubscribeEmail(
        productName,
        policyPublicId
      );

      if (res) this.unsubscriptionStatus = "unsubscribed";
    } catch (error) {
      console.error(error);
    }
  }
}
