<div class="modal-content__container">
  <div class="modal-header">
    <h3 class="modal-header__title">
      {{ "DO_YOU_WANT_TO_LEAVE" | translate }}
    </h3>
  </div>
  <div class="modal-body">
    <p>{{ "YOU_WILL_LOSE_DATA" | translate }}</p>
  </div>
  <div class="modal-footer">
    <app-wizard-button variant="secondary" (click)="activeModal.close(true)">
      {{ "LEAVE" | translate }}
    </app-wizard-button>
    <app-wizard-button variant="primary" (click)="activeModal.dismiss()">
      <span tabindex="0" ngbAutofocus>{{ "STAY" | translate }}</span>
    </app-wizard-button>
  </div>
</div>
