<div class="container">
  <div class="row">
    <div class="section__description col-12 col-lg-6 ml-auto mr-auto mt-4">
      <ng-container [ngSwitch]="unsubscriptionStatus">
        <ng-container *ngSwitchCase="'pending'">
          <h2>{{ "PLEASE_WAIT" | translate }}</h2>
        </ng-container>
        <ng-container *ngSwitchCase="'unsubscribed'">
          <h2>{{ "SUCCESSFULLY_UNSUBSCRIBED" | translate }}</h2>
          <p>
            {{ "YOUR_UNSUBSCRIBED_EMAIL_ADDRESS" | translate }}
            <b>{{ emailAddress }}</b>
          </p>
        </ng-container>
        <ng-container *ngSwitchCase="'failed'">
          <h2>{{ "COULDNT_UNSUBSCRIBE" | translate }}</h2>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
