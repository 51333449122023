<main class="d-flex flex-column">
  <section class="section section--our-mission">
    <div class="section__description col-12 col-lg-6">
      <h1>{{ "OUR_MISSION" | translate }}</h1>
      <h2 class="bold">{{ "OUR_MISSION_SUBTITLE" | translate }}</h2>
      <div class="content-justify" [innerHTML]="'OUR_MISSION_TEXT' | translate"></div>
    </div>
  </section>
  <section class="
      section section--why-migros-insurance
      row
      align-items-center
      position-relative
    ">
    <img src="assets/images/home-bg.png" alt="backgorund" class="frame-image position-absolute d-none d-xl-block" />
    <div class="section__description col-12 col-lg-6">
      <h2 class="bold">{{ "OUR_RANGE" | translate }}</h2>
      <div class="content-justify" [innerHTML]="'OUR_RANGE_TEXT' | translate"></div>
    </div>
    <div class="col-lg-6 d-none d-lg-flex justify-content-center position-relative">
      <img src="assets/images/warum-mit.svg" [src]="
          'assets/images/' + ('SUPERHERO_WOMAN_ICON_SRC' | translate) + '.svg'
        " [alt]="'SUPERHERO_WOMAN_ICON_ALT' | translate" [title]="'SUPERHERO_WOMAN_ICON_TITLE' | translate"
        class="section-image" />
      <div class="
          shield
          position-absolute
          col-lg-6
          d-none d-lg-flex
          justify-content-center
        ">
        <img src="assets/images/migros-shield.svg" class="section-image shield-image" />
      </div>
    </div>
  </section>
  <section class="section section--our-range">
    <div class="section__description col-12 col-lg-6">
      <h2 class="bold">{{ "WHY_MIGROS_INSURANCE" | translate }}</h2>
      <div class="content-justify" [innerHTML]="'WHY_MIGROS_INSURANCE_TEXT' | translate"></div>
    </div>
  </section>
</main>
