interface IWizardStep {
  path: string;
  stepNumber: number;
  component: any;
}

export const wizardRootPath = 'wizard'

export const wizardSteps: Array<IWizardStep> = [
  {
    path: "insurance-type",
    stepNumber: 1,
    component: "IwInsuranceTypeComponent",
  },
  {
    path: "options",
    stepNumber: 2,
    component: "IwPrivateHouseholdItemComponent",
  },
  {
    path: "personal-information",
    stepNumber: 3,
    component: "IwPersonalInformationComponent",
  },
  { path: "summary", stepNumber: 4, component: "IwSummaryComponent" },
  { path: "final", stepNumber: 5, component: "IwFinalComponent" },
];
