<div class="modal-content__container">
  <div class="modal-header">
    <h3 class="modal-header__title">
      {{ "DOWNLOAD_MODAL_TITLE" | translate }}
    </h3>
  </div>
  <div class="modal-body">
    <p>{{ "DOWNLOAD_MODAL_DESCRIPTION" | translate }}</p>
    <strong>{{ filename }}</strong>
  </div>
  <div class="modal-footer">
    <app-wizard-button variant="secondary" (click)="activeModal.dismiss()">
      {{ "CANCEL" | translate }}
    </app-wizard-button>
    <a [href]="url" target="_blank" rel="noreferrer" (click)="onDownloadClick()">
      <span tabindex="0" ngbAutofocus>{{ "DOWNLOAD" | translate }}</span>
    </a>
  </div>
</div>
