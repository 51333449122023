import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import 'rxjs/Rx';

@Injectable()
export class TokenStorage {

  public getAccessToken(): string {
    const token: string = <string>sessionStorage.getItem('accessToken');
    return token;
  }

  public getRefreshToken(): Observable<string> {
    const token: string = <string>sessionStorage.getItem('refreshToken');
    return of(token);
  }

  public getRefreshTime(): Observable<number> {
    const refreshTime: number = Number(sessionStorage.getItem('refreshTime'));
    return of(refreshTime);
  }

  public getUsername(): Observable<string> {
    const userName: string = <string>sessionStorage.getItem('username');
    return of(userName);
  }

  public getUserId(): string {
    return <string>sessionStorage.getItem('userId');
  }

  public getFullName(): string{
    return <string>sessionStorage.getItem('fullName');
  }

  public setFullName(fullName: string): TokenStorage{
    sessionStorage.setItem('fullName', fullName);
    return this;
  }

  public setAccessToken(token: string): TokenStorage {
    sessionStorage.setItem('accessToken', token);
    return this;
  }

  public setRefreshToken(token: string): TokenStorage {
    sessionStorage.setItem('refreshToken', token);
    return this;
  }

  public setRefreshTime(refreshTime: number): TokenStorage {
    sessionStorage.setItem('refreshTime', refreshTime.toString());
    return this;
  }

  public setUsername(userName: string): TokenStorage {
    sessionStorage.setItem('username', userName);
    return this;
  }

  public setUserId(userId: string): TokenStorage {
    sessionStorage.setItem('userId', userId);
    return this;
  }

  public setLanguage(languageId: string): TokenStorage{
    sessionStorage.setItem('language', languageId);
    return this;
  }

  public getLanguage(): string{
    return <string>sessionStorage.getItem('language');
  }
  
  public clear() {
    sessionStorage.clear();
  }
}