import { Injectable } from "@angular/core";
import { ConfigurationService } from "../core/configuration.service";
import { HttpClient } from "@angular/common/http";
import { ipInfo } from "../types/ipInfo";

@Injectable()
export class IPService {
  ipInfo: ipInfo;
  ipInfoToken: string;

  constructor(
    protected configurationService: ConfigurationService,
    private httpClient: HttpClient
  ) {
    this.ipInfoToken = this.configurationService.config.ipinfoToken;
  }

  public async fetchIPInfo(): Promise<any> {
    const res = await this.httpClient
      .get(`https://ipinfo.io/?token=${this.ipInfoToken}`)
      .toPromise();

    // @ts-ignore
    this.ipInfo = res;

    return this.ipInfo;
  }

  public async getIPInfo(): Promise<ipInfo> {
    if (this.ipInfo) return Promise.resolve(this.ipInfo);
    const res = await this.fetchIPInfo();
    
    return res;
  }
}
