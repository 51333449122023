import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-file-download-modal",
  templateUrl: "./file-download-modal.component.html",
  styleUrls: ["./file-download-modal.component.scss"],
})
export class FileDownloadModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  @Input() filename;
  @Input() url;
  @Input() callback = () => undefined;

  ngOnInit(): void {}

  onDownloadClick() {
    this.callback();
    this.closeModal();
  }

  closeModal() {
    this.activeModal.close();
  }
}
