import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Meta, DomSanitizer } from "@angular/platform-browser";
import { ContentfulService } from "src/app/core/contentful.service";
import { ConfigurationService } from "src/app/core/configuration.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "src/app/core/navigation.service";
import { NgxSpinnerService } from "ngx-spinner";

import { Entry } from "contentful";
import { BLOCKS } from "@contentful/rich-text-types";

import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { NavigateWithLang } from "src/app/shared/pipes/navigateWithLang.pipe";
import { Subscription } from "rxjs";
import { PageTitleService } from "src/app/services/page-title.service";

@Component({
  selector: "app-blog-entry",
  templateUrl: "./blog-entry.component.html",
  styleUrls: ["./blog-entry.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BlogEntryComponent implements OnInit, OnDestroy {
  baseTitle: string;
  id: string;
  slug: string;
  isLoading: Boolean = true;
  author: any;
  createdAt: string;
  publishDate: string;
  blogEntry: Entry<any>[];
  title: string;
  crossSellingOption: string = "none";
  coverTitle: string;
  coverDescription: string;
  coverImage: any;
  coverImageUrl: string;
  coverImageWidth: number;
  coverImageHeight: number;
  authorProfilePhotoUrl: string;
  shareUrl: string;
  meta: string[];

  languageSubscription: Subscription;

  constructor(
    private contentfulService: ContentfulService,
    private route: ActivatedRoute,
    private router: Router,
    private pageTitleService: PageTitleService,
    private metaService: Meta,
    private navigationService: NavigationService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private configurationService: ConfigurationService,
    private navigateWithLang: NavigateWithLang,
    public sanitizer: DomSanitizer
  ) {
    const slug = this.route.snapshot.paramMap.get("slug");
    this.route.url.subscribe((url) => this.getBlogEntry(slug));

    this.languageSubscription = this.translate.onLangChange.subscribe(
      async (lang) => {
        if (this.id) {
          const res = await this.contentfulService.GetAlternativeSlugs(this.id);

          const newLangSlug =
            res["items"][0]["fields"]["slug"][
              this.configurationService.getLanguage()
            ];

          this.router.navigateByUrl(
            this.navigateWithLang.transform(`/blog/${newLangSlug}`),
            { replaceUrl: true }
          );
        }
      }
    );

    this.shareUrl = `${this.configurationService.config.mLogin.client_root}${
      this.router.url
    }`;
  }

  renderRichTextHtml(richText) {
    if (richText?.nodeType !== "document") return "<p></p>";

    const embeddedAssetRenderer = (node) => {
      const { description = "" } = node?.data?.target?.fields;
      const url = `https:${node.data.target.fields.file?.url}`;

      return `<img src="${url}?w=900" alt="${description}" width="100%" height="auto">`;
    };

    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) =>
          `<custom-component>${embeddedAssetRenderer(node)}</custom-component>`,
      },
    };

    return documentToHtmlString(richText, options);
  }

  async getBlogEntry(slug) {
    this.slug = slug;
    this.spinner.show();
    this.isLoading = true;
    const res = this.route.snapshot.data["contentfulEntry"];

    if (res?.items?.length) {
      const [item] = res.items;

      this.id = item.sys.id;
      this.createdAt = item.sys.createdAt;
      this.publishDate = item.fields.publishDate;
      this.blogEntry = item.fields.content;
      this.author = item.fields.author || null;
      this.coverImage = item.fields.coverImage;
      this.coverTitle = item.fields.coverImage.fields.title;
      this.coverDescription = item.fields.coverImage.fields.description;
      this.coverImageUrl = `https:${item.fields.coverImage.fields.file.url}`;
      this.coverImageWidth =
        item.fields.coverImage.fields.file.details.image.width;
      this.coverImageHeight =
        item.fields.coverImage.fields.file.details.image.height;
      this.authorProfilePhotoUrl =
        `https:${item.fields?.author?.fields?.profilePhoto?.fields?.file?.url}` ||
        "";
      this.title = item.fields.title;
      this.crossSellingOption = item.fields.crossSellingOption;
      this.meta = item.fields.metaTags || null;

      this.pageTitleService.setPageTitle(this.title);
      for (const key in this.meta) {
        this.metaService.updateTag({ name: key, content: this.meta[key] });
      }
    } else {
      this.spinner.hide();
      this.navigationService.navigateTo(["/404"], { replaceUrl: true });
    }
    this.spinner.hide();
    this.isLoading = false;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();
    for (const key in this.meta) {
      this.metaService.removeTag(`name='${key}'`);
    }
  }
}
