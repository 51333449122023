import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-wizard-footer",
  templateUrl: "./wizard-footer.component.html",
  styleUrls: ["./wizard-footer.component.scss"],
})
export class WizardFooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {}
}
