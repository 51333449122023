import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { ConfigurationService } from "../core/configuration.service";
import { HttpClient } from "@angular/common/http";
import { FacebookEvent } from "../types/facebookEvent";
import { SocotraConfigService } from "../core/socotraConfig.service";
import { IPService } from "./ip.service";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class FacebookConversionService {
  gatewayApi: string = this.configurationService.config.gatewayApi;

  constructor(
    protected configurationService: ConfigurationService,
    private httpClient: HttpClient,
    private socotraConfigService: SocotraConfigService,
    private IPService: IPService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  public async postFacebookConversionEvent(
    eventBody: FacebookEvent
  ): Promise<any> {
    try {
      if (isPlatformBrowser(this.platformId)) {
        const requestOptions =
          await this.socotraConfigService.getRequestOptions();
        const ipInfo = await this.getIPInfoWithTimeout();

        eventBody = {
          ...eventBody,
          country: ipInfo?.country || "CH",
          currency: "CHF",
          eventSourceUrl: window.location.href,
          clientUserAgent: window.navigator.userAgent,
        };

        return this.httpClient
          .post(
            this.gatewayApi + "/FacebookConversion/conversion",
            eventBody,
            requestOptions
          )
          .toPromise();
      }
    } catch (error) {
      return undefined;
    }
  }

  public async getIPInfoWithTimeout(): Promise<any> {
    const TIMEOUT = 3000;
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Timeout")), TIMEOUT)
    );
    try {
      const ipInfo = await Promise.race([
        this.IPService.getIPInfo(),
        timeoutPromise,
      ]);
      return ipInfo;
    } catch (error) {
      return undefined;
    }
  }
}
