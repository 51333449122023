import { Component, OnInit, Input, Inject, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
import { ConfigurationService } from "src/app/core/configuration.service";
import { MessagesService } from "src/app/core/messages.service";
import { AuthService } from "src/app/services/auth.service";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: "app-account-dropdown",
  templateUrl: "./account-dropdown.component.html",
  styleUrls: ["./account-dropdown.component.scss"],
})
export class AccountDropdownComponent implements OnInit {
  isDropdownOpen = false;
  name;
  email;
  accountEditLink;
  @Input() message;
  @Input() isCockpitLinkVisible;
  @Input() showMessages;
  @Input() changeUserInfo;

  constructor(
    private router: Router,
    private configurationService: ConfigurationService,
    private authService: AuthService,
    public messagesService: MessagesService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.messagesService.getUnreadMessagesCount();
      this.getUserData();
      this.calculateAccountLink();
      this.router.events.subscribe(() => this.calculateAccountLink());
    }
  }

  calculateAccountLink() {
    this.accountEditLink = `${this.configurationService
      .config.mLogin
      .profile_inlink.replace(
        "{{language}}",
        this.configurationService.getLanguage()
      )}?return_to=${this.configurationService.config.mLogin.client_root}${
      this.router.url
    }`;
  }

  async getUserData() {
    const user = this.authService.user;

    if (user) {
      this.name = user.profile.name;
      this.email = user.profile.email;
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onClickedOutsideDropdown(e: Event) {
    this.isDropdownOpen = false;
  }

  ngOnInit(): void {}
}
