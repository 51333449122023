// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Contentful API authentication credentials
  contentful: {
    space: '7z9ko79p2dpd',
    accessToken: 'xI5Whltjhdk3ldKOFXHtmV9r0Kqc_FOZpDvlPO2bmUp0',
  },
  // URL where this app is hosted
  // hostUrl: 'https://migros-insurances.local.com:4300',
  hostUrl: 'https://migros-insurances.local.com:4300',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
