import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { SwiperOptions } from "swiper";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/core";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
})
export class SliderComponent {
  config: SwiperOptions = {
    // If we need pagination
    pagination:
      isPlatformBrowser(this.platformId) && window.innerWidth < 768
        ? { clickable: true }
        : false,
    breakpoints: {
      1300: {
        spaceBetween: 18,
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 3,
      },
      300: {
        slidesPerView: 1,
      },
    },
  };

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
}
