import { Inject, Injectable, Optional, PLATFORM_ID } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { StorageService } from "./storage.service";
import { BehaviorSubject, Observable } from "rxjs";
import {
  makeStateKey,
  StateKey,
  TransferState,
} from "@angular/platform-browser";
import { isPlatformBrowser } from "@angular/common";
import { BrowserStorage } from "../services/browserStorage.service";
import { ConfigurationHelper } from "../shared/configuration-helper-abstract";
import { DOMAIN, PAGE_URL } from "src/app/types/di_tokens";

interface IStaticConfig {
  staticApiURL: string;
  gatewayApi: string;
}

enum StaticConfigFields {
  staticApiURL,
  gatewayApi,
}
enum ConfigFields {
  authApi,
  commonApi,
  policyApi,
  pricingApi,
  selfApi,
  userApi,
  defaultLanguage,
  defaultCurrency,
  datePickerLocale,
  spinnerTimer,
  companyName,
  tenantId,
  tenantPublicId,
  tenantNameSuffix,
  phoneNumber,
  address,
  email,
  defaultCultureInfoId,
  isBlogEnabled,
  contentfulOptions,
  contentfulBlogOptions,
  languages,
  insuranceTypePerils,
  householdPerils,
  liabilityPerils,
  mLogin,
  gtmId,
  ipinfoToken,
}
type ConfigKeys = keyof typeof ConfigFields | keyof typeof StaticConfigFields;

export type Config = Record<ConfigKeys, any>;

@Injectable({ providedIn: "root" })
export class ConfigurationService {
  public config: { [key in ConfigKeys]: any } = {} as any;

  private isConfigReadySource: BehaviorSubject<Boolean> =
    new BehaviorSubject<Boolean>(false);
  public isConfigReady$: Observable<Boolean> =
    this.isConfigReadySource.asObservable();

  private localDevHosts = [
    "migros-insurances.local.com",
    "migros-insurances.local.jsless.com",
  ];

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional()
    @Inject(DOMAIN)
    private domain: string,
    @Optional()
    @Inject(PAGE_URL)
    private pageUrl: string,
    private browserStorage: BrowserStorage,
    private configurationHelper: ConfigurationHelper,
    private transferState: TransferState
  ) {}

  public async getStaticConfig(): Promise<IStaticConfig> {
    const staticConfigStateKey: StateKey<string> =
      makeStateKey<string>("staticApiURL");

    let staticConfig;

    // If config already fetched during SSR
    if (this.transferState.hasKey(staticConfigStateKey)) {
      staticConfig = this.transferState.get(staticConfigStateKey, null);
    } // If config not already fetched, fetch it
    else {
      staticConfig = await this.configurationHelper.getConfigFile(
        "assets/config/staticConfig.json?v=1.02"
      );
    }

    this.transferState.set(staticConfigStateKey, staticConfig);

    return JSON.parse(staticConfig);
  }

  public async getConfig(configApiUrl) {
    const configStateKey: StateKey<string> = makeStateKey<string>(
      "domainConfiguration"
    );

    var url: string = isPlatformBrowser(this.platformId)
      ? window.location.href
      : this.domain;
    const domainCapturingRegex = new RegExp(
      "(?:http://|https://|^)([A-z0-9-.]*)"
    );
    const domain = domainCapturingRegex.exec(url)[1]; // Gets first capturing group from matched string

    let config;

    // If config already fetched during SSR
    if (this.transferState.hasKey(configStateKey)) {
      config = JSON.parse(this.transferState.get(configStateKey, null));
    } else {
      // If config not already fetched, fetch it
      if (this.localDevHosts.includes(domain)) {
        config = JSON.parse(
          await this.configurationHelper.getConfigFile(
            "/assets/config/localhost.json"
          )
        );
      } else {
        config = await this.httpClient
          .get(`${configApiUrl}/configurations/applicationsettings/app/${domain}`)
          .toPromise();
      }

      this.transferState.set(configStateKey, JSON.stringify(config));
    }

    return config;
  }

  public saveConfig(config: Record<string, string | object>) {
    // Save Config
    Object.entries(config).forEach(([key, value]) => {
      const storageService = this.storageService;
      storageService.setItem(key, value);

      // Define getter and setters for each configuration which stored in StorageService
      Object.defineProperty(this.config, key, {
        get() {
          return storageService.getItem(key);
        },
        set(data: any) {
          return storageService.setItem(key, data);
        },
      });
    });

    this.isConfigReadySource.next(true);
  }

  getDomain(href) {
    let domain: string = href.replace("http://", "");
    domain = domain.replace("https://", "");
    let index = domain.indexOf("#");
    if (index != -1) domain = domain.substring(0, domain.indexOf("#") - 1);
    else {
      index = domain.indexOf("/");
      if (index != -1) domain = domain.substring(0, domain.indexOf("/"));
    }

    domain = domain.split(":")[0];

    return domain;
  }

  resolveLanguage() {
    const langFromUrl = isPlatformBrowser(this.platformId)
      ? window.location.pathname.split("/")[1]
      : this.pageUrl.split("/")[1];

    if (langFromUrl && this.isLanguageSupported(langFromUrl)) {
      this.browserStorage.setItem("language", langFromUrl, "session");
    } else {
      this.browserStorage.setItem(
        "language",
        this.config.defaultLanguage,
        "session"
      );
    }
  }
  isLanguageSupported(lang: string | null) {
    return this.config.languages.find((lng: any) => lng.id === lang);
  }
  public getLanguage(): string {
    return this.browserStorage.getItem("language", "session");
  }
  public setLanguage(languageId: string) {
    this.browserStorage.setItem("language", languageId, "session");
    return this;
  }

  public getLogoPath() {
    return `/assets/images/${this.getLanguage()}/logo.svg`;
  }
}
