<div class="notice">
  <div class="notice__icon">
    <svg-icon
      [src]="iconPath"
      ngbPopover="{{ option.info }}"
      triggers="mouseenter click:mouseleave"
      [placement]="['top','left']"
    ></svg-icon>
  </div>
  <div *ngIf="option.text" class="small">
    <p [innerHtml]="option.text"></p>
  </div>
</div>
