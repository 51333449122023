
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { ClickOutsideModule } from "ng-click-outside";
import { NgxMaskModule } from "ngx-mask";
import { AccountDropdownComponent } from "../components/account-dropdown/account-dropdown.component";
import { WizardButtonComponent } from "../components/wizard-button/wizard-button.component";
import { WizardLinkButtonComponent } from "../components/wizard-button/wizard-link-button.component";
import { WizardFooterComponent } from "../components/wizard-footer/wizard-footer.component";
import { WizardMessageComponent } from "../components/wizard-message/wizard-message.component";
import { WizardNavigationBarComponent } from "../components/wizard-navigation-bar/wizard-navigation-bar.component";
import { DotDirective } from "./directives/dot.directive";
import { FormatDateTimestamp } from "./pipes/format-date-timestamp";
import { FormatDate } from "./pipes/formatDate";
import { MoneyFormatterPipe } from "./pipes/money-formatter.pipe";
import { NavigateToMigros } from "./pipes/navigateToMigros.pipe";
import { NavigateWithLang } from "./pipes/navigateWithLang.pipe";

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    TranslateModule,
    AngularSvgIconModule.forRoot(),
    RouterModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    MoneyFormatterPipe,
    NavigateWithLang,
    NavigateToMigros,
    WizardButtonComponent,
    WizardLinkButtonComponent,
    WizardMessageComponent,
    AccountDropdownComponent,
    FormatDate,
    DotDirective,
    WizardNavigationBarComponent,
    WizardFooterComponent,
    FormatDateTimestamp
  ],
  exports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    AngularSvgIconModule,
    TranslateModule,
    MoneyFormatterPipe,
    NavigateWithLang,
    NavigateToMigros,
    WizardButtonComponent,
    WizardLinkButtonComponent,
    RouterModule,
    DotDirective,
    WizardMessageComponent,
    AccountDropdownComponent,
    WizardNavigationBarComponent,
    WizardFooterComponent,
    FormatDate,
    FormatDateTimestamp,
    NgxMaskModule
  ],
  providers: [FormatDate, FormatDateTimestamp],
})
export class SharedModule {}
