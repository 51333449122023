import { Meta } from "@angular/platform-browser";
import { Component, OnInit } from "@angular/core";
import { PageTitleService } from "src/app/services/page-title.service";

@Component({
  selector: "app-not-found",
  templateUrl: "./under-construction.html",
  styleUrls: ["./under-construction.scss"],
})
export class UnderConstructionComponent implements OnInit {
  constructor(
    private pageTitleService: PageTitleService,
    private metaService: Meta
  ) {
    this.changeTitle();
  }

  ngOnInit(): void {}

  changeTitle() {
    this.pageTitleService.setPageTitle("Under Construction");
    this.metaService.removeTag(`name=description`);
  }
}
