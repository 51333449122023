import { Injectable } from "@angular/core";
import "rxjs/Rx";
import { StorageService } from "./storage.service";

@Injectable()
export class AppStorage {
  constructor(private storageService: StorageService) {}

  public getLogoPath(): string {
    const data: string = this.storageService.getItem("logoPath");
    return data;
  }

  public setLogoPath(path: string): AppStorage {
    this.storageService.setItem("logoPath", path);
    return this;
  }

  public getBackgroundPath(): string {
    const data: string = this.storageService.getItem("bgPath");
    return data;
  }

  public setBackgroundPath(path: string): AppStorage {
    this.storageService.setItem("bgPath", path);
    return this;
  }

  public getIconPaths(): Object {
    return this.storageService.getItem("iconPaths");
  }

  public setIconPaths(icons: Object): AppStorage {
    this.storageService.setItem("iconPaths", icons);
    return this;
  }

  public getHomeHeroFamilie(): string {
    const data: string = this.storageService.getItem("heroFamiliePath");
    return data;
  }

  public setHomeHeroFamilie(path: string): AppStorage {
    this.storageService.setItem("heroFamiliePath", path);
    return this;
  }

  public getHomeHeroJung(): string {
    const data: string = this.storageService.getItem("heroJungPath");
    return data;
  }

  public setHomeHeroJung(path: string): AppStorage {
    this.storageService.setItem("heroJungPath", path);
    return this;
  }

  public getHomeHeroStudenten(): string {
    const data: string = this.storageService.getItem("heroStudentenPath");
    return data;
  }

  public setHomeHeroStudenten(path: string): AppStorage {
    this.storageService.setItem("heroStudentenPath", path);
    return this;
  }

  public getHomeHeroOptimierer(): string {
    const data: string = this.storageService.getItem("heroOptimiererPath");
    return data;
  }

  public setHomeHeroOptimierer(path: string): AppStorage {
    this.storageService.setItem("heroOptimiererPath", path);
    return this;
  }
}
