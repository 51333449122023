<main class="d-flex flex-column">
  <article *ngIf="blogEntry" class="blog-entry">
    <!-- Picture in header  -->
    <header class="header">
      <div class="blog-image">
        <div class="blog-image__svg-placeholder" [innerHTML]="sanitizer.bypassSecurityTrustHtml('
          <svg id=&quot;coverImagePlaceholder&quot; viewBox=&quot;0 0 '+ coverImageWidth + ' ' + coverImageHeight + '&quot; preserveAspectRatio=&quot;xMidYMid meet&quot;></svg>
        ')"></div>
        <img [width]="coverImageWidth" [height]="coverImageHeight" [src]="coverImageUrl" [alt]="coverDescription" [title]="coverTitle">
      </div>
      <div class="container">
        <h1 class="title">{{ title }}</h1>
        <div class="sub-text-container d-flex flex-row justify-content-center">
          <address *ngIf="author" class="author">Posted by {{ author?.fields?.name }}</address>
          <span *ngIf="author">&nbsp;|&nbsp;</span>
          <time *ngIf="publishDate" class="date" datetime="2020-06-15">{{ publishDate | formatDate }}</time>
        </div>
      </div>
    </header>
    <!-- Link to SM  -->
    <div class="social">
      <a shareButton="facebook">
        <svg-icon src="assets/images/social-face.svg"></svg-icon>
      </a>
      <a shareButton="twitter">
        <svg-icon src="assets/images/social-twitter.svg">
        </svg-icon>
      </a>
      <a shareButton="linkedin">
        <svg-icon src="assets/images/social-linkedin.svg"></svg-icon>
      </a>
      <a shareButton="xing">
        <svg-icon src="assets/images/social-xing.svg"></svg-icon>
      </a>
    </div>
    <!-- Rich text content  -->
    <div [innerHtml]="renderRichTextHtml(blogEntry)" class="richtext">
    </div>
    <!-- Cross selling to Products  -->
    <div *ngIf="crossSellingOption !== 'Do not display'" class="cross-sell" [ngSwitch]="crossSellingOption">
      <section class="section section--household-insurance flex-column-reverse flex-md-row row ml-0 align-items-start"
        *ngSwitchCase="'Household insurance'">
        <div class="section__description col-12 col-md-6">
          <h2 class="bold">{{ "HOUSEHOLD_INSURANCE" | translate }}</h2>
          <p>
            {{ "HOUSEHOLD_INSURANCE_DESCRIPTION" | translate }}
          </p>
          <div
            class="cross-sell__buttons d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-md-start">
            <button class="btn btn-primary" [routerLink]="'/wizard/insurance-type' | navigateWithLang">
              {{ "CALCULATE_PREMIUM" | translate }}
            </button>
            <button class="btn btn-text" [routerLink]="'/household-insurance' | navigateWithLang">
              {{ "MORE_INFO" | translate }}
            </button>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-md-0 d-flex justify-content-xl-center justify-content-sm-start pl-4">
          <img src="assets/images/hausrat-frame.svg"
            [src]="'assets/images/'+('GUY_WITH_SMARTPHONE_SRC' | translate)+'.svg'" class="section-image"
            [alt]="'GUY_WITH_SMARTPHONE_ALT' | translate" [title]="'GUY_WITH_SMARTPHONE_TITLE' | translate" />
        </div>
      </section>
      <section
        class="section section--personal-insurance row flex-column flex-md-row align-items-start align-items-lg-center"
        *ngSwitchCase="'Personal liability insurance'">
        <div class="col-12 col-md-6 d-flex justify-content-sm-start mt-md-5 mt-lg-0 pr-4">
          <img src="assets/images/privat-frame.svg"
            [src]="'assets/images/' + ('WOMAN_WITH_BICYCLE_SRC' | translate)+ '.svg'"
            [alt]="'WOMAN_WITH_BICYCLE_ALT' | translate" [title]="'WOMAN_WITH_BICYCLE_TITLE' | translate"
            class="section-image" />
        </div>
        <div class="section__description col-12 col-md-6">
          <h2 class="bold" [innerHTML]="'PERSONAL_LIABILITY_TITLE' | translate"></h2>
          <p>
            {{ "PERSONAL_LIABILITY_INSURANCE_DESCRIPTION" | translate }}
          </p>
          <div
            class="cross-sell__buttons d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-start justify-content-md-start mb-0">
            <button class="btn btn-primary" [routerLink]="'/wizard/insurance-type' | navigateWithLang">
              {{ "CALCULATE_PREMIUM" | translate }}
            </button>
            <button class="btn btn-text" [routerLink]="'/personal-liability' | navigateWithLang">
              {{ "MORE_INFO" | translate }}
            </button>
          </div>
        </div>
      </section>
    </div>
    <!-- List Author  -->
    <div *ngIf="author" class="author-footer">
      <img class="author-photo" [src]="authorProfilePhotoUrl" alt="">
      <address class="author-name">
        {{ author?.fields?.name }}
      </address>
      {{ author?.fields?.authorInfo }}
    </div>
  </article>
  <div *ngIf="isLoading" class="blog-entry__loading">
    <ngx-spinner [showSpinner]="true" type="ball-scale-ripple-multiple" [fullScreen]="false"
      [bdColor]="'rgba(0,0,0,0.1)'"></ngx-spinner>
  </div>
</main>
