import {
  Component,
  Injectable,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { ConfigurationService } from "src/app/core/configuration.service";
import { PolicyService } from "src/app/core/policy.service";

@Component({
  selector: "app-download",
  templateUrl: "./download.component.html",
  styleUrls: ["./download.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
@Injectable({
  providedIn: "root",
})
export class DownloadComponent implements OnInit {
  downloadId: number;

  constructor(
    private configurationService: ConfigurationService,
    private policyService: PolicyService
  ) {}

  ngOnInit(): void {}

  isDownloading(id) {
    return this.downloadId === id;
  }

  async downloadFactsheetPDF() {
    const language = this.configurationService.getLanguage();
    this.downloadId = 1;

    await this.policyService.GetFactsheetDocument(language);
    this.downloadId = undefined;
  }

  async downloadAVB() {
    const language = this.configurationService.getLanguage();
    this.downloadId = 3;

    await this.policyService.DownloadAvbProduct();
    this.downloadId = undefined;
  }

  async downloadVAG45() {
    const language = this.configurationService.getLanguage();
    this.downloadId = 2;

    await this.policyService.GetVAG45Document(language);
    this.downloadId = undefined;
  }
}
