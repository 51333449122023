import { DatePipe } from "@angular/common";
import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatDateTimestamp",
  pure: false,
})
@Injectable()
export class FormatDateTimestamp implements PipeTransform {
  constructor() {}

  transform(timestamp: number) {
    const datePipe = new DatePipe("en-US");
    return datePipe.transform(timestamp);
  }
}
