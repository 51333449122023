<main class="d-flex flex-column">
  <section class="section section--content row align-items-start">
    <div class="section__description col-12 col-md-6">
      <h1 class="manual-word-break">{{ heroTitle }}</h1>
      <h5 class="mb-4">
        {{ heroText }}
      </h5>

      <div class="wrapper wrapper--button-insurance mt-0">
        <button
          class="btn btn-primary"
          [routerLink]="'/wizard/insurance-type' | navigateWithLang"
        >
          {{ "CALCULATE_PREMIUM" | translate }}
        </button>
      </div>
    </div>
    <div class="col-12 col-md-6 section-hero__image">
      <img src="assets/images/migros-woman.svg" [src]="heroImagePath" class="w-100 w-sm-auto"
        [alt]="'GIRL_WITH_SKATEBOARD_AND_SHIELD_ALT' | translate"
        [title]="'GIRL_WITH_SKATEBOARD_AND_SHIELD_TITLE' | translate" />
    </div>
  </section>
  
  <section class="section section--contact text-center">
    <p class="text-center">
      {{ "ASK_CONTACT_US" | translate }}
    </p>
    <a href="tel:{{ phoneNumberPf }}" rel="noreferrer">
      <h3 class="phone-number bold text-center">{{ phoneNumberPf }}</h3>
    </a>
  </section>

  <section class="
      section section--why-migros
      text-left text-sm-center
      align-items-center
      d-flex
      flex-column
      position-relative
    ">
    <img src="assets/images/home-bg.png" alt="background" class="frame-image position-absolute" />
    <h2 class="align-self-start align-self-sm-center">
      {{ "WHY_INSURE_WITH_MIGROS_HOME" | translate }}
    </h2>
    <h5 class="align-self-start align-self-sm-center">
      {{ "TRUST_CONNECTS" | translate }}
    </h5>
    <div class="wrapper wrapper--why d-flex text-center flex-column flex-lg-row">
      <div class="item__description">
        <svg-icon src="assets/images/premium-icon.svg" class="icon" [svgAriaLabel]="'PREMIUM_ICON_ALT' | translate">
        </svg-icon>
        <h4 class="bold">{{ "PREMIUM_PROTECTION" | translate }}</h4>
        <p class="text-center">{{ "PREMIUM_PROTECTION_DESCRIPTION" | translate }}</p>
      </div>
      <div class="item__description">
        <svg-icon src="assets/images/partner-icon.svg" class="icon" [svgAriaLabel]="'PARTNER_ICON_ALT' | translate">
        </svg-icon>
        <h4 class="bold">{{ "STRONG_PARTNER" | translate }}</h4>
        <p class="text-center">{{ "STRONG_PARTNER_DESCRIPTION" | translate }}</p>
      </div>
      <div class="item__description">
        <svg-icon src="assets/images/clock-icon.svg" class="icon" [svgAriaLabel]="'CLOCK_ICON_ALT' | translate">
        </svg-icon>
        <h4 class="bold">{{ "ONLINE_CONTRACT" | translate }}</h4>
        <p class="text-center">{{ "ONLINE_CONTRACT_DESCRIPTION" | translate }}</p>
      </div>
      <div class="item__description">
        <svg-icon src="assets/images/cards-icon.svg" class="icon" [svgAriaLabel]="'CARDS_ICON_ALT' | translate">
        </svg-icon>
        <h4 class="bold">{{ "CUMULUS_POINTS" | translate }}</h4>
        <p class="text-center">{{ "CUMULUS_POINTS_DESCRIPTION" | translate }}</p>
      </div>
    </div>

    <div class="wrapper wrapper--button-insurance mt-0">
      <button
        class="btn btn-primary"
        [routerLink]="'/wizard/insurance-type' | navigateWithLang"
      >
        {{ "CALCULATE_PREMIUM" | translate }}
      </button>
    </div>
  </section>
  <h2 class="title">{{ "RELIABLE_PROTECTION" | translate }}</h2>
  <section class="
      section section--household-insurance
      flex-column-reverse flex-md-row
      row
      ml-0
      align-items-start
    ">
    <div class="section__description col-12 col-md-6">
      <h2 class="bold">{{ "HOUSEHOLD_INSURANCE" | translate }}</h2>
      <p>
        {{ "HOUSEHOLD_INSURANCE_DESCRIPTION" | translate }}
      </p>
      <div class="
          wrapper wrapper--button-insurance
          d-flex
          flex-column flex-sm-row
          align-items-start align-items-sm-center
          justify-content-md-start
        ">
        <button class="btn btn-primary" [routerLink]="'/wizard/insurance-type' | navigateWithLang">
          {{ "CALCULATE_PREMIUM" | translate }}
        </button>
        <button class="btn btn-outline" [routerLink]="'/household-insurance' | navigateWithLang">
          {{ "MORE_INFO" | translate }}
        </button>
      </div>
    </div>
    <div class="
        col-12 col-md-6
        mt-md-0
        d-flex
        justify-content-xl-center justify-content-sm-start
      ">
      <img src="assets/images/hausrat-frame.svg" [src]="
          'assets/images/' + ('GUY_WITH_SMARTPHONE_SRC' | translate) + '.svg'
        " class="section-image" [alt]="'GUY_WITH_SMARTPHONE_ALT' | translate"
        [title]="'GUY_WITH_SMARTPHONE_TITLE' | translate" />
    </div>
  </section>
  <section class="
      section section--personal-insurance
      row
      flex-column flex-md-row
      align-items-start align-items-lg-center
    ">
    <div class="col-12 col-md-6 d-flex justify-content-sm-start mt-md-5 mt-lg-0">
      <img src="assets/images/privat-frame.svg"
        [src]="'assets/images/' + ('WOMAN_WITH_BICYCLE_SRC' | translate)+ '.svg'"
        [alt]="'WOMAN_WITH_BICYCLE_ALT' | translate" [title]="'WOMAN_WITH_BICYCLE_TITLE' | translate"
        class="section-image" />
    </div>
    <div class="section__description col-12 col-md-6">
      <h2 class="bold" [innerHTML]="'PERSONAL_LIABILITY_TITLE' | translate"></h2>
      <p>
        {{ "PERSONAL_LIABILITY_INSURANCE_DESCRIPTION" | translate }}
      </p>
      <div class="
          wrapper wrapper--button-insurance
          d-flex
          flex-column flex-sm-row
          align-items-start align-items-sm-center
          justify-content-sm-start justify-content-md-start
          mb-0
        ">
        <button class="btn btn-primary" [routerLink]="'/wizard/insurance-type' | navigateWithLang">
          {{ "CALCULATE_PREMIUM" | translate }}
        </button>
        <button class="btn btn-outline" [routerLink]="'/personal-liability' | navigateWithLang">
          {{ "MORE_INFO" | translate }}
        </button>
      </div>
    </div>
  </section>
  <section class="section section--slider">
    <app-slider></app-slider>
  </section>
</main>
