<svg-icon
  src="assets/images/404-frame.svg"
  class="section-image position-absolute"
></svg-icon>
<div
  class="wrapper position-relative d-flex flex-column align-items-stretch align-items-md-center justify-content-center text-center text-align-md-center"
>
  <h1>{{ "UNDER_CONSTRUCTION_TITLE" | translate }}</h1>
  <button class="btn btn-primary" [routerLink]="'/' | navigateWithLang">
    {{ "BACK_TO_MAIN_PAGE" | translate }}
  </button>
</div>
