import { Meta } from "@angular/platform-browser";
import { Component, OnInit } from "@angular/core";
import { PageTitleService } from "src/app/services/page-title.service";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit {
  constructor(
    private pageTitleService: PageTitleService,
    private metaService: Meta
  ) {
    this.changeTitle();
  }

  ngOnInit(): void {}

  changeTitle() {
    this.pageTitleService.setPageTitle("404");
    this.metaService.removeTag(`name=description`);
  }
}
