<main class="d-flex flex-column">
  <section class="section section--content row align-items-start">
    <div class="section__description col-12 col-md-6">
      <h1 class="manual-word-break">{{ "HERO_TITLE" | translate }}</h1>
      <p class="mb-4">
        {{ "HERO_TEXT" | translate }}
      </p>

      <div class="wrapper wrapper--button-insurance mt-0">
        <button
          class="btn btn-primary"
          [routerLink]="'/wizard/insurance-type' | navigateWithLang"
        >
          {{ "CALCULATE_PREMIUM" | translate }}
        </button>
      </div>
      <div class="section--wizard-buttons d-none d-lg-block">
        <h2>{{ "WHICH_INSURANCE_DO_YOU_WANT" | translate }}</h2>
        <div class="wizard-buttons-container">
          <a
            class="wizard-button"
            [routerLink]="'/wizard/insurance-type' | navigateWithLang"
            [queryParams]="{insuranceType: 'householdandliability'}"
          >
            <svg-icon
              class="wizard-button__icon"
              src="assets/images/householdandliability-icon.svg"
            ></svg-icon>
            <div class="wizard-button__title">
              {{ "HOUSEHOLD_GOODS_AND_LIABILITY" | translate }}
            </div>
            <div class="wizard-button__subtitle">
              {{ "HOUSEHOLD_GOODS_AND_LIABILITY_SUBTITLE" | translate }}
            </div>
            <span class="price-sticker">
              {{ "PRICE_STICKER_HOUSEHOLD_GOODS_AND_LIABILITY" | translate }}
            </span>
          </a>
          <a
            class="wizard-button"
            [routerLink]="'/wizard/insurance-type' | navigateWithLang"
            [queryParams]="{insuranceType: 'householdonly'}"
            ><svg-icon
              class="wizard-button__icon"
              src="assets/images/home-icon.svg"
            ></svg-icon>
            <div class="wizard-button__title">
              {{ "HOUSEHOLD_ITEMS" | translate }}
            </div>
            <div class="wizard-button__subtitle">
              {{ "HOUSEHOLD_ITEMS_SUBTITLE" | translate }}
            </div>
            <span class="price-sticker">
              {{ "PRICE_STICKER_HOUSEHOLD_ITEMS" | translate }}
            </span>
            </a
          >
          <a
            class="wizard-button"
            [routerLink]="'/wizard/insurance-type' | navigateWithLang"
            [queryParams]="{insuranceType: 'liabilityonly'}"
            ><svg-icon
              class="wizard-button__icon"
              src="assets/images/shield-icon.svg"
            ></svg-icon>
            <div class="wizard-button__title">
              {{ "LIABILITY" | translate }}
            </div>
            <div class="wizard-button__subtitle">
              {{ "LIABILITY_SUBTITLE" | translate }}
            </div>
            <span class="price-sticker">
              {{ "PRICE_STICKER_LIABILITY" | translate }}
            </span>
            </a
          >
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="section-hero-sticker">
        <div
          *ngIf="('STICKER_COMPETITION_STATUS' | translate) === 'active'"
          class="section-hero-sticker__item"
        >
          <app-sticker-competition></app-sticker-competition>
        </div>
        <img
          src="assets/images/migros-woman.svg"
          [src]="
            'assets/images/' +
            ('GIRL_WITH_SKATEBOARD_AND_SHIELD_SRC' | translate) +
            '.svg'
          "
          class="section-image h-auto w-100 w-sm-auto"
          [alt]="'GIRL_WITH_SKATEBOARD_AND_SHIELD_ALT' | translate"
          [title]="'GIRL_WITH_SKATEBOARD_AND_SHIELD_TITLE' | translate"
          width="600"
          height="600"
        />
      </div>
    </div>
    
  <div class="section--wizard-buttons d-lg-none">
    <h2>{{ "WHICH_INSURANCE_DO_YOU_WANT" | translate }}</h2>
    <div class="wizard-buttons-container">
      <a
        class="wizard-button"
        [routerLink]="'/wizard/insurance-type' | navigateWithLang"
        [queryParams]="{insuranceType: 'householdandliability'}"
      >
        <svg-icon
          class="wizard-button__icon"
          src="assets/images/householdandliability-icon.svg"
        ></svg-icon>
        <div class="wizard-button__title">
          {{ "HOUSEHOLD_GOODS_AND_LIABILITY" | translate }}
        </div>
        <div class="wizard-button__subtitle">
          {{ "HOUSEHOLD_GOODS_AND_LIABILITY_SUBTITLE" | translate }}
        </div>
        <span class="price-sticker">
          {{ "PRICE_STICKER_HOUSEHOLD_GOODS_AND_LIABILITY" | translate }}
        </span>
      </a>
      <a
        class="wizard-button"
        [routerLink]="'/wizard/insurance-type' | navigateWithLang"
        [queryParams]="{insuranceType: 'householdonly'}"
        ><svg-icon
          class="wizard-button__icon"
          src="assets/images/home-icon.svg"
        ></svg-icon>
        <div class="wizard-button__title">
          {{ "HOUSEHOLD_ITEMS" | translate }}
        </div>
        <div class="wizard-button__subtitle">
          {{ "HOUSEHOLD_ITEMS_SUBTITLE" | translate }}
        </div>
        <span class="price-sticker">
          {{ "PRICE_STICKER_HOUSEHOLD_ITEMS" | translate }}
        </span></a
      >
      <a
        class="wizard-button"
        [routerLink]="'/wizard/insurance-type' | navigateWithLang"
        [queryParams]="{insuranceType: 'liabilityonly'}"
        ><svg-icon
          class="wizard-button__icon"
          src="assets/images/shield-icon.svg"
        ></svg-icon>
        <div class="wizard-button__title">
          {{ "LIABILITY" | translate }}
        </div>
        <div class="wizard-button__subtitle">
          {{ "LIABILITY_SUBTITLE" | translate }}
        </div>
        <span class="price-sticker">
          {{ "PRICE_STICKER_HOUSEHOLD_ITEMS" | translate }}
        </span>
        </a
      >
    </div>
  </div>
  </section>
  

  <section class="section section--contact text-center">
    <p class="text-center">
      {{ "ASK_CONTACT_US" | translate }}
    </p>
    <a href="tel:{{ phoneNumberPf }}" rel="noreferrer">
      <div class="phone-number bold">{{ phoneNumberPf }}</div>
    </a>
  </section>

  <section
    class="section section--why-migros text-left text-sm-center align-items-center d-flex flex-column position-relative"
  >
    <img
      src="assets/images/home-bg.png"
      alt="background"
      class="frame-image position-absolute"
    />
    <h2 class="align-self-start align-self-sm-center bold">
      {{ "WHY_INSURE_WITH_MIGROS_HOME" | translate }}
    </h2>
    <p class="align-self-start align-self-sm-center why-migros__subtitle">
      {{ "TRUST_CONNECTS" | translate }}
    </p>

    <div
      class="wrapper wrapper--why d-flex text-center flex-column flex-lg-row"
    >
      <div class="item__description">
        <svg-icon
          src="assets/images/premium-icon.svg"
          class="icon"
          [svgAriaLabel]="'PREMIUM_ICON_ALT' | translate"
        >
        </svg-icon>
        <h3 class="bold">{{ "PREMIUM_PROTECTION" | translate }}</h3>
        <p class="text-center">
          {{ "PREMIUM_PROTECTION_DESCRIPTION" | translate }}
        </p>
      </div>
      <div class="item__description">
        <svg-icon
          src="assets/images/partner-icon.svg"
          class="icon"
          [svgAriaLabel]="'PARTNER_ICON_ALT' | translate"
        >
        </svg-icon>
        <h3 class="bold">{{ "STRONG_PARTNER" | translate }}</h3>
        <p class="text-center">
          {{ "STRONG_PARTNER_DESCRIPTION" | translate }}
        </p>
      </div>
      <div class="item__description">
        <svg-icon
          src="assets/images/clock-icon.svg"
          class="icon"
          [svgAriaLabel]="'CLOCK_ICON_ALT' | translate"
        >
        </svg-icon>
        <h3 class="bold">{{ "ONLINE_CONTRACT" | translate }}</h3>
        <p class="text-center">
          {{ "ONLINE_CONTRACT_DESCRIPTION" | translate }}
        </p>
      </div>
      <div class="item__description">
        <svg-icon
          src="assets/images/cards-icon.svg"
          class="icon"
          [svgAriaLabel]="'CARDS_ICON_ALT' | translate"
        >
        </svg-icon>
        <h3 class="bold">{{ "CUMULUS_POINTS" | translate }}</h3>
        <p class="text-center">
          {{ "CUMULUS_POINTS_DESCRIPTION" | translate }}
        </p>
      </div>
    </div>

    <div class="wrapper wrapper--button-insurance mt-0">
      <button
        class="btn btn-primary"
        [routerLink]="'/wizard/insurance-type' | navigateWithLang"
      >
        {{ "CALCULATE_PREMIUM" | translate }}
      </button>
    </div>
  </section>
  <h2 class="title bold">{{ "RELIABLE_PROTECTION" | translate }}</h2>
  <section
    class="section section--household-insurance flex-column-reverse flex-md-row row ml-0 align-items-start"
  >
    <div class="section__description col-12 col-md-6">
      <h3 class="bold mb-3">{{ "HOUSEHOLD_INSURANCE" | translate }}</h3>
      <p>
        {{ "HOUSEHOLD_INSURANCE_DESCRIPTION" | translate }}
      </p>
      <div
        class="wrapper wrapper--button-insurance d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-md-start"
      >
        <button
          class="btn btn-primary"
          [routerLink]="'/wizard/insurance-type' | navigateWithLang"
        >
          {{ "CALCULATE_PREMIUM" | translate }}
        </button>
        <button
          class="btn btn-outline"
          [routerLink]="'/household-insurance' | navigateWithLang"
        >
          {{ "MORE_INFO" | translate }}
        </button>
      </div>
    </div>
    <div
      class="col-12 col-md-6 mt-md-0 d-flex justify-content-xl-center justify-content-sm-start"
    >
      <img
        src="assets/images/hausrat-frame.svg"
        [src]="
          'assets/images/' + ('GUY_WITH_SMARTPHONE_SRC' | translate) + '.svg'
        "
        class="section-image"
        [alt]="'GUY_WITH_SMARTPHONE_ALT' | translate"
        [title]="'GUY_WITH_SMARTPHONE_TITLE' | translate"
      />
    </div>
  </section>
  <section
    class="section section--personal-insurance row flex-column flex-md-row align-items-start align-items-lg-center"
  >
    <div
      class="col-12 col-md-6 d-flex justify-content-sm-start mt-md-5 mt-lg-0"
    >
      <img
        src="assets/images/privat-frame.svg"
        [src]="
          'assets/images/' + ('WOMAN_WITH_BICYCLE_SRC' | translate) + '.svg'
        "
        [alt]="'WOMAN_WITH_BICYCLE_ALT' | translate"
        [title]="'WOMAN_WITH_BICYCLE_TITLE' | translate"
        class="section-image"
      />
    </div>
    <div class="section__description col-12 col-md-6">
      <h3
        class="bold mb-3"
        [innerHTML]="'PERSONAL_LIABILITY_TITLE' | translate"
      ></h3>
      <p>
        {{ "PERSONAL_LIABILITY_INSURANCE_DESCRIPTION" | translate }}
      </p>
      <div
        class="wrapper wrapper--button-insurance d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-start justify-content-md-start mb-0"
      >
        <button
          class="btn btn-primary"
          [routerLink]="'/wizard/insurance-type' | navigateWithLang"
        >
          {{ "CALCULATE_PREMIUM" | translate }}
        </button>
        <button
          class="btn btn-outline"
          [routerLink]="'/personal-liability' | navigateWithLang"
        >
          {{ "MORE_INFO" | translate }}
        </button>
      </div>
    </div>
  </section>
  <section class="section section--slider">
    <app-slider></app-slider>
  </section>
</main>
