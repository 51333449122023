import {
  BrowserModule,
  BrowserTransferStateModule,
  TransferState,
} from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import {
  NgModule,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";
import {
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpClientModule,
} from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { registerLocaleData, CurrencyPipe } from "@angular/common";
import {
  LocationStrategy,
  Location,
  PathLocationStrategy,
} from "@angular/common";
import localeEn from "@angular/common/locales/en";
import localeCh from "@angular/common/locales/de-CH";
import localeIt from "@angular/common/locales/de-IT";
import localeFr from "@angular/common/locales/fr";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ShareModule } from "ngx-sharebuttons";

// #Core
import { AppComponent } from "./app.component";
import { ToastrModule } from "ngx-toastr";
import { NotificationsService } from "./core/notifications.service";
import { RequestService } from "./core/request.service";
import { ContentfulService } from "./core/contentful.service";
import { ConfigurationService } from "./core/configuration.service";
import { StorageService } from "./core/storage.service";
import { XsrfTokenInterceptor } from "./core/xsrfTokenInterceptor";
import { AppStorage } from "./core/appstorage.service";
import { TokenStorage } from "src/app/core/tokenstorage.service";
import { SocotraConfigService } from "./core/socotraConfig.service";

// #MIGROS

// ##Services
import { WizardService } from "./services/wizard.service";
import { AuthService } from "./services/auth.service";
import { PolicyService } from "./core/policy.service";
import { MessagesService } from "./core/messages.service";
import { LanguageGuard } from "./services/languageGuard.service";
import { FacebookConversionService } from "./services/facebookConversion.service";
import { IPService } from "./services/ip.service";

// ##Packages
import { AngularSvgIconModule, SvgLoader } from "angular-svg-icon";
import { SwiperModule } from "swiper/angular";

// ##Layouts
import { LandingLayoutComponent } from "./layouts/landing-layout/landing-layout.component";
import { DefaultLayoutComponent } from "./layouts/default-layout/default-layout.component";
import { WizardLayoutComponent } from "./layouts/wizard-layout/wizard-layout.component";
import { WizardLayoutWithoutAnimationComponent } from "./layouts/wizard-layout-without-animation/wizard-layout-without-animation.component";

import { AlternativeLandingLayoutComponent } from "./layouts/alternative-landing-layout/alternative-landing-layout.component";

// ##Landing
import { HomeComponent } from "./pages/home/home.component";
import { AlternativeHomeComponent } from "./pages/alternative-home/alternative-home.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { ImprintComponent } from "./pages/imprint/imprint.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SliderComponent } from "./components/slider/slider.component";
import { StickerCompetitionComponent } from "./components/sticker-competition/sticker-competition.component";
import { DownloadComponent } from "./components/download/download.component";
import { PersonalLiabilityComponent } from "./pages/personal-liability/personal-liability.component";
import { HouseholdInsuranceComponent } from "./pages/household-insurance/household-insurance.component";
import { AboutComponent } from "./pages/about/about.component";
import { SupportComponent } from "./pages/support/support.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { ReportDamageComponent } from "./pages/report-damage/report-damage.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { BlogEntryComponent } from "./pages/blog-entry/blog-entry.component";
import { DynamicLandingComponent } from "./pages/dynamic-landing-page/dynamic-landing-page.component";

// ##Globals
import { SessionExpireWarningModalComponent } from "./components/session-expire-warning-modal/session-expire-warning-modal.component";
import { FileDownloadModalComponent } from "./components/file-download-modal/file-download-modal.component";
import { DeeplinkCallbackComponent } from "./pages/deeplink-callback/deeplink-callback.component";
import { DocumentviewComponent } from "./pages/documentview/documentview.component";
import { SharedModule } from "./shared/shared.module";
import { matcherFor } from "./localized-routes";
import { RouteResolver } from "./services/routeResolver.service";
import { AlternativeHeaderComponent } from "./components/alternative-header/alternative-header.component";
import { SvgLoaderBrowserImplement } from "./shared/svg-loader-browser-implement";
import { ConfigurationHelperBrowser } from "./shared/configuration-helper-browser";
import { ConfigurationHelper } from "./shared/configuration-helper-abstract";
import { PageTitleService } from "./services/page-title.service";
import { UnderConstructionComponent } from "./pages/under-construction/under-construction";
import { DynamicLandingResolver } from "./services/dynamicLandingResolver.service";
import { BlogEntryResolver } from "./services/blogEntryResolver.service";
import { UnsubscribeComponent } from "./pages/unsubscribe/unsubscribe.component";
import { UnsubscribeRedirectHandler } from "./services/unsubscribeRedirectHandler";
import { LandingGuard } from "./services/landingResolver.service";

export const load =
  (
    configurationService: ConfigurationService,
    contentfulService: ContentfulService
  ) =>
  async (): Promise<boolean> => {
    // Get Config
    const staticConfig = await configurationService.getStaticConfig();
    const config = await configurationService.getConfig(
      staticConfig.gatewayApi
    );

    // Save Config
    configurationService.saveConfig({
      ...staticConfig,
      ...config,
    });

    configurationService.resolveLanguage();
    await contentfulService.LoadAssets(configurationService.getLanguage());
    switch (configurationService.config.defaultCultureInfoId) {
      case "de-CH": {
        registerLocaleData(localeCh);
        break;
      }
      case "en": {
        registerLocaleData(localeEn);
        break;
      }
      case "de-IT": {
        registerLocaleData(localeIt);
        break;
      }
      case "fr": {
        registerLocaleData(localeFr);
        break;
      }
      default: {
        registerLocaleData(localeEn);
        break;
      }
    }

    return true;
  };

export function svgLoaderFactory(
  http: HttpClient,
  transferState: TransferState
) {
  return new SvgLoaderBrowserImplement(http, transferState);
}

@NgModule({
  declarations: [
    AppComponent,
    LandingLayoutComponent,
    WizardLayoutWithoutAnimationComponent,
    AlternativeLandingLayoutComponent,
    AlternativeHomeComponent,
    AlternativeHeaderComponent,
    BlogComponent,
    BlogEntryComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    UnderConstructionComponent,
    ImprintComponent,
    SliderComponent,
    HouseholdInsuranceComponent,
    DownloadComponent,
    PersonalLiabilityComponent,
    AboutComponent,
    SupportComponent,
    ContactComponent,
    ReportDamageComponent,
    SessionExpireWarningModalComponent,
    FileDownloadModalComponent,
    DefaultLayoutComponent,
    DocumentviewComponent,
    DynamicLandingComponent,
    StickerCompetitionComponent,
    UnsubscribeComponent,
  ],
  imports: [
    SharedModule,
    HttpClientModule,
    SwiperModule,
    PerfectScrollbarModule,
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    BrowserAnimationsModule,
    ShareModule,
    BrowserTransferStateModule,
    AngularSvgIconModule.forRoot({
      loader: {
        provide: SvgLoader,
        useFactory: svgLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot(
      [
        {
          path: "home/:lang/unsubscribe/:policyPublicId",
          canActivate: [UnsubscribeRedirectHandler],
          children: [],
        },
        {
          path: ":lang",
          canActivate: [LanguageGuard],
          children: [
            {
              matcher: matcherFor("wizard"),
              loadChildren: () =>
                import("./wizard/wizard.module").then((m) => m.WizardModule),
            },
            {
              // Landing Pages
              path: "",
              canActivate: [LandingGuard],
              component: LandingLayoutComponent,
              children: [
                {
                  path: "",
                  component: HomeComponent,
                  resolve: {
                    title: RouteResolver,
                  },
                },
                {
                  matcher: matcherFor("household-insurance"),
                  component: HouseholdInsuranceComponent,
                  resolve: {
                    title: RouteResolver,
                  },
                },
                {
                  matcher: matcherFor("personal-liability"),
                  component: PersonalLiabilityComponent,
                  resolve: {
                    title: RouteResolver,
                  },
                },
                {
                  matcher: matcherFor("imprint"),
                  component: ImprintComponent,
                  resolve: {
                    title: RouteResolver,
                  },
                },
                {
                  matcher: matcherFor("about"),
                  component: AboutComponent,
                  resolve: {
                    title: RouteResolver,
                  },
                },
                {
                  matcher: matcherFor("blog"),
                  component: BlogComponent,
                  resolve: {
                    title: RouteResolver,
                  },
                },
                {
                  path: "blog/:slug",
                  component: BlogEntryComponent,
                  resolve: {
                    title: RouteResolver,
                    contentfulEntry: BlogEntryResolver,
                  },
                },
                {
                  matcher: matcherFor("support"),
                  component: SupportComponent,
                  resolve: {
                    title: RouteResolver,
                  },
                },
                {
                  matcher: matcherFor("contact"),
                  component: ContactComponent,
                  resolve: {
                    title: RouteResolver,
                  },
                },
                {
                  path: "404",
                  component: NotFoundComponent,
                  resolve: {
                    title: RouteResolver,
                  },
                },
                {
                  matcher: matcherFor("report-damage-landing"),
                  component: ReportDamageComponent,
                  resolve: {
                    title: RouteResolver,
                  },
                },
              ],
            },
            {
              path: "",
              component: AlternativeLandingLayoutComponent,
              children: [
                {
                  path: "familie",
                  component: AlternativeHomeComponent,
                },
                {
                  path: "jung",
                  component: AlternativeHomeComponent,
                },
                {
                  path: "studenten",
                  component: AlternativeHomeComponent,
                },
                {
                  path: "optimierer",
                  component: AlternativeHomeComponent,
                },
              ],
            },
            {
              path: "",
              component: DefaultLayoutComponent,
              children: [
                {
                  path: "documentview/:documentId",
                  component: DocumentviewComponent,
                },
                {
                  path: "unsubscribe/:policyPublicId",
                  component: UnsubscribeComponent,
                },
              ],
            },
            {
              path: "cockpit",
              loadChildren: () =>
                import("./cockpit/cockpit.module").then((m) => m.CockpitModule),
            },
            {
              //Deeplink
              path: "offer/:policyPublicId",
              component: DeeplinkCallbackComponent,
            },
            {
              path: "",
              component: WizardLayoutWithoutAnimationComponent,
              children: [
                {
                  path: ":slug",
                  component: DynamicLandingComponent,
                  resolve: {
                    title: RouteResolver,
                    contentfulEntry: DynamicLandingResolver,
                  },
                },
              ],
            },
            {
              path: "**",
              canActivate: [LanguageGuard],
              component: NotFoundComponent,
            },
          ],
        },
        {
          path: "**",
          canActivate: [LanguageGuard],
          component: NotFoundComponent,
        },
      ],
      {
        relativeLinkResolution: "legacy",
        scrollPositionRestoration: "enabled",
        initialNavigation: "disabled",
      }
    ),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: XsrfTokenInterceptor, multi: true },
    { provide: ConfigurationHelper, useClass: ConfigurationHelperBrowser },
    ConfigurationService,
    ContentfulService,
    {
      provide: APP_INITIALIZER,
      useFactory: load,
      deps: [
        ConfigurationService,
        ContentfulService,
        StorageService,
        HttpClient,
      ],
      multi: true,
    },
    NotificationsService,
    RequestService,
    ContentfulService,
    AppStorage,
    Location,
    TokenStorage,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    AuthService,
    FacebookConversionService,
    IPService,
    WizardService,
    SocotraConfigService,
    PolicyService,
    MessagesService,
    CurrencyPipe,
    TranslateModule,
    PageTitleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
