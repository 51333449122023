<div class="landing__container">
  <div class="landing-layout__nav-slot">
    <app-alternative-header></app-alternative-header>
  </div>
  <div class="landing-layout__content-slot">
    <router-outlet></router-outlet>
  </div>
  <div class="landing-layout__footer-slot">
    <app-footer></app-footer>
  </div>
</div>
<div class="cookie__wrapper fixed-bottom vw-100" [ngClass]="{ 'd-none': isCookieAccepted }">
  <div class="landing__container">
    <div class="
        cookie__container
        d-flex
        flex-column flex-md-row
        align-item-start align-items-md-center
        justify-content-stretch
      ">
      <p (click)="handleCookieTextClicked($event)" class="container__description"
        [innerHTML]="'ACCEPT_COOKIE_TEXT' | translate"></p>
      <button class="btn btn-primary" (click)="acceptCookie()">
        {{ "CLOSE_NOTICE" | translate }}
      </button>
    </div>
  </div>
</div>