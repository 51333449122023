import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import {
  makeStateKey,
  StateKey,
  TransferState,
} from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  public map: Map<string, string> = new Map<any, string>();

  constructor(
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const transferredValues = JSON.parse(transferState.toJson());
      Object.entries<string>(transferredValues).forEach(([key, value]) => {
        if (key.includes("storageService:")) {
          this.map.set(key.replace("storageService:", ""), value);
        }
      });
    }
  }

  public setItem(key: string, value: string | object): void {
    const strValue = typeof value === "object" ? JSON.stringify(value) : value;

    this.map.set(key, strValue);
    // Here we save the translations in the transfer-state
    const STATE_KEY: StateKey<string> = makeStateKey<string>(
      `storageService:${key}`
    );
    this.transferState.set(STATE_KEY, strValue);
  }

  public getItem(key: string): string | null {
    const value = this.map.get(key) || "";

    try {
      const parsed = JSON.parse(value);

      if (typeof parsed === "object") {
        return parsed;
      } else {
        return value;
      }
    } catch (error) {
      return value;
    }
  }
}
