import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { localizedRoutes } from "src/app/localized-routes";
import { ConfigurationService } from "../../core/configuration.service";

@Pipe({
  name: "navigateWithLang",
  pure: false,
})
@Injectable()
export class NavigateWithLang implements PipeTransform {
  constructor(private configurationService: ConfigurationService) {}

  transform(path: string) {
    if (path === undefined) return undefined;

    const currentLanguage = this.configurationService.getLanguage();

    let pathSections = path.split("/");
    pathSections = pathSections.map(
      (section) => localizedRoutes[section]?.langs[currentLanguage] || section
    );

    const newPath = pathSections.join("/");

    return `/${currentLanguage}${newPath}`;
  }
}
