import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "moneyFormatter",
})
export class MoneyFormatterPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) return "";

    const formatter = new Intl.NumberFormat("de-CH", {
      style: "currency",
      currency: "CHF",
    });
    let val = formatter.format(value).toString().replace("CHF", "").trim();

    let myNumber = val.split(".");
    if (myNumber[1] === "00") {
      return myNumber[0] + ".-";
    } else {
      return myNumber[0] + "." + myNumber[1].padEnd(2, "0");
    }
  }
}
