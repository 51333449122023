import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { FileDownloadModalComponent } from "src/app/components/file-download-modal/file-download-modal.component";
import { DocumentViewService } from "../../services/documentview.service";

@Component({
  templateUrl: "./documentview.component.html",
  styleUrls: ["./documentview.component.scss"],
  providers: [DocumentViewService],
})
export class DocumentviewComponent implements OnInit {
  message: string = "";
  id: string;
  data: any;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private documentViewService: DocumentViewService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.id = this.route.snapshot.paramMap.get("documentId");
    this.message = this.translateService.instant("YOUR_FILE_IS_BEING_PREPARED");
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.getPDFDocument();
    }
  }

  async downloadPDFDocument() {
    const blob = new Blob([this.data], {
      type: "application/pdf",
    });
    const url = URL.createObjectURL(blob);

    let read = new FileReader();
    let fileName = "Document";
    read.readAsText(blob, "ISO-8859-4");
    read.onload = () => {
      let start = read.result.toString().indexOf("/Title(");
      let substring = read.result.toString().substring(start);
      if (start != -1) {
        fileName = substring.substring(7, substring.indexOf(")"));
      }

      const sanitizedURL = this.sanitizer.bypassSecurityTrustUrl(url);

      const fileDownloadModalRef = this.modalService.open(
        FileDownloadModalComponent,
        { centered: true }
      );
      fileDownloadModalRef.componentInstance.url = sanitizedURL as string;
      fileDownloadModalRef.componentInstance.filename = fileName;
      fileDownloadModalRef.componentInstance.callback = () => {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download =
          fileName.substring(fileName.length - 4) !== ".pdf"
            ? fileName.concat(".pdf")
            : fileName;
        a.click();
      };
    };
  }

  async getPDFDocument() {
    try {
      this.data = await this.documentViewService
        .GetUserSharedDocumentById(this.id)
        .toPromise();
      this.message = this.translateService.instant("YOUR_FILE_IS_READY");
      this.downloadPDFDocument();
    } catch {
      this.data = undefined;
      this.message = this.translateService.instant("SAS_URL_EXPIRED");
    }
  }
}
