<button [disabled]="isDisabled == true" [ngClass]="{
    'with-trailing-icon': trailingIcon,
    'background-gray': changeBackground,
    secondary: variant === 'secondary',
    quaternary: variant === 'quaternary',
    tertiary: variant === 'tertiary',
    small: size === 'small',
    fluid: fluid
  }" (click)="onClick($event)">
  <ng-content></ng-content>
  <div class="trailing-icon" *ngIf="trailingIcon">
    <svg-icon [src]="icons[trailingIcon]"></svg-icon>
  </div>
</button>
