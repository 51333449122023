import { Component, OnInit } from "@angular/core";
import { ConfigurationService } from "src/app/core/configuration.service";
import { AuthService } from "src/app/services/auth.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PolicyService } from "src/app/core/policy.service";
import { Router } from "@angular/router";
import { NavigateWithLang } from "src/app/shared/pipes/navigateWithLang.pipe";

@Component({
  selector: "app-report-damage",
  templateUrl: "./report-damage.component.html",
  styleUrls: ["./report-damage.component.scss"],
})
export class ReportDamageComponent implements OnInit {
  phoneNumberPf: string;
  hasPublicId;
  isCockpitActive: boolean = false;

  constructor(
    private configurationService: ConfigurationService,
    public authService: AuthService,
    public policyService: PolicyService,
    private modalService: NgbModal,
    private router: Router,
    private navigateWithLang: NavigateWithLang
  ) {
    this.phoneNumberPf = this.configurationService.config.phoneNumber;
  }

  ngOnInit(): void {
    this.checkIfCockpitActive();
  }

  async checkIfCockpitActive() {
    const hasPublicId = await this.policyService.readOrGetPolicyHolderPublicId();
    this.isCockpitActive = this.authService.isLoggedIn && !!hasPublicId;
  }

  goToReportDamage() {
    this.router.navigateByUrl(
      this.navigateWithLang.transform("/cockpit/report-damage")
    );
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  public onLogin() {
    this.authService.login();
  }
}
