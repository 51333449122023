import {
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from "oidc-client";
import { ConfigurationService } from "src/app/core/configuration.service";

import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { SessionExpireWarningModalComponent } from "../components/session-expire-warning-modal/session-expire-warning-modal.component";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { isPlatformBrowser } from "@angular/common";

export { User };

interface ISigninRedirectState {
  url?: string;
  redirectedMLoginAction?: "login" | "register" | "dashboard";
}
interface IGetUserManagerSettingsOptions {
  omitPrompt: boolean;
}

type IMLoginInlink =
  | "register_inlink"
  | "profile_inlink"
  | "profile_edit_inlink"
  | "address_edit_inlink"
  | "cumulus_edit_inlink"
  | "cumulus_register_inlink";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  userManager: UserManager;
  isLoggedIn: Boolean;
  public initialGetUserPromise: Promise<User>;

  private userSource: BehaviorSubject<User>;
  public user$: Observable<User>;
  private userSubSource: BehaviorSubject<string>;
  public userSub$: Observable<string>;

  private sessionExpireModalRefSource: BehaviorSubject<NgbModalRef>;
  public sessionExpireModalRef$: Observable<NgbModalRef>;

  constructor(
    private configurationService: ConfigurationService,
    private router: Router,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.configurationService.isConfigReady$.subscribe((val) =>
        this.configureOIDC()
      );
    }

    this.userSource = new BehaviorSubject<User>(undefined);
    this.user$ = this.userSource.asObservable();
    this.userSubSource = new BehaviorSubject<string>(undefined);
    this.userSub$ = this.userSubSource.asObservable();

    this.sessionExpireModalRefSource = new BehaviorSubject<NgbModalRef>(
      undefined
    );
    this.sessionExpireModalRef$ =
      this.sessionExpireModalRefSource.asObservable();
  }

  get user() {
    return this.userSource.getValue();
  }
  set user(user: Partial<User>) {
    // @ts-ignore
    this.userSource.next(user);
  }

  get userSub() {
    return this.userSubSource.getValue();
  }
  set userSub(userSub: string) {
    this.userSubSource.next(userSub);
  }

  public async configureOIDC() {
    const settings: UserManagerSettings = this.getUserManagerSettings();
    this.userManager = new UserManager(settings);

    this.userManager.events.addAccessTokenExpiring((e) => {
      this.openSessionExpireModal();
    });

    this.userManager.events.addAccessTokenExpired((e) => {
      if (this.sessionExpireModalRefSource.getValue()) {
        this.sessionExpireModalRefSource.getValue().dismiss();
        this.logout();
      }
    });

    this.initialGetUserPromise = this.getUser();
    const storedUser = await this.initialGetUserPromise;

    if (storedUser && !storedUser.expired) {
      if (storedUser) {
        const renewedUser = await this.renewToken();
        if (renewedUser) {
          await this.getUser();
        }
      }
    }

    return null;
  }

  private getUserManagerSettings(
    options?: IGetUserManagerSettingsOptions
  ): UserManagerSettings {
    const opts: IGetUserManagerSettingsOptions = {
      omitPrompt: false,
      ...options,
    };

    const mLoginConfig = this.configurationService.config.mLogin;

    return {
      authority: mLoginConfig.authority,
      client_id: mLoginConfig.client_id,
      redirect_uri: `${this.configurationService.config.mLogin.client_root}/mlogin/callback`,
      post_logout_redirect_uri: `${this.configurationService.config.mLogin.client_root}`,
      response_type: mLoginConfig.response_type,
      scope: mLoginConfig.scope,
      ...(opts.omitPrompt ? {} : { prompt: mLoginConfig.prompt }),
      client_secret: this.configurationService.config.mLogin.client_secret,
      extraQueryParams: {
        claims: JSON.stringify(mLoginConfig.claims),
      },
      accessTokenExpiringNotificationTime: 60,
      stateStore: new StateStore(
        "oidc.",
        this.configurationService.config.mLogin.client_root
      ),
    };
  }

  public async getUser(): Promise<User> {
    this.user = await this.userManager.getUser();

    if (this.user) {
      this.isLoggedIn = true;

      if (this.user.profile.sub !== this.userSubSource.getValue()) {
        this.userSub = this.user.profile.sub;
      }
    } else {
      this.isLoggedIn = false;
      this.userSub = undefined;
    }

    // @ts-ignore
    return this.user;
  }

  public async login(options?: ISigninRedirectState): Promise<void> {
    await this.saveSigninRedirectState({
      redirectedMLoginAction: "login",
      url: this.router.url,
      ...options,
    });

    window.localStorage.removeItem("withGuestForm");

    await this.userManager.signinRedirect();

    return;
  }

  public async register(options?: ISigninRedirectState) {
    await this.saveSigninRedirectState({
      redirectedMLoginAction: "register",
      url: this.router.url,
      ...options,
    });

    const registerLink = `${this.configurationService.config.mLogin.register_inlink.replace(
      "{{language}}",
      this.configurationService.getLanguage()
    )}?return_to=${
      this.configurationService.config.mLogin.client_root
    }/mlogin/callback`;
    window.location.href = registerLink;
  }

  async goToMLoginInlink(
    inlinkType: IMLoginInlink,
    options?: ISigninRedirectState
  ) {
    await this.saveSigninRedirectState({
      redirectedMLoginAction: "dashboard",
      url: this.router.url,
      ...options,
    });

    const returnToType =
      inlinkType === "cumulus_edit_inlink" ? "explicit_return_to" : "return_to";

    window.location.href = `${this.configurationService.config.mLogin[
      inlinkType
    ].replace(
      "{{language}}",
      this.configurationService.getLanguage()
    )}?${returnToType}=${
      this.configurationService.config.mLogin.client_root
    }/mlogin/callback`;
  }

  public async renewToken(): Promise<User | null> {
    const user = await this.getUser();

    if (user) {
      try {
        const signinPromise = this.userManager.signinSilent();
        await signinPromise;
        return user;
      } catch (error) {
        return null;
      }
    }
  }

  public async logout(
    path = `/${this.configurationService.getLanguage()}/`,
    logoutFromMlogin: boolean = false
  ): Promise<void> {
    const homePath = `/${this.configurationService.getLanguage()}/`;
    const rootDomain = this.configurationService.config.mLogin.client_root;
    const appDomain = `https://${AuthService.extractHostname(
      document.location.href
    )}`;
    let href = ``;

    if (path.includes("cockpit")) {
      href = `${rootDomain}${homePath}`;
    } else {
      href = `${appDomain}${path}`;
    }

    await this.userManager.removeUser();
    await synchronizeMLoginCrossDomain(
      this.configurationService.config.mLogin.client_root
    );

    this.getUser();

    if (logoutFromMlogin) {
      href = `${
        this.configurationService.config.mLogin["authority"]
      }oauth2/logout?post_logout_redirect_uri=${encodeURIComponent(
        href
      )}&id_token_hint=${sessionStorage.getItem("id_token_hint")}`;
    }

    window.location.href = href;
    return null;
  }

  public async relogWithCumulusForced(): Promise<void> {
    await this.saveSigninRedirectState({
      ...this.getSigninRedirectState(),
      redirectedMLoginAction: "login",
    });

    const mLoginConfig = this.configurationService.config.mLogin;
    const settings: UserManagerSettings = {
      authority: mLoginConfig.authority,
      client_id: mLoginConfig.client_id,
      redirect_uri: `${this.configurationService.config.mLogin.client_root}/mlogin/callback`,
      post_logout_redirect_uri: `${this.configurationService.config.mLogin.client_root}`,
      response_type: mLoginConfig.response_type,
      scope: mLoginConfig.scope,
      prompt: mLoginConfig.prompt,
      client_secret: this.configurationService.config.mLogin.client_secret,
      extraQueryParams: {
        claims: JSON.stringify({
          ...mLoginConfig.claims,
          userinfo: {
            ...mLoginConfig.claims.userinfo,
            cumulus_connected: {
              essential: true,
            },
          },
        }),
      },
      accessTokenExpiringNotificationTime: 60,
    };
    const tempUserManager = new UserManager(settings);

    tempUserManager.signinRedirect();

    return null;
  }

  public async saveSigninRedirectState(options?: ISigninRedirectState) {
    const state: ISigninRedirectState = {
      ...options,
      url: `//${AuthService.extractHostname(document.location.href)}${
        options?.url || this.router.url
      }`,
    };

    window.sessionStorage.setItem("signinRedirectState", JSON.stringify(state));
    await synchronizeMLoginCrossDomain(
      this.configurationService.config.mLogin.client_root
    );
  }
  public getSigninRedirectState(): ISigninRedirectState {
    const savedStateItem = window.sessionStorage.getItem("signinRedirectState");
    return savedStateItem ? JSON.parse(savedStateItem) : {};
  }

  /**
   * Ask user if he/she sure about that.
   * @returns { NgbModalRef } NgbModalRef modal reference. Can be used to get user confirmation about leaving.
   */
  public openSessionExpireModal() {
    if (this.sessionExpireModalRefSource.getValue()) return null;

    const sessionExpireModalRef = this.modalService.open(
      SessionExpireWarningModalComponent,
      { centered: true }
    );
    sessionExpireModalRef.componentInstance.name = "SessionExpireModal";

    sessionExpireModalRef.result
      .then(() => {
        this.sessionExpireModalRefSource.next(undefined);
        const user = this.renewToken();
        if (user) {
          this.getUser();
        } else {
          if (this.sessionExpireModalRefSource.getValue()) {
            this.sessionExpireModalRefSource.getValue().dismiss();
          }
          this.logout();
        }
      })
      .catch(() => {
        this.sessionExpireModalRefSource.next(undefined);
        this.logout();
      });

    this.sessionExpireModalRefSource.next(sessionExpireModalRef);

    return sessionExpireModalRef;
  }

  static extractHostname = (url, keepPort = false) => {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
      hostname = url.split("/")[2];
    } else {
      hostname = url.split("/")[0];
    }

    if (!keepPort) {
      //find & remove port number
      hostname = hostname.split(":")[0];
    }

    //find & remove "?"
    hostname = hostname.split("?")[0];

    return hostname;
  };
}

const synchronizeMLoginCrossDomain = async (landingDomain: string) => {
  return new Promise((resolve, reject) => {
    const iframe = document.createElement("iframe");
    iframe.width = "0";
    iframe.height = "0";
    iframe.style.visibility = "hidden";
    iframe.src = `${landingDomain}/de/mlogin-crossdomain-sync`;
    document.querySelector("body").appendChild(iframe);

    iframe.addEventListener("load", () => {
      console.log("mLogin sync: Sending MLOGIN_CROSSDOMAIN_SYNC_REQUEST");
      iframe.contentWindow.postMessage(
        {
          messageType: "MLOGIN_CROSSDOMAIN_SYNC_REQUEST",
          oidcStates: getOIDCStates(),
          oidcRecords: getOIDCRecords(),
          tokenHint: getOIDCTokenHint(),
          redirectState: getRedirectState(),
        },
        `${landingDomain}`
      );
    });

    const timeout = setTimeout(() => {
      console.log("mLogin sync: Timeout MLOGIN_CROSSDOMAIN_SYNC_REQUEST");
      reject(false);
    }, 10000);

    window.addEventListener("message", (event) => {
      console.log(event);
      if (event.data.messageType === "MLOGIN_CROSSDOMAIN_SYNC_COMPLETED") {
        console.log("mLogin sync: Received MLOGIN_CROSSDOMAIN_SYNC_COMPLETED");
        resolve(true);
        clearTimeout(timeout);
      }
    });
  });
};

const getOIDCStates = () => {
  const oidcStateRegex = /^oidc\.(?!.*user).*$/;

  return Object.entries(localStorage)
    .filter(([key, value]) => oidcStateRegex.test(key))
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {}
    );
};

const getOIDCRecords = () => {
  return Object.entries(sessionStorage)
    .filter(([key, value]) => key.startsWith("oidc.user"))
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {}
    );
};

const getOIDCTokenHint = () => {
  return sessionStorage.getItem("id_token_hint");
};

const getRedirectState = () => {
  return sessionStorage.getItem("signinRedirectState");
};

class StateStore implements WebStorageStateStore {
  constructor(private prefix: string, private landingDomain: string) {}

  set(key: string, value: any): Promise<void> {
    console.log("set called with:", key, value);
    return new Promise(async (resolve) => {
      localStorage.setItem(this.prefix + key, value);
      await synchronizeMLoginCrossDomain(this.landingDomain);
      resolve(undefined);
    });
  }
  get(key: string): Promise<any> {
    console.log("get called with:", key);
    const record = new Promise((resolve) =>
      resolve(localStorage.getItem(this.prefix + key))
    );
    record.then((res) => console.log(res));
    return record;
  }
  remove(key: string): Promise<any> {
    console.log("remove called with:", key);
    const record = new Promise((resolve) =>
      resolve(localStorage.removeItem(this.prefix + key))
    );
    record.then((res) => console.log(res));
    return record;
  }
  getAllKeys(): Promise<string[]> {
    console.log("getAllKeys called with:");
    const keys = new Promise<string[]>((resolve) =>
      resolve(
        Object.keys(localStorage).filter((key) => key.includes(this.prefix))
      )
    );
    keys.then((res) => console.log(res));
    return keys;
  }
}
