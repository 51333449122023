import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Meta } from "@angular/platform-browser";
import { ConfigurationService } from "src/app/core/configuration.service";

import { Entry } from "contentful";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";

import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { PageTitleService } from "src/app/services/page-title.service";

@Component({
  selector: "dynamic-landing",
  templateUrl: "./dynamic-landing-page.component.html",
  styleUrls: ["./dynamic-landing-page.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicLandingComponent {
  slug: string;
  isLoading: Boolean = true;
  landingContent: Entry<any>[];
  title: string;
  shareUrl: string;
  meta: string[];
  primaryButtonLink: string;
  primaryButtonText: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pageTitleService: PageTitleService,
    private metaService: Meta,
    private configurationService: ConfigurationService
  ) {
    this.getLandingContent();
    this.shareUrl = `${this.configurationService.config.mLogin.client_root}${
      this.router.url
    }`;
  }

  renderRichTextHtml(richText) {
    if (richText?.nodeType !== "document") return "<p></p>";

    const embeddedAssetRenderer = (node) => {
      const { description = "" } = node?.data?.target?.fields;
      const url = `https:${node.data.target.fields.file?.url}`;

      return `<img src="${url}" alt="${description}" width="100%" height="auto">`;
    };

    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) =>
          `<custom-component>${embeddedAssetRenderer(node)}</custom-component>`,
        [INLINES.HYPERLINK]: (node) =>
          node.content[0].marks?.some((mark) => mark.type === MARKS.CODE)
            ? `<a class="btn btn--home d-inline-block" href="${node.data.uri}">${node.content[0].value}</a>`
            : `<a  href="${node.data.uri}">${node.content[0].value}</a>`,
      },
    };

    return documentToHtmlString(richText, options);
  }

  async getLandingContent() {
    const res = this.route.snapshot.data['contentfulEntry'];

    if (res?.items?.length) {
      const [item] = res.items;
      this.landingContent = item.fields.content;
      this.primaryButtonLink = item.fields.buttonLink;
      this.primaryButtonText = item.fields.primaryButtonText;
      this.title = item.fields.pageTitle;
      this.meta = item.fields.metaTags || null;
      // Update meta tags & title
      this.pageTitleService.setPageTitle(this.title);
      for (const key in this.meta) {
        this.metaService.updateTag({ name: key, content: this.meta[key] });
      }
    } else {
      this.router.navigateByUrl(
        `${this.configurationService.getLanguage()}/404`
      );
    }
  }

  ngOnDestroy(): void {
    for (const key in this.meta) {
      this.metaService.removeTag(`name='${key}'`);
    }
  }
}
