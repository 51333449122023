import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { ConfigurationService } from "../core/configuration.service";

@Injectable({ providedIn: "root" })
export class LandingGuard implements CanActivate {
  constructor(private configurationService: ConfigurationService) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(
      "Redirecting to WordPress website:",
      this.configurationService.config.mLogin.client_root
    );
    document.location.href =
      this.configurationService.config.mLogin.client_root;
    return false;
  }
}
