import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router,
    private configurationService: ConfigurationService) {
}

public navigateTo(commands: any[], extras?: NavigationExtras) {
    const currentLaguage = this.configurationService.getLanguage();
    if (commands.length > 0) {
        commands[0] = `${currentLaguage}/${commands[0]}`;
    }
    if (extras) {
        this.router.navigate(commands, extras);
    } else {
        this.router.navigate(commands);
    }
}
}
