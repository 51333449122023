import { Component, OnInit } from "@angular/core";
import { ConfigurationService } from "src/app/core/configuration.service";
import { NavigateWithLang } from "src/app/shared/pipes/navigateWithLang.pipe";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  providers: [NavigateWithLang]
})
export class HomeComponent implements OnInit {
  phoneNumberPf: string;

  constructor(private configurationService: ConfigurationService, private navigateWithLang: NavigateWithLang) {
    this.phoneNumberPf = this.configurationService.config.phoneNumber;
  }

  ngOnInit(): void {}
}
