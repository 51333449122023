import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { RequestService } from "../core/request.service";
import { BaseCrudService } from "../core/basecrud.service";
import { TokenStorage } from "../core/tokenstorage.service";
import { ConfigurationService } from "../core/configuration.service";
import { Policy } from "../models/policy";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import {
  Offer,
  PolicyHolder,
  ReducedPolicyHolder,
  ReducedOffer,
  PolicyHolderSocotra,
  PolicyHolderSocotraExtraFields,
} from "../types/wizard";
import { WizardService } from "../services/wizard.service";
import { SocotraConfigService } from "./socotraConfig.service";
import { AuthService } from "../services/auth.service";
import { isIOS } from "../helpers/isIOS";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FileDownloadModalComponent } from "../components/file-download-modal/file-download-modal.component";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({ providedIn: "root" })
export class PolicyService extends BaseCrudService<Policy> {
  policyApi: string = this.configurationService.config.policyApi;
  selfPath: string;
  staticApi: string;
  servicesApi: string;

  private policyHolderPublicIdPromise: Promise<any>;
  private policyHolderNotCreatedYet: Boolean;

  private ownedProductsSource: BehaviorSubject<string[]>;
  public ownedProducts$: Observable<string[]>;
  private policyHolderPublicIdSource: BehaviorSubject<string>;
  public policyHolderPublicId$: Observable<string>;
  private policyHolderSource: BehaviorSubject<PolicyHolderSocotra>;
  public policyHolder$: Observable<PolicyHolderSocotra>;

  constructor(
    protected translateService: TranslateService,
    protected requestService: RequestService,
    protected tokenStorage: TokenStorage,
    private socotraConfigService: SocotraConfigService,
    private configurationService: ConfigurationService,
    private httpClient: HttpClient,
    private wizardService: WizardService,
    private authService: AuthService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {
    super(
      "policies/",
      configurationService.config.policyApi,
      translateService,
      requestService,
      tokenStorage
    );

    this.selfPath = this.configurationService.config.selfApi;
    this.staticApi = this.configurationService.config.staticApiURL;
    this.servicesApi = this.configurationService.config.gatewayApi;

    this.ownedProductsSource = new BehaviorSubject<string[]>([]);
    this.ownedProducts$ = this.ownedProductsSource.asObservable();
    this.policyHolderPublicIdSource = new BehaviorSubject<string>(undefined);
    this.policyHolderPublicId$ = this.policyHolderPublicIdSource.asObservable();
    this.policyHolderSource = new BehaviorSubject<PolicyHolderSocotra>(
      undefined
    );
    this.policyHolder$ = this.policyHolderSource.asObservable();

    this.authService.userSub$.subscribe(async (userSub) => {
      if (userSub) {
        await this.readOrGetPolicyHolderPublicId(true);
        this.getPolicyHolder();
        this.getOwnedProducts();
      } else {
        this.ownedProducts = [];
      }
    });
  }

  pathExtension: string = `tenants/${this.configurationService.config.tenantId}/`;

  get ownedProducts(): string[] {
    return this.ownedProductsSource.getValue();
  }
  set ownedProducts(ownedProducts: string[]) {
    this.ownedProductsSource.next(ownedProducts);
  }

  get policyHolderPublicId(): string {
    return this.policyHolderPublicIdSource.getValue();
  }
  set policyHolderPublicId(policyHolderPublicId: string) {
    this.policyHolderPublicIdSource.next(policyHolderPublicId);
  }

  public async readOrGetPolicyHolderPublicId(
    renew: Boolean = false
  ): Promise<string> {
    try {
      if (!renew && this.policyHolderNotCreatedYet) {
        return undefined;
      } else if (renew || !this.policyHolderPublicIdPromise) {
        this.policyHolderPublicIdPromise = this.getPolicyHolderPublicId();
        return await this.policyHolderPublicIdPromise;
      } else {
        return await this.policyHolderPublicIdPromise;
      }
    } catch (error) {
      if (error.status === 404) {
        this.policyHolderNotCreatedYet = true;
      }
      this.policyHolderPublicIdPromise = undefined;
      return undefined;
    }
  }

  public async getPolicyHolderPublicId(): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();
    return this.httpClient
      .get(
        this.policyApi + this.pathExtension + "policyholders/publicid",
        requestOptions
      )
      .toPromise();
  }

  get policyHolder(): PolicyHolderSocotra {
    return this.policyHolderSource.getValue();
  }
  set policyHolder(policyHolder: PolicyHolderSocotra) {
    this.policyHolderSource.next(policyHolder);
  }
  public async readOrGetPolicyHolder(): Promise<PolicyHolderSocotra> {
    if (this.policyHolder) return Promise.resolve(this.policyHolder);
    const res = await this.getPolicyHolder();
    return res;
  }

  public getReducedPolicyHolderRequestData(): ReducedPolicyHolder {
    const reducedPolicyHolder: ReducedPolicyHolder = {
      correspondenceLanguage:
        this.wizardService.personalInformation?.correspondenceLanguage ||
        this.configurationService.getLanguage(),
      email: this.wizardService.popupMail,
      cityId: this.wizardService.cityId,
    };

    return reducedPolicyHolder;
  }

  public getPolicyHolderRequestData(): PolicyHolder {
    const policyHolder: PolicyHolder = {
      ...this.getReducedPolicyHolderRequestData(),
      policyHolderPublicId:
        this.wizardService.personalInformation.PolicyHolderPublicId,
      firstName: this.wizardService.personalInformation.firstName,
      lastName: this.wizardService.personalInformation.lastName,
      gender: this.wizardService.personalInformation.gender == "male" ? 0 : 1,
      dateOfBirth: this.wizardService.personalInformation.dateOfBirth,
      nationality: this.wizardService.personalInformation.nationality,
      cityId: this.wizardService.personalInformation.cityId,
      streetName: this.wizardService.personalInformation.streetName,
      houseNumber: this.wizardService.personalInformation.houseNumber,
      email: this.wizardService.personalInformation.email,
      loyalityMembershipNo:
        this.wizardService.personalInformation.cumulusNumber?.replace(
          /\s+/g,
          ""
        ),
      mobilePhone: this.wizardService.personalInformation.mobileNumber.replace(
        / /g,
        ""
      ),
    };

    return policyHolder;
  }

  public getReducedOfferRequestData(): ReducedOffer {
    const reducedOfferData: ReducedOffer = {
      policyHolder: this.getReducedPolicyHolderRequestData(),
      productType: this.wizardService.insuranceType,
      contractStartDate: new Date(Date.now() + 1000 * 60 * 60 * 24)
        .toISOString()
        .slice(0, 10),
      paymentFrequency: "annually",
      selectedPerils: this.wizardService.selectedPerils,
      owner: this.wizardService.ownerStatus,
      family: this.wizardService.howManyPeople,
      canton: this.wizardService.canton,
      numberOfRooms: this.wizardService.roomCount,
      interiorStandard: this.wizardService.furnishingStandard,
      acceptedDataSharing: true,
      discountCode: this.wizardService.discountCode,
      discount: this.wizardService.discountPercent,
      discountLumpSum: this.wizardService.discountLumpSum,
      generalComment: this.wizardService.generalComment,
      DistributionChannel: this.wizardService.DistributionChannel,
    };

    return reducedOfferData;
  }

  public getOfferRequestData(): Offer {
    const offerData: Offer = {
      ...this.getReducedOfferRequestData(),
      policyHolder: this.getPolicyHolderRequestData(),
      contractStartDate: this.wizardService.insuranceStartDate,
      claimsHistory: this.wizardService.claimQuestion?.toLowerCase(),
      previouslyRejected:
        this.wizardService.terminatedOrDeniedInsuranceQuestion?.toLowerCase(),
      acceptedDataSharing: this.wizardService.acceptedDataSharing,
    };

    return offerData;
  }

  public getPolicyHolderUpdateData(): any {
    const policyholder = this.getOfferRequestData().policyHolder;

    return {
      ...policyholder,
      PhoneNumber: policyholder.mobilePhone,
      StreetAddress: policyholder.streetName,
    };
  }

  public async createOffer(offerBody: Offer | ReducedOffer): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();

    try {
      const res = (await this.httpClient
        .post(
          this.policyApi +
            this.pathExtension +
            "products/householdliability/offer",
          offerBody,
          requestOptions
        )
        .toPromise()) as any;

      if (this.authService.user?.access_token) {
        if (res.PolicyHolderPublicId) {
          this.socotraConfigService.exchangeMLoginToken();
        }
      }

      return res;
    } catch (error) {
      throw error;
    }
  }

  public async finalizePolicy(
    policyPublicId: any,
    acceptedTermsAndConditions,
    contractStartDate
  ): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();
    let dcCode: string = "";
    if (this.wizardService.discountCode != undefined) {
      dcCode = "&discountCode=" + this.wizardService.discountCode;
    }

    const res = await this.httpClient
      .post(
        this.policyApi +
          this.pathExtension +
          "products/householdliability/" +
          policyPublicId +
          "/finalize?acceptedTermsAndConditions=" +
          acceptedTermsAndConditions +
          "&contractStartDate=" +
          contractStartDate +
          dcCode,
        "",
        requestOptions
      )
      .toPromise();

    await this.readOrGetPolicyHolderPublicId(true);
    this.getOwnedProducts();
    return res;
  }

  public async createDeeplink(policyPublicId: string): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();

    const res = await this.httpClient
      .post(
        this.policyApi +
          this.pathExtension +
          "products/householdliability/" +
          policyPublicId +
          "/deeplink",
        "",
        requestOptions
      )
      .toPromise();

    this.readOrGetPolicyHolderPublicId(true);
    return res;
  }

  public GetOfferFromDeeplink(
    publicPolicyId: string,
    options
  ): Observable<any> {
    return this.httpClient.get(
      this.policyApi +
        this.pathExtension +
        "products/householdliability/deeplink/" +
        publicPolicyId +
        "/offer",
      options
    );
  }

  public async updatePolicyHolderData(
    policyHolderBody: PolicyHolder
  ): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();

    const res = await this.httpClient
      .patch(
        this.policyApi +
          this.pathExtension +
          "policyholders/" +
          policyHolderBody.policyHolderPublicId,
        policyHolderBody,
        requestOptions
      )
      .toPromise();

    this.readOrGetPolicyHolderPublicId(true);
    return res;
  }

  public async getPolicyHolder(
    policyHolderPublicIdParam?: string
  ): Promise<PolicyHolderSocotra> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();
    const policyHolderPublicId =
      policyHolderPublicIdParam || (await this.readOrGetPolicyHolderPublicId());

    if (
      (this.authService.userSub && policyHolderPublicId) ||
      policyHolderPublicIdParam
    ) {
      const res = await this.httpClient
        .get<any>(
          this.policyApi + "policyholders/" + policyHolderPublicId + "/details",
          requestOptions
        )
        .toPromise();

      const extraFields: PolicyHolderSocotraExtraFields = {
        city:
          res.Details.entity.values.city && res.Details.entity.values.city[0],
        correspondencelanguage:
          res.Details.entity.values.correspondencelanguage &&
          res.Details.entity.values.correspondencelanguage[0],
        dateofbirth:
          res.Details.entity.values.dateofbirth &&
          res.Details.entity.values.dateofbirth[0],
        email:
          res.Details.entity.values.email && res.Details.entity.values.email[0],
        firstname:
          res.Details.entity.values.firstname &&
          res.Details.entity.values.firstname[0],
        gender:
          res.Details.entity.values.gender &&
          res.Details.entity.values.gender[0],
        housenumber:
          res.Details.entity.values.housenumber &&
          res.Details.entity.values.housenumber[0],
        lastname:
          res.Details.entity.values.lastname &&
          res.Details.entity.values.lastname[0],
        mobilephone:
          res.Details.entity.values.mobilephone &&
          res.Details.entity.values.mobilephone[0],
        nationality:
          res.Details.entity.values.nationality &&
          res.Details.entity.values.nationality[0],
        postcode:
          res.Details.entity.values.postcode &&
          res.Details.entity.values.postcode[0],
        streetname:
          res.Details.entity.values.streetname &&
          res.Details.entity.values.streetname[0],
      };
      const policyHolder = {
        ...res,
        extraFields,
      };

      // @ts-ignore
      this.policyHolder = policyHolder;
    }

    return Promise.resolve(this.policyHolder);
  }

  public async getOwnedProducts(): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();
    const policyHolderPublicId = await this.readOrGetPolicyHolderPublicId();

    if (policyHolderPublicId) {
      const ownedProducts: string[] = await this.httpClient
        .get<string[]>(
          this.policyApi +
            this.pathExtension +
            "policyholders/" +
            policyHolderPublicId +
            "/ownedproducts",
          requestOptions
        )
        .toPromise();

      this.ownedProducts = ownedProducts;
      return ownedProducts;
    }

    return [];
  }

  public async getPolicies(): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();
    const policyHolderPublicId = await this.readOrGetPolicyHolderPublicId();

    return this.httpClient
      .get(
        this.policyApi +
          "policyholders/" +
          policyHolderPublicId +
          "/policies?details=false&onlyActive=true",

        requestOptions
      )
      .toPromise();
  }

  public async getUnreadMessageCount(policyHolderPublicId): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();

    return this.httpClient
      .get(
        this.policyApi +
          "policyholders/" +
          policyHolderPublicId +
          "/messages/messages/unread/count",
        requestOptions
      )
      .toPromise();
  }

  public async getQuestionare(policyPublicId: any, requestBody): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();

    return this.httpClient
      .post(
        this.policyApi + "policies/" + policyPublicId + "/claims/questionnaire",
        requestBody,
        requestOptions
      )
      .toPromise();
  }

  public async getPolicyDetail(policyPublicId): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();

    return this.httpClient
      .get(
        this.policyApi +
          "policies/" +
          policyPublicId +
          "/details?withClaims=true",
        requestOptions
      )
      .toPromise();
  }

  public async getPolicyPriceDetails(policyPublicId): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();

    return this.httpClient
      .get(
        this.policyApi + "policies/" + policyPublicId + "/price",
        requestOptions
      )
      .toPromise();
  }

  public async getPolicyPricePerPeriod(
    policyPublicId: string,
    startTimestamp: number,
    policyLocator: number
  ): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();
    const publicTenantId = this.configurationService.config.tenantPublicId;

    return this.httpClient
      .get(
        this.servicesApi +
          "/pricing/policy/" +
          policyPublicId +
          "/period/" +
          startTimestamp +
          "?locator=" +
          policyLocator +
          "&selectedPublicTenantId=" +
          publicTenantId,
        requestOptions
      )
      .toPromise();
  }


  public async GetPolicyDocument(
    policyId: string,
    filename: string,
    containerType: number = 1,
    draftOffer: boolean = false
  ): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();
  
    return this.downloadPDF(
      `${this.policyApi}${this.controller}${policyId}/document/latest?containerType=${containerType}&draftOffer=${draftOffer}`,
      filename,
      requestOptions.headers
    );
  }
  

  public async GetDogDocument(
    policyId: string,
    filename: string
  ): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();

    return this.downloadPDF(
      `${this.policyApi}${this.pathExtension}products/householdliability/${policyId}/dogdocument`,
      filename,
      requestOptions.headers
    );
  }

  public async GetVAG45Document(language: string): Promise<any> {
    return this.downloadPDF(
      this.staticApi +
        this.pathExtension +
        "documents/vag?language=" +
        language,
      this.translateService.instant("VAG45_FILENAME")
    );
  }

  public async GetFactsheetDocument(language: string): Promise<any> {
    return this.downloadPDF(
      this.staticApi +
        this.pathExtension +
        "documents/factsheetandcoverage?language=" +
        language,
      this.translateService.instant("FACTSHEET_FILENAME")
    );
  }

  public async downloadPDF(URL, filename, headers = {}) {
    return this.requestService
      .getPDF(URL, headers)
      .toPromise()
      .then(
        (data) => {
          if (data != undefined) {
            let url = window.URL.createObjectURL(data.blob);

            if (isIOS()) {
              const sanitizedURL = this.sanitizer.bypassSecurityTrustUrl(url);

              const fileDownloadModalRef = this.modalService.open(
                FileDownloadModalComponent,
                { centered: true }
              );
              fileDownloadModalRef.componentInstance.url =
                sanitizedURL as string;
              fileDownloadModalRef.componentInstance.filename = filename;
            } else {
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.href = url;
              a.download = filename;
              a.click();
              setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
              }, 0);
            }
          }
        },
        (error) => {
          this.requestService.handleError(error);
        }
      );
  }

  public async GetPolicyHolderInvoiceDocuments(): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();
    const policyHolderPublicId = await this.readOrGetPolicyHolderPublicId();

    return this.httpClient
      .get(
        this.policyApi +
          "policyholders/" +
          policyHolderPublicId +
          "/invoice/documents",
        requestOptions
      )
      .toPromise();
  }

  public async GetInvoiceDocument(
    policyId: string,
    policyInvoiceId: string,
    documentType: string,
    version: string,
    filename: string
  ): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();

    return this.downloadPDF(
      `${this.policyApi}${this.controller}${policyId}/invoice/${policyInvoiceId}/documents/${documentType}/versions/${version}`,
      filename,
      requestOptions.headers
    );
  }

  //checking discount Code:
  public async checkDiscountCode(discountCode: string): Promise<any> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();
    return this.httpClient
      .get(
        this.policyApi + this.pathExtension + "discountcodes/" + discountCode,
        requestOptions
      )
      .toPromise();
  }

  public async DownloadAvbVersion(logId: string) {
    const requestOptions = await this.socotraConfigService.getRequestOptions();

    return this.downloadPDF(
      `${this.configurationService.config.userApi}${
        this.pathExtension
      }avb/${logId}?language=${this.configurationService.getLanguage()}`,
      this.translateService.instant("GENERAL_CONDITIONS_FILENAME"),
      requestOptions.headers
    );
  }

  public async DownloadAvbProduct(version?: string) {
    const versionParam = version ? `&version=1` : "";

    return this.downloadPDF(
      `${this.staticApi}${
        this.pathExtension
      }documents/avb?product=householdliability&language=${this.configurationService.getLanguage()}${versionParam}`,
      this.translateService.instant("GENERAL_CONDITIONS_FILENAME")
    );
  }

  //email unsubscribe
  public async unsubscribeEmail(
    productName: string,
    policyPublicId: string
  ): Promise<boolean> {
    const requestOptions = await this.socotraConfigService.getRequestOptions();

    return await this.httpClient
      .patch(
        `${this.policyApi}tenants/${this.configurationService.config.tenantPublicId}/products/${productName}/${policyPublicId}/offerquestionaire?option=false`,
        undefined,
        requestOptions
      )
      .toPromise()
      .then((res) => <boolean>res);
  }
}
