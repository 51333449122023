import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  Inject,
  PLATFORM_ID,
  ViewEncapsulation,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-wizard-layout-without-animation",
  templateUrl: "./wizard-layout-without-animation.component.html",
  styleUrls: ["./wizard-layout-without-animation.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class WizardLayoutWithoutAnimationComponent {
  constructor(router: Router, @Inject(PLATFORM_ID) private platformId: Object) {
    router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(0, 0);
      }
    });
  }
}
