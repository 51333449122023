<h2 class="download__title bold">{{ "DOWNLOAD" | translate }}</h2>
<div class="wrapper wrapper--pdf">
  <div
    (click)="downloadFactsheetPDF()"
    class="input-spinner-wrapper wrapper__item d-flex align-item-start align-items-sm-center"
    [ngClass]="{ 'is-loading': isDownloading(1) }"
    id="downloadFactsheetDocument"
  >
    <svg-icon
      src="assets/images/document-icon.svg"
      [svgAriaLabel]="'DOCUMENT_ICON_ALT' | translate"
    ></svg-icon>
    <div
      class="download_link bold"
      [innerHtml]="'DOWNLOADS_1' | translate"
    ></div>
  </div>

  <div
    (click)="downloadVAG45()"
    class="input-spinner-wrapper wrapper__item d-flex align-item-start align-items-sm-center"
    [ngClass]="{ 'is-loading': isDownloading(2) }"
    id="downloadVAG45Document"
  >
    <svg-icon
      src="assets/images/document-icon.svg"
      [svgAriaLabel]="'DOCUMENT_ICON_ALT' | translate"
    ></svg-icon>
    <div
      class="download_link bold"
      [innerHtml]="'DOWNLOADS_2' | translate"
    ></div>
  </div>
  <div
    (click)="downloadAVB()"
    class="input-spinner-wrapper wrapper__item d-flex align-item-start align-items-sm-center"
    [ngClass]="{ 'is-loading': isDownloading(3) }"
    id="downloadAVBDocument"
  >
    <svg-icon
      src="assets/images/document-icon.svg"
      [svgAriaLabel]="'DOCUMENT_ICON_ALT' | translate"
    ></svg-icon>
    <div
      class="download_link bold"
      [innerHtml]="'DOWNLOADS_3' | translate"
    ></div>
  </div>
</div>
