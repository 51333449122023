import {
  Injectable,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID,
} from "@angular/core";
import { createClient, ContentfulClientApi } from "contentful";
import { TranslateService } from "@ngx-translate/core";
import { AppStorage } from "./appstorage.service";
import { ConfigurationService } from "./configuration.service";
import { isPlatformBrowser } from "@angular/common";
import {
  makeStateKey,
  StateKey,
  TransferState,
} from "@angular/platform-browser";
import { HttpClient, HttpResponse } from "@angular/common/http";

@Injectable()
export class ContentfulService {
  @Output() onTranslationsLoaded: EventEmitter<boolean> = new EventEmitter();
  @Output() onBackgroundLoaded: EventEmitter<boolean> = new EventEmitter();

  blogClient: ContentfulClientApi;
  previewClient: ContentfulClientApi;
  client: ContentfulClientApi;
  companyName: string;
  isInitialRender: boolean;

  resolveFunc;
  landingPageResult;

  promiseHelperGlobals = {};

  constructor(
    private translate: TranslateService,
    private appStorage: AppStorage,
    private configurationService: ConfigurationService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private transferState: TransferState,
    private httpClient: HttpClient
  ) {
    this.companyName = this.configurationService.config.companyName;
  }

  private ConfigureClient() {
    this.client = createClient({
      space: this.configurationService.config.contentfulOptions.SpaceId,
      accessToken:
        this.configurationService.config.contentfulOptions.DeliveryApiKey,
      environment:
        this.configurationService.config.contentfulOptions.Environment,
    });
  }
  private ConfigurePreviewClient() {
    this.previewClient = createClient({
      host: "preview.contentful.com",
      space: this.configurationService.config.contentfulBlogOptions.SpaceId,
      accessToken:
        this.configurationService.config.contentfulBlogOptions.AccessToken,
      environment:
        this.configurationService.config.contentfulBlogOptions.Environment,
    });
  }
  private ConfigureBlogClient() {
    this.blogClient = createClient({
      space: this.configurationService.config.contentfulBlogOptions.SpaceId,
      accessToken:
        this.configurationService.config.contentfulBlogOptions.DeliveryApiKey,
      environment:
        this.configurationService.config.contentfulBlogOptions.Environment,

      adapter: (config) => {
        config.url = config.baseURL + "/" + config.url; // fix for Angular 9
        const { headers, data, params } = config;

        const promiseHelperGlobalsKey = params["promiseHelperGlobalsKey"];
        delete params.promiseHelperGlobalsKey;

        const request = this.httpClient.request(
          config.method.toUpperCase(),
          config.url,
          {
            body: data,
            headers,
            params,
            reportProgress: true,
            observe: "response",
          }
        );

        const prom = request.toPromise();

        const res = prom.then((response: HttpResponse<any>) => {
          return {
            data: response.body,
            // IE sends 1223 instead of 204 (https://github.com/axios/axios/issues/201)
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            config: config,
            request,
          };
        });
        if (promiseHelperGlobalsKey) {
          prom.then(() =>
            this.promiseHelperGlobals[promiseHelperGlobalsKey][
              "resolveCallback"
            ](res)
          );
        }

        return res;
      },
    });
  }
  public async LoadAssets(langId: string): Promise<any> {
    if (this.client == null) this.ConfigureClient();

    this.companyName = this.configurationService.config.companyName;

    this.isInitialRender = true;
    await Promise.all([
      this.SetTranslations(langId),
      this.SetIconPaths(),
      this.SetAlternativeHomeImages(),
    ]);
    this.isInitialRender = false;

    return true;
  }
  public GetPreviewBlogEntries(query?: object): Promise<any> {
    if (this.previewClient == null) this.ConfigurePreviewClient();
    return this.previewClient
      .getEntries({
        content_type: "blogPost",
        locale: this.configurationService.getLanguage(),
        order: "-fields.publishDate",
        ...query,
      })
      .then((entries) => {
        return entries;
      })
      .catch((err) => console.log(err));
  }
  public GetPreviewBlogEntryBySlug(slug: string): Promise<any> {
    if (this.previewClient == null) this.ConfigurePreviewClient();

    return this.GetPreviewBlogEntries({
      "fields.slug": slug,
      limit: 1,
    })
      .then((entry) => {
        if (entry) {
          return entry;
        }
      })
      .catch((err) => console.log(err));
  }
  public GetPreviewDynamicLandingPage(query?: object): Promise<any> {
    if (this.previewClient == null) this.ConfigurePreviewClient();
    return this.previewClient
      .getEntries({
        content_type: "landingPage",
        locale: this.configurationService.getLanguage(),
        order: "-sys.createdAt",
        ...query,
      })
      .then((entries) => {
        return entries;
      })
      .catch((err) => console.log(err));
  }
  public GetPreviewDynamicLandingPageBySlug(slug: string): Promise<any> {
    if (this.previewClient == null) this.ConfigurePreviewClient();

    return this.GetPreviewDynamicLandingPage({
      "fields.slug": slug,
      limit: 1,
    })
      .then((entry) => {
        if (entry) {
          return entry;
        }
      })
      .catch((err) => console.log(err));
  }

  /**
   * This function gets Contentful entry of related slug.
   * !! Contentful library has big problems with Angular ZoneAwarePromises in SSR. Please test it well if you make change to this function.
   * In short: getEntries method of Contentful library returns a Promise which causes SSR to think page is ready even before promise resolved. So we need to implement our own httpClient adapter and track original network request. When getEntries promise is resolved we are asigning it to a Class variable. And by awaiting the original httpClient request we make sure getEntries is finished and result is asigned to Class variable. By the end of the original httpClient request we are reading that Class variable and returning the value.
   * You may ask: Why aren't we using original httpClient request's result. Answer is: Contentful library does casts some magic on the response, so it has more fields to use in component.
   */
  public GetDynamicLandingPageBySlug = async (slug: string): Promise<any> => {
    return this.transferStateHelper(
      () =>
        this.contentfulRequestPromiseHelper(async () => {
          return this.blogClient.getEntries({
            content_type: "landingPage",
            locale: this.configurationService.getLanguage(),
            order: "-sys.createdAt",
            "fields.slug": slug,
            limit: 1,
            promiseHelperGlobalsKey: "dynamicLanding",
          });
        }, "dynamicLanding"),
      "dynamicLanding"
    );
  };

  // BLOG
  public async GetBlogCategories(): Promise<any> {
    const res = await this.transferStateHelper(async () => {
      if (this.blogClient == null) this.ConfigureBlogClient();
      const res = await this.blogClient.getEntries({
        content_type: "blogCategory",
        limit: 3,
        locale: this.configurationService.getLanguage(),
        order: "fields.name",
      });
      return res;
    }, "contentfulBlogCategories");

    return res;
  }
  public async GetBlogEntries(query?: object): Promise<any> {
    const res = await this.transferStateHelper(async () => {
      if (this.blogClient == null) this.ConfigureBlogClient();
      const res = await this.blogClient.getEntries({
        content_type: "blogPost",
        locale: this.configurationService.getLanguage(),
        order: "-fields.publishDate",
        ...query,
      });
      return res;
    }, "contentfulBlogPosts");

    return res;
  }
  public async GetBlogEntryBySlug(slug: string): Promise<any> {
    return await this.transferStateHelper(
      () =>
        this.contentfulRequestPromiseHelper(async () => {
          return await this.blogClient.getEntries({
            content_type: "blogPost",
            locale: this.configurationService.getLanguage(),
            order: "-fields.publishDate",
            "fields.slug": slug,
            limit: 1,
            promiseHelperGlobalsKey: "contentfulBlogPost",
          });
        }, "contentfulBlogPost"),
      "contentfulBlogPost"
    );
  }

  public async GetBlogLandingPageContent(): Promise<any> {
    const res = await this.transferStateHelper(async () => {
      if (this.blogClient == null) this.ConfigureBlogClient();
      const res = await this.blogClient.getEntries({
        content_type: "blogLandingPage",
        locale: this.configurationService.getLanguage(),
      });
      return res;
    }, "contentfulBlogPageContent");

    return res;
  }

  public GetAlternativeSlugs(id: string): Promise<any> {
    if (this.blogClient == null) this.ConfigureBlogClient();

    return this.blogClient
      .getEntries({
        content_type: "blogPost",
        order: "-fields.publishDate",
        select: "fields.slug",
        "sys.id": id,
        locale: "*",
        limit: 1,
      })
      .then((entry) => {
        if (entry) {
          return entry;
        }
      })
      .catch((err) => console.log(err));
  }

  /**
   * This function basicly solves a problem related to SSR and Contentful. This function accepts an async function which contains a call to Contentful package's getEntries method. Returns the result of this getEntries call.
   * !! Contentful library has big problems with Angular ZoneAwarePromises in SSR. Please test it well if you make change to this function.
   * In short: getEntries method of Contentful library returns a Promise which causes SSR to think page is ready even before promise resolved. So we need to implement our own httpClient adapter and track original network request (which returns Angular compatible ZoneAwarePromise instead of a standard one). When getEntries promise is resolved we are asigning it to promiseHelperGlobals property of this Class. And by awaiting the original httpClient request we make sure getEntries is finished and result is asigned to promiseHelperGlobals. By the end of the original httpClient request we are reading that promiseHelperGlobals and returning the value.
   * You may ask: Why aren't we using original httpClient request's result instead of getEntries'. Answer is: Contentful library does some magic on the response, so it has more fields to use in component.
   */
  private contentfulRequestPromiseHelper = async (
    fetcher: () => Promise<any>,
    promiseHelperGlobalsKey
  ) => {
    if (this.blogClient == null) this.ConfigureBlogClient();
    this.promiseHelperGlobals[promiseHelperGlobalsKey] = {};

    const promiseToTrackOriginalRequest = new Promise((resolve) => {
      this.promiseHelperGlobals[promiseHelperGlobalsKey]["resolveCallback"] =
        resolve;
    });

    const res = fetcher();
    res.then(
      (result) =>
        (this.promiseHelperGlobals[promiseHelperGlobalsKey]["result"] = result)
    );

    await promiseToTrackOriginalRequest;

    // This 1ms delay is required for asigning getEntries result to promiseHelperGlobals to take effect before reading it
    const timeoutPromise = await new Promise((resolve) => {
      setTimeout(() => {
        resolve("test");
      }, 1);
    });
    await timeoutPromise;

    return this.promiseHelperGlobals[promiseHelperGlobalsKey]["result"];
  };

  /**
   * This function saves Contentful response to TransferState in Server-Side and then re-use it
   * when Angular hydrated in the Browser.
   * This solves flashing/blank page issues when page loaded.
   *
   * @param fetcher An async function which fetches and returns Contentful content.
   * Return values of this function will be saved to TransferState and will be used when hydrated.
   *
   * @param transferStateKey This string will be used as key while storing to TransferState
   */
  private async transferStateHelper(
    fetcher: () => Promise<any>,
    transferStateKey: string
  ) {
    const stateKey: StateKey<string> = makeStateKey<object>(transferStateKey);
    const existingState = this.transferState.get(stateKey, null);

    if (isPlatformBrowser(this.platformId) && existingState) {
      // Remove state so further call to this method gets fresh data instead of SSR data.
      this.transferState.remove(stateKey);
      return new Promise((resolve) => {
        resolve(JSON.parse(existingState));
      });
    } else {
      const res = await fetcher();

      if (!isPlatformBrowser(this.platformId)) {
        // Save fetched data to TransferState to transfer into browser when app hydrated.
        this.transferState.set(stateKey, JSON.stringify(res));
      }

      return res;
    }
  }

  public SetTranslations(langId: string = "en"): Promise<any> {
    if (this.translate.currentLang === langId) return Promise.resolve(true);

    return this.GetTranslations(langId)
      .then((res) => {
        let translations = res;

        this.translate.resetLang(this.translate.currentLang);
        this.translate.setTranslation(langId, translations);
        this.translate.use(langId);
        this.onTranslationsLoaded.emit(true);

        return true;
      })
      .catch((err) => console.log(err));
  }

  private async GetTranslations(langId: string): Promise<any> {
    const translationsStateKey: StateKey<string> = makeStateKey<string>(
      "contentfulTranslations"
    );
    const translationsFromTransferState = this.transferState.get(
      translationsStateKey,
      null
    );

    if (
      isPlatformBrowser(this.platformId) &&
      this.isInitialRender &&
      translationsFromTransferState
    ) {
      return new Promise((resolve) => {
        resolve(JSON.parse(translationsFromTransferState));
      });
    } else {
      const res = await this.client.getEntries({
        content_type: "translations",
        "fields.type": "Migros_Wizard",
        locale: langId,
      });

      let translations = {};
      res.items.forEach((item) => {
        try {
          Object.keys(item.fields["data"]).forEach((key) => {
            translations[key] = item.fields["data"][key];
          });
        } catch (error) {
          console.error("Translation is missing on Contentful.");
        }
      });

      // Save fethed data to TransferState to transfer into browser when app hydrated.
      const translationsStateKey: StateKey<string> = makeStateKey<string>(
        "contentfulTranslations"
      );
      this.transferState.set(
        translationsStateKey,
        JSON.stringify(translations)
      );

      return translations;
    }
  }

  private SetIconPaths(): Promise<any> {
    return this.GetAssets(`icons_${this.companyName}`).then((assets) => {
      const x = {};
      assets.forEach((asset) => {
        const key = asset.fields.title.replace(
          `icons_${this.companyName}_`,
          ""
        );
        const value = asset.fields.file.url.replace("//", "https://");
        x[key] = value;
      });

      this.appStorage.setIconPaths(x);
    });
  }
  private SetAlternativeHomeImages(): Promise<any> {
    return this.GetAssets(`home_hero_`).then((assets) => {
      if (assets.length > 0) {
        assets.forEach((asset) => {
          if (asset.fields.title == "home_hero_familie")
            this.appStorage.setHomeHeroFamilie(
              asset.fields.file.url.replace("//", "https://")
            );
          if (asset.fields.title == "home_hero_jung")
            this.appStorage.setHomeHeroJung(
              asset.fields.file.url.replace("//", "https://")
            );
          if (asset.fields.title == "home_hero_studenten")
            this.appStorage.setHomeHeroStudenten(
              asset.fields.file.url.replace("//", "https://")
            );
          if (asset.fields.title == "home_hero_optimierer")
            this.appStorage.setHomeHeroOptimierer(
              asset.fields.file.url.replace("//", "https://")
            );
        });
        return true;
      }
    });
  }

  private async GetAssets(title: string, locale?: string): Promise<any> {
    const assetStateKey: StateKey<string> = makeStateKey<string>(title);
    const assetFromTransferState = this.transferState.get(assetStateKey, null);

    if (
      isPlatformBrowser(this.platformId) &&
      this.isInitialRender &&
      assetFromTransferState
    ) {
      return JSON.parse(assetFromTransferState);
    } else {
      const res = await this.client.getAssets({
        "fields.title[match]": title,
        locale,
      });

      // Save fethed data to TransferState to transfer into browser when app hydrated.
      const assetStateKey: StateKey<string> = makeStateKey<string>(title);
      this.transferState.set(assetStateKey, JSON.stringify(res.items));

      return res.items;
    }
  }
}
