<section class="section--report-damage">
  <div class="title d-flex align-items-center">
    <svg-icon src="assets/images/accident-icon.svg" [svgAriaLabel]="'HOUSE_ON_FIRE_ICON_ALT' | translate"></svg-icon>
    <h1>{{ "REPORT_DAMAGE" | translate }}</h1>
  </div>
  <h2 class="bold">{{ "REPORT_DAMAGE_SUBTITLE" | translate }}</h2>
  <div class="row">
    <div class="col-12 col-md-6 wrapper wrapper-login">
      <h3 class="bold subtitle">{{ "MESSAGE_VIA_COCKPIT" | translate }}</h3>
      <p>
        {{ "MESSAGE_VIA_COCKPIT_TEXT" | translate }}
      </p>

      <div class="wizard-actions">
        <button (click)="onLogin()" class="btn btn-tertiary btn-tertiary--content" *ngIf="!authService.isLoggedIn">
          <svg-icon src="assets/images/m-icon.svg" class="m-icon"></svg-icon>
          <div class="line"></div>
          <p>{{ "SIGN_IN" | translate }}</p>
        </button>

        <app-wizard-link-button *ngIf="authService.isLoggedIn" trailingIcon="right-arrow" (click)="
            isCockpitActive
              ? goToReportDamage()
              : openVerticallyCentered(content)
          ">
          {{ "REPORT_DAMAGE" | translate }}</app-wizard-link-button>
      </div>
    </div>
    <div class="col-12 col-md-6 wrapper wrapper-communication">
      <h3 class="bold subtitle">
        {{ "REPORT_BY_PHONE" | translate }}
      </h3>
      <p [innerHtml]="'REPORT_BY_PHONE_TEXT' | translate"></p>
      <a class="align-items-center call" href="tel:{{ phoneNumberPf }}" rel="noreferrer">
        <svg-icon src="assets/images/call-phone.svg" [svgAriaLabel]="'PHONE_ICON_ALT' | translate"></svg-icon>
        <p>{{ phoneNumberPf }}</p>
      </a>
    </div>
  </div>
</section>

<ng-template #content let-modal>
  <div class="modal-header">
    <h3 class="modal-title">{{ "NO_ACTIVE_INSURANCE_TITLE" | translate }}</h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <svg-icon src="assets/images/close-icon.svg" class="close-icon"></svg-icon>
    </button>
  </div>
  <div class="modal-body">{{ "NO_ACTIVE_INSURANCE_TEXT" | translate }}</div>
  <div class="modal-footer">
    <app-wizard-button variant="secondary" (onCallBack)="modal.dismiss('Cross click')">
      {{ "CANCEL" | translate }}
    </app-wizard-button>

    <app-wizard-link-button [type]="'submit'" (onCallBack)="modal.dismiss('Cross click')"
      [link]="'/wizard/insurance-type' | navigateWithLang">
      {{ "BUY_INSURANCE" | translate }}
    </app-wizard-link-button>
  </div>
</ng-template>
