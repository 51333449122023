import { Component, OnInit } from "@angular/core";
import { ConfigurationService } from "src/app/core/configuration.service";

@Component({
  selector: "app-wizard-navigation-bar",
  templateUrl: "./wizard-navigation-bar.component.html",
  styleUrls: ["./wizard-navigation-bar.component.scss"],
})
export class WizardNavigationBarComponent implements OnInit {
  logoPath: string;
  phoneNumberPf: string;
 
  constructor(
    public configurationService: ConfigurationService,
  ) {
    this.phoneNumberPf = this.configurationService.config.phoneNumber;
  }

  ngOnInit(): void {}
}
