import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { ConfigurationService } from "../../core/configuration.service";

@Pipe({
  name: "formatDate",
  pure: false,
})
@Injectable()
export class FormatDate implements PipeTransform {
  constructor(private configurationService: ConfigurationService) {}

  transform(dateString: string, toLocal?: Boolean) {
    let d: Date;

    if (toLocal) {
      const arr =
        /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(
          dateString
        );

      d = new Date(
        Date.UTC(+arr[1], +arr[2] - 1, +arr[3], +arr[4], +arr[5], +arr[6])
      );
    } else {
      d = new Date(dateString);
    }

    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${da}.${mo}.${ye}`;
  }
}
