import { BrowserStorage } from "./browserStorage.service";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep } from "lodash-es";

// Types
import {
  ClaimQuestion,
  CorrespondenceLanguage,
  Deductible,
  DiscountCode,
  FurnishingStandard,
  HowManyPeople,
  InsuranceAmount,
  InsuranceStartDate,
  InsuranceType,
  Canton,
  OwnerStatus,
  PersonalInformation,
  RoomCount,
  Summary,
  TerminatedOrDeniedInsuranceQuestion,
  IPeril,
  PolicyPublicId,
  CityInfo,
  CumulusInfo,
  PrivateLiabiltyPrice,
  IUIPeril,
} from "src/app/types/wizard";
import { wizardRootPath, wizardSteps } from "../constants/wizardSteps";
import { ConfigurationService } from "../core/configuration.service";
import { WizardLeaveConfirmModalComponent } from "../components/wizard-leave-confirm-modal/wizard-leave-confirm-modal.component";
import { skip } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { SocotraConfigService } from "../core/socotraConfig.service";
import { localizedRoutes } from "../localized-routes";
import { isPlatformBrowser } from "@angular/common";
import { getExistingPerilOrDefaultConfig } from "../helpers/getExistingPerilOrDefaultConfig";

export interface IWizardState {
  wizardCurrentStep: number;
  wizardLatestStep: number;
  wizardLeaveModalOpen: Boolean;
  wizardLeaveModalRef: NgbModalRef;
  stillHasInitialData: Boolean;
  savedAt?: number;
  needToSkipStep6: Boolean; // This will be set to 'true' when login/register button clicked on step 6, and step 6 will be automatically skipped when app loaded.
  existingOfferData: any;
}

const initialWizardState: IWizardState = {
  wizardCurrentStep: 1,
  wizardLatestStep: 0,
  wizardLeaveModalOpen: false,
  wizardLeaveModalRef: undefined,
  stillHasInitialData: true,
  savedAt: undefined,
  needToSkipStep6: false,
  existingOfferData: undefined,
};

export interface IWizardData {
  insuranceType: InsuranceType;
  howManyPeople: HowManyPeople;
  ownerStatus: OwnerStatus;
  furnishingStandard: FurnishingStandard;
  canton: Canton;
  cityInfo: CityInfo;
  cityId: number;
  insuranceAmount: InsuranceAmount;
  roomCount: RoomCount;
  insuranceStartDate: InsuranceStartDate;
  personalInformation: PersonalInformation;
  claimQuestion: ClaimQuestion;
  terminatedOrDeniedInsuranceQuestion: TerminatedOrDeniedInsuranceQuestion;
  discountCode: DiscountCode;
  discountPercent: number;
  discountLumpSum: number;
  discountedTotal: number;
  extraCumulusPoints: number;
  deductible: Deductible;
  summary: Summary;
  correspondenceLanguage: CorrespondenceLanguage;
  selectedPerils: IPeril[];
  acceptedTermsAndConditions: boolean;
  acceptedDataSharing: boolean;
  policyPublicId: PolicyPublicId;
  popupMail: string;
  cumulusInfo: CumulusInfo;
  privateLiabiltyPrice: PrivateLiabiltyPrice;
  isDiscountAdded: Boolean;
  generalComment: string;
  DistributionChannel: string;
}

export const initialWizardData: IWizardData = {
  insuranceType: undefined,
  howManyPeople: undefined,
  ownerStatus: undefined,
  furnishingStandard: undefined,
  canton: undefined,
  cityInfo: undefined,
  cityId: undefined,
  insuranceAmount: undefined,
  roomCount: 1,
  insuranceStartDate: undefined,
  personalInformation: undefined,
  claimQuestion: undefined,
  terminatedOrDeniedInsuranceQuestion: undefined,
  discountCode: undefined,
  discountPercent: undefined,
  discountLumpSum: undefined,
  discountedTotal: undefined,
  extraCumulusPoints: undefined,
  deductible: 500,
  summary: 0,
  correspondenceLanguage: undefined,
  selectedPerils: [],
  acceptedTermsAndConditions: false,
  acceptedDataSharing: false,
  policyPublicId: undefined,
  popupMail: undefined,
  cumulusInfo: undefined,
  privateLiabiltyPrice: undefined,
  isDiscountAdded: false,
  generalComment: undefined,
  DistributionChannel: undefined,
};

export const deeplinkPolicyRelatedFields = [
  "insuranceType",
  "howManyPeople",
  "ownerStatus",
  "cityId",
  "insuranceAmount",
  "roomCount",
  "personalInformation",
  "cumulusInfo",
  "deductible",
  "correspondenceLanguage",
  "selectedPerils",
];

interface ILocalStorageWizardState {
  data: IWizardData;
  state: IWizardState;
}

@Injectable()
export class WizardService {
  private _router: Router;

  public wizardDataSource: BehaviorSubject<IWizardData>;
  private wizardStateSource: BehaviorSubject<IWizardState>;

  public wizardData$: Observable<IWizardData>;
  public wizardState$: Observable<IWizardState>;

  public isPersonalInfoUpdatingSource: BehaviorSubject<Boolean> =
    new BehaviorSubject<Boolean>(false);
  public isPersonalInfoUpdating$: Observable<Boolean> =
    this.isPersonalInfoUpdatingSource.asObservable();

  constructor(
    private router: Router,
    private configurationService: ConfigurationService,
    private modalService: NgbModal,
    private authService: AuthService,
    private socotraConfigService: SocotraConfigService,
    private browserStorage: BrowserStorage,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this._router = router;
    this.wizardDataSource = new BehaviorSubject<IWizardData>(
      cloneDeep(initialWizardData)
    );
    this.wizardStateSource = new BehaviorSubject<IWizardState>(
      cloneDeep(initialWizardState)
    );

    this.wizardData$ = this.wizardDataSource.asObservable();
    this.wizardState$ = this.wizardStateSource.asObservable();

    this.wizardData$.pipe(skip(1)).subscribe((data) => {
      if (this.stillHasInitialData) {
        this.stillHasInitialData = false;
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener("beforeunload", (e) => this.wizardLeaveGuard(e));
    }

    this.authService.user$.subscribe(() => {
      this.getUserData();
    });
  }

  /**
   * Is user info being fetched
   */
  get isPersonalInfoUpdating() {
    return this.isPersonalInfoUpdatingSource.getValue();
  }
  set isPersonalInfoUpdating(isPersonalInfoUpdating: Boolean) {
    this.isPersonalInfoUpdatingSource.next(isPersonalInfoUpdating);
  }
  async getUserData() {
    this.isPersonalInfoUpdating = true;
    const user = this.authService.user;
    if (user) {
      const languageMigros = user.profile.locale.split("-")[0];
      const correspondenceLanguage =
        this.configurationService.config.languages.some(
          (lang) => lang.id === languageMigros
        )
          ? languageMigros
          : this.configurationService.config.defaultLanguage;

      const cities = await this.socotraConfigService.getCityId(
        user.profile.address.postal_code
      );
      const city = cities.find((cty) =>
        cty.Name.includes(user.profile.address.locality)
      );

      //@ts-ignore
      let personalInfo: PersonalInformation = {
        correspondenceLanguage,
        firstName: user.profile.given_name,
        lastName: user.profile.family_name,
        gender: user.profile.gender,
        dateOfBirth: user.profile.birthdate,
        nationality: user.profile.address.country,
        city: city.Name,
        cityId: city.Id,
        // @ts-ignore - street not exists in TS definitions but exist in real data
        streetName: user.profile.address.street,
        // @ts-ignore - house_number not exists in TS definitions but exist in real data
        houseNumber: `${user.profile.address.house_number}${
          // @ts-ignore - house_number not exists in TS definitions but exist in real data
          user.profile.address.house_number_addition || ""
        }`,
        email: user.profile.email,
        mobileNumber: user.profile.phone_number,
        postCode: user.profile.address.postal_code,
        cumulusNumber: undefined,
        subjectId: user.profile.sub,
      };

      if (user.profile.cumulus_connected) {
        const cumulusRes = await this.socotraConfigService.getCumulusInfo();
        let cumulusInfo: CumulusInfo = {
          cumulus_status: cumulusRes.cumulus_status,
          cumulus_number: cumulusRes.cumulus_number,
          firstname: cumulusRes.firstname,
          lastname: cumulusRes.lastname,
          email: cumulusRes.email,
          gender: cumulusRes.gender,
          has_address: cumulusRes.has_address,
          locked: cumulusRes.locked,
        };
        this.cumulusInfo = cumulusInfo;
        personalInfo.cumulusNumber = cumulusRes.cumulus_number;
      }

      this.personalInformation = personalInfo;
    }

    this.isPersonalInfoUpdating = false;
  }

  wizardLeaveGuard(e) {
    // BYPASS: Migros wanted to remove this modal, so in next line always return true
    return true;

    const currentLanguage = this.configurationService.getLanguage();
    const localizedwizardRootPath =
      localizedRoutes[wizardRootPath]?.langs[currentLanguage];

    if (
      this.router.url.includes(`/${localizedwizardRootPath}`) &&
      !this.stillHasInitialData &&
      !this.authService.getSigninRedirectState().redirectedMLoginAction &&
      this.wizardCurrentStep !==
        wizardSteps.find((step) => step.path === "final").stepNumber
    ) {
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = "";
    }
  }

  /**
   * Handle insuranceType selection
   */
  onInsuranceTypeChange(insuranceType: InsuranceType) {
    const insuranceTypePerils =
      this.configurationService.config.insuranceTypePerils;

    const perilConfigurations = [
      ...this.configurationService.config.householdPerils,
      ...this.configurationService.config.liabilityPerils,
    ];

    this.insuranceType = insuranceType;

    // Get basic perils of selected insurance type. If it is already selected then get it otherwise get default config of peril.
    const basicPerils = insuranceTypePerils[insuranceType].basic.map(
      (perilName) =>
        getExistingPerilOrDefaultConfig(
          perilName,
          this.selectedPerils,
          perilConfigurations
        )
    );

    // Filter already selected perils and only keep the ones related to newly selected insurance type
    const preservedOptionalPerils = this.selectedPerils.filter((peril) =>
      [...insuranceTypePerils[insuranceType].option].includes(peril.Name)
    );

    // Save new perils to store
    this.selectedPerils = [...basicPerils, ...preservedOptionalPerils];
  }

  /** Get default peril definitions */
  getDefaultPerils() {
    const defaultPerils: IUIPeril[] = [
      ...(this.insuranceType.includes("household")
        ? this.configurationService.config.householdPerils
        : []),
      ...(this.insuranceType.includes("liability")
        ? this.configurationService.config.liabilityPerils
        : []),
    ];

    const defaultPerilsWithUpdatedDeductibles = defaultPerils.map(
      (defaultPeril) => ({
        ...defaultPeril,
        Deductible: defaultPeril.useBasicPerilsDeductibleValue
          ? this.deductible
          : defaultPeril.Deductible,
      })
    );

    return defaultPerilsWithUpdatedDeductibles;
  }

  updateSelectedPerilsWhichUsesBasicDeductible(deductible: number) {
    this.selectedPerils = this.selectedPerils.map((peril) => {
      const defaultPeril = this.getDefaultPerils().find(
        (defaultPeril) => defaultPeril.Name === peril.Name
      );
      return {
        ...peril,
        Deductible: defaultPeril.useBasicPerilsDeductibleValue
          ? deductible
          : peril.Deductible,
      };
    });
  }

  getOfferRelatedData() {
    const currentValues = this.wizardDataSource.getValue();

    return deeplinkPolicyRelatedFields.reduce((acc, fieldName) => {
      if (fieldName === "selectedPerils") {
        return {
          ...acc,
          selectedPerils: currentValues.selectedPerils
            .filter((peril) => peril.IsSelected)
            .map((peril) => ({
              Name: peril.Name,
              Deductible: peril.Deductible,
              IsSelected: peril.IsSelected,
              SumInsured: peril.SumInsured,
            })),
        };
      }

      if (fieldName === "personalInformation") {
        return {
          ...acc,
          personalInformation: Object.entries(
            currentValues.personalInformation
          ).reduce(
            (acc2, [key, val]) => ({
              ...acc2,
              ...(val ? { [key]: val } : {}),
            }),
            {}
          ),
        };
      }

      return {
        ...acc,
        ...(currentValues[fieldName]
          ? { [fieldName]: currentValues[fieldName] }
          : {}),
      };
    }, {});
  }

  /**
   * Type of insurance
   */
  get insuranceType() {
    return this.wizardDataSource.getValue().insuranceType;
  }
  set insuranceType(insuranceType: InsuranceType) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      insuranceType,
    });
  }

  /**
   * How many people lives in house
   */

  get howManyPeople() {
    return this.wizardDataSource.getValue().howManyPeople;
  }
  set howManyPeople(howManyPeople: HowManyPeople) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      howManyPeople,
    });
  }

  /**
   * Owner status of house
   */

  get ownerStatus() {
    return this.wizardDataSource.getValue().ownerStatus;
  }
  set ownerStatus(ownerStatus: OwnerStatus) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      ownerStatus,
    });
  }

  /**
   * Furnishing Standards
   */

  get furnishingStandard() {
    return this.wizardDataSource.getValue().furnishingStandard;
  }
  set furnishingStandard(furnishingStandard: FurnishingStandard) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      furnishingStandard,
    });
  }

  /**
   * Living Place
   */

  get canton() {
    return this.wizardDataSource.getValue().canton;
  }
  set canton(canton: Canton) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      canton,
    });
  }

  /**
   * City Info
   */

  get cityInfo() {
    return this.wizardDataSource.getValue().cityInfo;
  }
  set cityInfo(cityInfo: CityInfo) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      cityInfo,
    });
  }

  /**
   * City Id
   */
  get cityId() {
    return this.wizardDataSource.getValue().cityId;
  }
  set cityId(cityId: number) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      cityId,
    });
  }

  /**
   * Insurance Amount
   */

  get insuranceAmount() {
    return this.wizardDataSource.getValue().insuranceAmount;
  }
  set insuranceAmount(insuranceAmount: InsuranceAmount) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      insuranceAmount,
    });
  }

  /**
   * Room Count
   */

  get roomCount() {
    return this.wizardDataSource.getValue().roomCount;
  }
  set roomCount(roomCount: RoomCount) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      roomCount,
    });
  }

  /**
   * Insurance Start Date
   */

  get insuranceStartDate() {
    return this.wizardDataSource.getValue().insuranceStartDate;
  }
  set insuranceStartDate(insuranceStartDate: InsuranceStartDate) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      insuranceStartDate,
    });
  }

  /**
   * Personal Information
   */

  get personalInformation() {
    return this.wizardDataSource.getValue().personalInformation;
  }
  set personalInformation(personalInformation: PersonalInformation) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      personalInformation: {
        ...this.wizardDataSource.getValue().personalInformation,
        ...personalInformation,
      },
    });
  }

  get cumulusInfo() {
    return this.wizardDataSource.getValue().cumulusInfo;
  }
  set cumulusInfo(cumulusInfo: CumulusInfo) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      cumulusInfo,
    });
  }

  /**
   * Claim Question
   */

  get claimQuestion() {
    return this.wizardDataSource.getValue().claimQuestion;
  }
  set claimQuestion(claimQuestion: ClaimQuestion) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      claimQuestion,
    });
  }

  /**
   * terminatedOrDeniedInsuranceQuestion
   */

  get terminatedOrDeniedInsuranceQuestion() {
    return this.wizardDataSource.getValue().terminatedOrDeniedInsuranceQuestion;
  }
  set terminatedOrDeniedInsuranceQuestion(
    terminatedOrDeniedInsuranceQuestion: TerminatedOrDeniedInsuranceQuestion
  ) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      terminatedOrDeniedInsuranceQuestion,
    });
  }

  /**
   * discountCode
   */

  get discountCode() {
    return this.wizardDataSource.getValue().discountCode;
  }
  set discountCode(discountCode: DiscountCode) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      discountCode: discountCode && discountCode.trim(),
    });
  }

  /**
   * discountPercent
   */

  get discountPercent() {
    return this.wizardDataSource.getValue().discountPercent;
  }
  set discountPercent(discountPercent: number) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      discountPercent,
    });
  }

  /**
   * discountLumpSum
   */

  get discountLumpSum() {
    return this.wizardDataSource.getValue().discountLumpSum;
  }
  set discountLumpSum(discountLumpSum: number) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      discountLumpSum,
    });
  }

  /**
   * discountedTotal
   */

  get discountedTotal() {
    return this.wizardDataSource.getValue().discountedTotal;
  }
  set discountedTotal(discountedTotal: number) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      discountedTotal,
    });
  }

  /**
   * extraCumulusPoints
   */

  get extraCumulusPoints() {
    return this.wizardDataSource.getValue().extraCumulusPoints;
  }
  set extraCumulusPoints(extraCumulusPoints: number) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      extraCumulusPoints,
    });
  }

  /**
   * generalComment
   */

  get generalComment() {
    return this.wizardDataSource.getValue().generalComment;
  }
  set generalComment(generalComment: string) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      generalComment,
    });
  }

  /**
   * DistributionChannel
   */

  get DistributionChannel() {
    return this.wizardDataSource.getValue().DistributionChannel;
  }
  set DistributionChannel(DistributionChannel: string) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      DistributionChannel,
    });
  }

  get deductible() {
    return this.wizardDataSource.getValue().deductible;
  }
  set deductible(deductible: Deductible) {
    this.updateSelectedPerilsWhichUsesBasicDeductible(deductible);

    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      deductible,
    });
  }

  get summary() {
    return this.wizardDataSource.getValue().summary;
  }
  set summary(summary: Summary) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      summary,
    });
  }

  // correspondenceLanguage getter setter

  get correspondenceLanguage() {
    return this.wizardDataSource.getValue().correspondenceLanguage;
  }
  set correspondenceLanguage(correspondenceLanguage: CorrespondenceLanguage) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      correspondenceLanguage,
    });
  }

  get privateLiabiltyPrice() {
    return this.wizardDataSource.getValue().privateLiabiltyPrice;
  }
  set privateLiabiltyPrice(privateLiabiltyPrice: PrivateLiabiltyPrice) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      privateLiabiltyPrice,
    });
  }

  /**
   * Selected Perils Types
   */

  get selectedPerils() {
    return this.wizardDataSource.getValue().selectedPerils;
  }
  set selectedPerils(selectedPerils: IPeril[]) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      selectedPerils,
    });
  }

  /**
   * Accepted Terms And Conditions
   */

  get acceptedDataSharing() {
    return this.wizardDataSource.getValue().acceptedDataSharing;
  }
  set acceptedDataSharing(acceptedDataSharing: boolean) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      acceptedDataSharing,
    });
  }

  /**
   * Accepted Terms And Conditions
   */

  get acceptedTermsAndConditions() {
    return this.wizardDataSource.getValue().acceptedTermsAndConditions;
  }
  set acceptedTermsAndConditions(acceptedTermsAndConditions: boolean) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      acceptedTermsAndConditions,
    });
  }

  /**
   * Popup Mail Address
   */

  get popupMail() {
    return this.wizardDataSource.getValue().popupMail;
  }
  set popupMail(popupMail: string) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      popupMail,
    });
  }

  /**
   * Policy Public Id
   */

  get policyPublicId() {
    return this.wizardDataSource.getValue().policyPublicId;
  }
  set policyPublicId(policyPublicId: string) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      policyPublicId,
    });
  }

  /**
   * Is Discouunt Added?
   */

  get isDiscountAdded() {
    return this.wizardDataSource.getValue().isDiscountAdded;
  }
  set isDiscountAdded(isDiscountAdded: Boolean) {
    this.wizardDataSource.next({
      ...this.wizardDataSource.getValue(),
      isDiscountAdded,
    });
  }

  public clearAllData() {
    this.wizardDataSource.next({
      ...cloneDeep(initialWizardData),
      selectedPerils: [],
      personalInformation: !this.authService.user
        ? undefined
        : this.personalInformation,
    });
    this.wizardStateSource.next(initialWizardState);

    this.saveWizardStateToLocalStorage();
  }

  /**
   * ##Wizard State
   * This section controls enabling next and previous steps.
   * These calculations are generally based on user inputs.
   */

  /**
   * Getter for wizard current step.
   */
  get wizardCurrentStep() {
    return this.wizardStateSource.getValue().wizardCurrentStep;
  }
  set wizardCurrentStep(wizardCurrentStep: number) {
    this.wizardStateSource.next({
      ...this.wizardStateSource.getValue(),
      wizardCurrentStep,
    });
  }

  /**
   * Getter for needToSkipStep6. This will be set to 'true' when login/register button clicked on step 6, and step 6 will be automatically skipped when app loaded.
   */
  get needToSkipStep6() {
    return this.wizardStateSource.getValue().needToSkipStep6;
  }
  set needToSkipStep6(needToSkipStep6: Boolean) {
    this.wizardStateSource.next({
      ...this.wizardStateSource.getValue(),
      needToSkipStep6,
    });
  }

  /**
   * Getter for existingOfferData. Existing offer's data (will be set after deeplink loaded or an offer created).
   * Will be used for comparing changes in last step. Acording to changes new offer may be created.
   */
  get existingOfferData() {
    return this.wizardStateSource.getValue().existingOfferData;
  }
  set existingOfferData(existingOfferData: any) {
    this.wizardStateSource.next({
      ...this.wizardStateSource.getValue(),
      existingOfferData,
    });
  }

  /**
   * Getter for wizard latest step. This is the furthest step user visited.
   */
  get wizardLatestStep() {
    return this.wizardStateSource.getValue().wizardLatestStep;
  }
  set wizardLatestStep(wizardLatestStep: number) {
    this.wizardStateSource.next({
      ...this.wizardStateSource.getValue(),
      wizardLatestStep,
    });
  }

  /**
   * Is wizard state changed after initialization (by user interaction or using localStorage data)
   */
  get stillHasInitialData() {
    return this.wizardStateSource.getValue().stillHasInitialData;
  }
  set stillHasInitialData(stillHasInitialData: Boolean) {
    this.wizardStateSource.next({
      ...this.wizardStateSource.getValue(),
      stillHasInitialData,
    });
  }

  /**
   * Finds previous enabled step's path.
   * Checks each step starting from start to current. Returns latest enabled step's path.
   */
  get previousStepPath() {
    const currentStep = this.wizardStateSource.getValue().wizardCurrentStep;
    let previousEnabledStepNumber;

    // Find previous enabled step index
    for (let i = 1; i < currentStep; i++) {
      previousEnabledStepNumber = this.isStepEnabled(i)
        ? i
        : previousEnabledStepNumber;
    }

    // Get previous enabled step
    const previousEnabledStep = wizardSteps.find(
      (step) => step.stepNumber === previousEnabledStepNumber
    );

    return previousEnabledStep
      ? `/${wizardRootPath}/${previousEnabledStep.path}`
      : undefined;
  }

  /**
   * Finds next enabled step's path.
   * Checks each step starting from last to current. Returns earliest enabled step's path.
   */
  get nextStepPath() {
    const currentStep = this.wizardStateSource.getValue().wizardCurrentStep;
    let nextEnabledStepNumber;

    // Find next enabled step index
    for (let i = wizardSteps.length; i > currentStep; i--) {
      nextEnabledStepNumber = this.isStepEnabled(i) ? i : nextEnabledStepNumber;
    }

    // Get next enabled step
    const nextEnabledStep = wizardSteps.find(
      (step) => step.stepNumber === nextEnabledStepNumber
    );

    return nextEnabledStep
      ? `/${wizardRootPath}/${nextEnabledStep.path}`
      : undefined;
  }

  isStepEnabled(stepNumber) {
    switch (stepNumber) {
      case 1:
        return this.isStep1Enabled;
        break;
      case 2:
        return this.isStep2Enabled;
        break;
      case 3:
        return this.isStep3Enabled;
        break;
      case 4:
        return this.isStep4Enabled;
        break;
      case 5:
        return this.isStep5Enabled;
        break;
      default:
        return false;
        break;
    }
  }

  get isStep1Enabled() {
    return true;
  }

  get isQuestion2Enabled() {
    const { insuranceType } = this.wizardDataSource.getValue();
    return !!insuranceType;
  }

  get isQuestion3Enabled() {
    const { howManyPeople } = this.wizardDataSource.getValue();
    return this.isQuestion2Enabled && !!howManyPeople;
  }

  get isQuestion4Enabled() {
    const { ownerStatus } = this.wizardDataSource.getValue();
    return this.isQuestion3Enabled && !!ownerStatus;
  }

  get isQuestion5Enabled() {
    const { insuranceType, ownerStatus, canton } =
      this.wizardDataSource.getValue();
    return (
      this.isQuestion3Enabled &&
      !!ownerStatus &&
      !!canton &&
      (insuranceType === "householdandliability" ||
        insuranceType === "householdonly")
    );
  }

  get isStep2Enabled() {
    const { insuranceType, insuranceAmount, ownerStatus, canton } =
      this.wizardDataSource.getValue();

    if (
      insuranceType === "householdonly" ||
      insuranceType === "householdandliability"
    ) {
      return this.isQuestion4Enabled && !!insuranceAmount;
    } else if (insuranceType === "liabilityonly") {
      return this.isQuestion3Enabled && !!ownerStatus && !!canton;
    }

    return false;
  }

  get isStep3Enabled() {
    const { deductible } = this.wizardDataSource.getValue();
    return this.isStep2Enabled && !!deductible;
  }

  get isStep4Enabled() {
    const personalInformation =
      this.wizardDataSource.getValue().personalInformation;

    if (!personalInformation) return false;

    const {
      correspondenceLanguage,
      firstName,
      lastName,
      gender,
      dateOfBirth,
      city,
      cityId,
      streetName,
      nationality,
      email,
      mobileNumber,
      houseNumber,
      postCode,
    } = personalInformation;

    const requiredFields = {
      correspondenceLanguage,
      firstName,
      lastName,
      gender,
      dateOfBirth,
      city,
      cityId,
      streetName,
      nationality,
      email,
      mobileNumber,
      houseNumber,
      postCode,
    };

    const isPersonalInfoCompleted = Object.values(requiredFields).every(
      (field) => {
        return field !== undefined;
      }
    );

    return this.isStep3Enabled && isPersonalInfoCompleted;
  }

  get isStep5Enabled() {
    const {
      terminatedOrDeniedInsuranceQuestion,
      claimQuestion,
      insuranceStartDate,
    } = this.wizardDataSource.getValue();
    return (
      this.isStep4Enabled &&
      terminatedOrDeniedInsuranceQuestion === "No" &&
      claimQuestion === "No" &&
      this.acceptedTermsAndConditions &&
      !!insuranceStartDate &&
      this.acceptedDataSharing
    );
  }

  /**
   * ##Wizard Helpers
   * This section defines methods for controlling andvanced wizard features.
   */

  /**
   * Save wizard state to localStorage for later use.
   */
  public saveWizardStateToLocalStorage() {
    this.browserStorage.setItem(
      "savedWizardState",
      JSON.stringify({
        data: this.wizardDataSource.getValue(),
        state: {
          ...this.wizardStateSource.getValue(),
          savedAt: new Date().getTime(),
        },
      })
    );
  }

  /**
   * Read saved state from localStorage
   */
  public readSavedState(): ILocalStorageWizardState {
    const localStorageItem = this.browserStorage.getItem("savedWizardState");
    let savedState;

    if (localStorageItem) {
      savedState = JSON.parse(localStorageItem);
    }

    return savedState;
  }

  /**
   * Use saved wizard state or start fresh.
   */
  public useSavedStateOrStartFresh() {
    let savedState = this.readSavedState() || { data: {}, state: {} };

    this.wizardDataSource = new BehaviorSubject<IWizardData>({
      ...initialWizardData,
      ...savedState.data,
    });
    this.wizardStateSource = new BehaviorSubject<IWizardState>({
      ...initialWizardState,
      ...savedState.state,
      stillHasInitialData: false,
    });

    const currentLanguage = this.configurationService.getLanguage();
    const currentStep = wizardSteps.find(
      (step) =>
        step.stepNumber === this.wizardStateSource.value.wizardCurrentStep
    );

    const currentStepPath = currentStep ? currentStep.path : "";

    const localizedRootPath =
      localizedRoutes[wizardRootPath]?.langs[currentLanguage];
    const localizedStepPath =
      localizedRoutes[currentStepPath]?.langs[currentLanguage];

    this.router.navigateByUrl(
      `${this.configurationService.getLanguage()}/${localizedRootPath}/${localizedStepPath}`
    );
  }

  /**
   * Ask user if he/she sure about that.
   * @returns { NgbModalRef } modal reference. Can be used to get user confirmation about leaving.
   */
  public openWizardLeaveModal() {
    if (this.wizardStateSource.getValue().wizardLeaveModalRef) return null;

    const wizardLeaveModalRef = this.modalService.open(
      WizardLeaveConfirmModalComponent,
      { centered: true }
    );
    wizardLeaveModalRef.componentInstance.name = "WizardLeaveModal";
    wizardLeaveModalRef.result
      .then(() => {
        this.wizardStateSource.next({
          ...this.wizardStateSource.getValue(),
          wizardLeaveModalRef: undefined,
        });
      })
      .catch(() => {
        this.wizardStateSource.next({
          ...this.wizardStateSource.getValue(),
          wizardLeaveModalRef: undefined,
        });
      });

    this.wizardStateSource.next({
      ...this.wizardStateSource.getValue(),
      wizardLeaveModalRef,
    });

    return wizardLeaveModalRef;
  }

  /**
   * Close modal. Pass true/false to confirm/reject leaving wizard.
   */
  public closeWizardLeaveModal(leave: Boolean) {
    if (leave) {
      const modalRef = this.wizardStateSource.getValue().wizardLeaveModalRef;
      modalRef.close(true);
    } else {
      const modalRef = this.wizardStateSource.getValue().wizardLeaveModalRef;
      modalRef.dismiss(false);
    }
  }
}
