import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs';
import { TokenStorage } from './tokenstorage.service'

@Injectable()
export abstract class BaseCrudService<T> {

    constructor(protected controller: string, protected api: string, protected translateService: TranslateService, protected requestService: RequestService, protected tokenStorage: TokenStorage) { }

    public Count(): Promise<number> {
        return this.requestService.get(this.api, this.controller.concat("count"))
            .toPromise()
            .then(res => { return <number>res });
    }

    public Find(): Promise<T[]> {
        return this.requestService.get(this.api,this.controller)
            .toPromise()
            .then(res => { return <T[]>res });
    }

    public FindById(id: string): Promise<T> {
        return this.requestService.get(this.api, this.controller.concat(id))
            .toPromise()
            .then(res => { return <T>res });
    }

    public Save(data: T): Promise<T> {
        return this.requestService.post(this.api, this.controller, data)
            .toPromise()
            .then(res => { return <T>res });
    }

    public Delete(id: string): Observable<any> {
        return this.requestService.delete(this.api,this.controller.concat(id));
    }
}