import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-wizard-leave-confirm-modal",
  templateUrl: "./wizard-leave-confirm-modal.component.html",
  styleUrls: ["./wizard-leave-confirm-modal.component.scss"],
})
export class WizardLeaveConfirmModalComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {}
}
