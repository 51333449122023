import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sticker-competition',
  templateUrl: './sticker-competition.component.html',
  styleUrls: ['./sticker-competition.component.scss']
})
export class StickerCompetitionComponent {

}
